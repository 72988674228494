import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useTranslation } from "react-i18next";
import { _AuthApi } from "api/auth";
import { Box, Grid } from "@mui/material";
import { DarkModeContext } from "theme/provider/DarkModeProvider";
let schema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(6, "The Password must be of six characters")
    .max(20, "The Password must be of 20 characters"),

  confirm_password: yup
    .string()
    .required("Confirm password is required")
    .min(6, "The confirm password must be of six characters")
    .max(20, "The confirm password must be of 20 characters")
    .oneOf([yup.ref("password")], "your password does not match"),
  createdOn: yup.date().default(function () {
    return new Date();
  }),
});
const PasswordEditForm = () => {
  const { t } = useTranslation("index");
  const { darkToggle } = useContext(DarkModeContext);
  const [loading, setloading] = useState(false);
  const { email, code } = useParams();
  const [new_password, setNewPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const formOptions = { resolver: yupResolver(schema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const navigate = useNavigate();
  const onSubmit = async (e) => {
    setloading(true);
    _AuthApi
      .passEdit({
        email,
        code,
        new_password,
        confirm_password,
      })
      .then((res) => navigate("/", { replace: true }))
      ?.finally(() => setloading(false))
      ?.catch((err) => console.log(err));
  };
  return (
    <section>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "transparent",

          flexDirection: "column",
          backdropFilter: "bluer(20px)",
        }}
      >
        <Box
          className="dark:bg-[#272727]/50 bg-third-color"
          sx={{
            minWidth: "350px",
            width: "30%",
            padding: "40px",
            zIndex: 10000,
            backgroundColor: "transparent",
            border: darkToggle
              ? "1px solid rgba(255,255,255,0.2)"
              : "1px solid rgba(0,0,0,0.2)",
            borderRadius: "10px",
          }}
          style={{
            backdropFilter: " blur(3px)",
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box sx={{ width: "120px" }}></Box>
            <p className="text-2xl text-black dark:text-white ">
              {t("Reset Your Password")}
            </p>
            <p className="my-5 text-xl text-center text-secondry-color">
              {t("Enter your credentials to continue")}
            </p>
          </Grid>
          <Box
            sx={{ width: "100%", mt: "30px" }}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box sx={{ margin: "0 0 8px 5px" }}>
              <p className="text-black dark:text-white">{t("New Password")}</p>
            </Box>
            <input
              type="password"
              sx={{ width: "100%" }}
              placeholder="Enter your password"
              {...register("password", { validate: true })}
              id="password"
              value={new_password}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full px-5 py-5 text-black bg-transparent border border-black rounded dark:text-white "
            />
            {errors.password && (
              <span className="field_level_error" style={{ color: "red" }}>
                {errors.password.message}
              </span>
            )}
            <Box sx={{ margin: "20px 0 8px 5px" }}>
              <p className="text-black dark:text-white">
                {t("Confirm Password")}
              </p>
            </Box>
            <input
              type="password"
              sx={{ width: "100%" }}
              placeholder="Confirm Password"
              {...register("confirm_password", { validate: true })}
              id="confirm_password"
              value={confirm_password}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="w-full px-5 py-5 text-black bg-transparent border border-black rounded dark:text-white "
            />
            {errors.confirm_password && (
              <span className="field_level_error" style={{ color: "red" }}>
                {errors.confirm_password.message}
              </span>
            )}
            <div className="flex items-center justify-center w-full my-5">
              <button
                type="submit"
                className="relative h-12 text-white rounded w-1/1 bg-secondry-color"
                onClick={handleSubmit}
                // disabled={loading}
              >
                <div className="px-5">
                  {loading ? (
                    <p className="loader"></p>
                  ) : (
                    <p>Update Password</p>
                  )}
                </div>
              </button>
            </div>
          </Box>
        </Box>
      </Box>
    </section>
  );
};

export default PasswordEditForm;
