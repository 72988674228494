import React from "react";
import { Route, Routes } from "react-router-dom";

import AboutComponent from "./AboutComponent";
import About from "./About";
import WhatDoIneed from "./WhatDoIneed/WhatDoIneed";
import MeetTheSiinTeam from "./MeetTheSiinTeam/MeetTheSiinTeam";
import SiinMediaTestimonials from "./SiinMediaTestimonials/SiinMediaTestimonials";
import Partners from "./partners/Partners";
import Sponsorships from "./Sponsorships/Sponsorships";
import GetInTouch from "./GetInTouch/GetInTouch";

const AboutRouting = () => {
  return (
    <Routes>
      <Route element={<AboutComponent />}>
        <Route path="/about" element={<About />} />
        <Route path="/why-do-i-need" element={<WhatDoIneed />} />
        <Route path="/meet-the-siin-team" element={<MeetTheSiinTeam />} />
        <Route
          path="/siin-in-the-media&testimonials"
          element={<SiinMediaTestimonials />}
        />
        <Route path="/partners" element={<Partners />} />
        <Route path="/sponsorships" element={<Sponsorships />} />
        <Route path="/get-in-touch" element={<GetInTouch />} />
      </Route>
    </Routes>
  );
};

export default AboutRouting;
