import React from "react";

import { useLink } from "hooks/useLink";

const GetInTouch = () => {
  const { data, isLoading } = useLink();

  return (
    <div className="block">
      <h1 className="pt-20 pb-10 text-2xl font-bold text-center ">
        Get in touch
      </h1>
      <div className="flex justify-center w-full ">
        {isLoading && <p className="loader"> </p>}
      </div>
      <div className="flex items-center justify-between py-5">
        <div className="flex flex-col items-center justify-center w-full gap-4">
          <h1>We would love to hear from you! Write</h1>
          {data?.links?.map(
            (item) =>
              item.type === "email" && (
                <a href={`mailto:${item.url}`}>
                  <h1>{item.url}</h1>
                </a>
              )
          )}
          <h1>Or keep an eye to meet us in one of the events!</h1>
          <h1>We are located in Lebanon.</h1>
          <h1>Also you can send us a WhatsApp message on </h1>
          {data?.links?.map(
            (item) =>
              item.type === "phone" && (
                <a href={`tel:${item.url}`}>
                  <h1>{item.url}</h1>
                </a>
              )
          )}
          <div className="flex justify-between w-1/4 mt-10 ">
            {data?.links?.map((item) => (
              <>
                {item.type === "social_media" && (
                  <div key={item.id} className="flex ">
                    <a href={item.url} target="_balank">
                      <img src={item.icon} alt="" />
                    </a>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
