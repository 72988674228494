import React from "react";
import dateIcon from "assets/icons/DateIcon.svg";
import locationIcon from "assets/icons/LocationIcon.svg";
import heartEmpty from "assets/icons/heartEmpty.svg";
import heartfill from "assets/icons/heartIconFill.png";
import leftArrowIcon from "assets/icons/profile/ArrowLeftBorder.svg";
import rightArrowIcon from "assets/icons/profile/ArrowRightBorder.svg";
import { FormControlLabel, FormGroup, Rating } from "@mui/material";
import likeIconWhite from "assets/icons/eventShow/likeIconeWhite.svg";
import likeIconBlack from "assets/icons/eventShow/likeIconBlack.svg";
import dislikeIconWhite from "assets/icons/eventShow/dislikeIconWhite.svg";
import dislikeIconBlack from "assets/icons/eventShow/dislikeIconBlack.svg";
import Switch from "@mui/material/Switch";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useLogic } from "./hooks/useLogic";

import Modal from "react-modal";

const Verified = () => {
  const {
    t,
    data,
    role,
    state,
    params,
    startX,
    loading,
    direction,
    isLoading,
    setStartX,
    formatDate,
    isDragging,
    scrollLeft,
    darkToggle,
    activeIndex,
    access_token,
    handleChange,
    setCoverImage,
    coverImageRef,
    setIsDragging,
    setScrollLeft,
    hanldeFavorite,
    showAllReviews,
    profileImageRef,
    handlePrevEvent,
    setProfileImage,
    handleNextEvent,
    showAllComments,
    formatDateMonth,
    limitedComments,
    handleToggleLike,
    coverImageLoading,
    handleCoverUpdate,
    setShowAllComments,
    profileImageLoading,
    handleEventShowView,
    handleProfileUpdate,
    handleToggleUnFollow,
    toggleReviewsDisplay,
    handleSwitchComments,
    isModalOpenCoverImage,
    handleSubmitCoverImage,
    isModalOpenProfileImage,
    setIsModalOpenCoverImage,
    setIsModalOpenProfileImage,
    handleSubmitProfileImage,
  } = useLogic();

  return (
    <section className="text-black dark:text-white">
      <div className="flex items-center justify-center w-full">
        {coverImageLoading && <p className="loader"></p>}
      </div>
      <div className="flex items-center justify-center w-full">
        {isLoading && <p className="loader"></p>}
      </div>
      <div>
        <div className="relative">
          <div
            className={`w-full h-[60vh] bg-no-repeat bg-center bg-cover 
             flex items-end justify-center`}
            style={{ backgroundImage: `url(${data?.profile?.cover})` }}
          >
            {access_token ? (
              <>
                <input
                  ref={coverImageRef}
                  id="cover_image"
                  type="file"
                  accept="image/*"
                  className="absolute right-0 hidden"
                  onChange={(e) => {
                    setCoverImage(e.target.files[0]);
                    handleCoverUpdate();
                  }}
                />
                <label
                  htmlFor="cover_image"
                  className="absolute flex gap-2 p-2 text-white rounded-md cursor-pointer right-3 bottom-3 hover:bg-black/50 bg-black/75"
                >
                  <CameraAltIcon />
                  <p className="hidden md:block">Edit Cover Photo</p>
                </label>
              </>
            ) : null}
            <Modal
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.4)",
                  zIndex: 1000,
                  width: "100%",
                },
                content: {
                  zIndex: 1001,
                  background: "white",
                  border: "none",
                  borderRadius: "8px",
                  width: "100%",
                  top: "90%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  height: "30%",
                },
              }}
              isOpen={isModalOpenCoverImage}
              onRequestClose={() => setIsModalOpenCoverImage(false)}
            >
              <h2>Change Cover Image</h2>
              <p>Are you sure you want to change your cover image?</p>
              <div className="flex gap-10 my-5">
                <button
                  className="px-8 py-3 bg-transparent border border-green-700 rounded-md"
                  onClick={handleSubmitCoverImage}
                >
                  Yes
                </button>
                <button
                  className="px-8 py-3 text-white bg-red-500 rounded-md"
                  onClick={() => setIsModalOpenCoverImage(false)}
                >
                  No
                </button>
              </div>
            </Modal>
            <div className="absolute -bottom-[10%] lg:-bottom-[10%] flex flex-col items-center justify-center gap-5 lg:gap-10">
              {profileImageLoading ? (
                <p className="loader"></p>
              ) : (
                <img
                  src={data?.profile?.image}
                  alt=""
                  className="w-32 h-32 rounded-full sm:w-40 sm:h-40 ring-4 ring-secondry-color"
                />
              )}
              {access_token ? (
                <>
                  <input
                    ref={profileImageRef}
                    id="profile_image"
                    type="file"
                    accept="image/*"
                    className="absolute right-0 hidden"
                    onChange={(e) => {
                      setProfileImage(e.target.files[0]);
                      handleProfileUpdate();
                    }}
                  />
                  <label
                    htmlFor="profile_image"
                    className="absolute flex gap-2 p-2 text-white rounded-md cursor-pointer right-3 bottom-3 hover:bg-black/50 bg-black/75"
                  >
                    <CameraAltIcon />
                  </label>
                </>
              ) : null}
              <Modal
                style={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.4)",
                    zIndex: 1000,
                    width: "100%",
                  },
                  content: {
                    zIndex: 1001,
                    background: "white",
                    border: "none",
                    borderRadius: "8px",
                    width: "100%",
                    top: "90%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    height: "30%",
                  },
                }}
                isOpen={isModalOpenProfileImage}
                onRequestClose={() => setIsModalOpenProfileImage(false)}
              >
                <h2>Change Profile Image</h2>
                <p>Are you sure you want to change your profile image?</p>
                <div className="flex gap-10 my-5">
                  <button
                    className="px-8 py-3 bg-transparent border border-green-700 rounded-md"
                    onClick={handleSubmitProfileImage}
                  >
                    Yes
                  </button>
                  <button
                    className="px-8 py-3 text-white bg-red-500 rounded-md"
                    onClick={() => setIsModalOpenProfileImage(false)}
                  >
                    No
                  </button>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
      <section className="flex flex-col items-center justify-center w-full gap-10 my-24">
        <div className="flex gap-5 text-xl capitalize">
          <p>{data?.profile?.first_name}</p>
          <p>{data?.profile?.last_name}</p>
        </div>
        {data?.profile?.is_user_profile !== true ? (
          <div>
            <button
              onClick={() => handleToggleUnFollow(params.id)}
              className={`${
                data?.profile?.is_follower === false
                  ? "bg-secondry-color  hover:scale-110 hover:font-semibold"
                  : "bg-primary-color  hover:scale-110 hover:font-semibold"
              } px-4 py-2 rounded-md`}
            >
              {loading ? (
                <p className="loader"></p>
              ) : data?.profile?.is_follower === false ? (
                <div>{t("Follow")}</div>
              ) : (
                <div>{t("Following")}</div>
              )}
            </button>
          </div>
        ) : (
          ""
        )}
        <div>
          {data?.profile?.followers_count} {t("followers")}
        </div>
      </section>
      <section className=" lg:px-10">
        <h1 className="px-5 text-3xl font-bold">{t("Plans")}</h1>

        <div className="flex flex-col px-2 lg:flex lg:flex-row">
          <div
            className={`  lg:border lg:border-white lg:border-t-0 lg:border-b-0  ${
              direction === "ltr" ? "lg:border-l-0" : "lg:border-r-0"
            } w-8/12`}
          >
            <div className="flex justify-end gap-5 lg:gap-10 lg:px-10">
              <img
                src={leftArrowIcon}
                alt=""
                className="w-10 h-10 transition duration-200 ease-linear cursor-pointer hover:scale-110"
                onClick={handlePrevEvent}
              />
              <img
                src={rightArrowIcon}
                alt=""
                className="w-10 h-10 transition duration-200 ease-linear cursor-pointer hover:scale-110"
                onClick={handleNextEvent}
              />
            </div>

            {data && (
              <div className={`w-full  mt-5  `}>
                <div className="flex gap-2 px-2 lg:gap-5 lg:w-1/1">
                  {data?.profile?.plans.length > 0 &&
                    data?.profile?.plans
                      ?.slice(activeIndex, activeIndex + 2)
                      .map((item, index) => (
                        <div
                          key={index}
                          className={`my-5  flex flex-col gap-5 w-full lg:w-1/2 border dark:border-white border-gray-400 p-2 lg:p-5 lg:mx-10  h-1/1 rounded-xl  ${
                            activeIndex ? "" : ""
                          }`}
                        >
                          <div className="relative w-full ">
                            <img
                              src={item.image}
                              alt=""
                              onClick={() => handleEventShowView(item.id)}
                              className="object-cover w-full rounded-md cursor-pointer lg:object-con h-60 lg:w-full lg:h-80 "
                            />
                            <div
                              className={`absolute ${
                                direction === "ltr"
                                  ? " right-2  top-2"
                                  : "left-2  top-2"
                              }`}
                            >
                              <img
                                src={item.is_favorite ? heartfill : heartEmpty}
                                alt=""
                                onClick={() => {
                                  hanldeFavorite(item.id);
                                }}
                                className="w-8 h-8 rounded-md cursor-pointer hover:scale-110 "
                              />
                            </div>
                          </div>
                          <p className="text-xl">{item.name}</p>
                          <div className="flex gap-5">
                            <img src={dateIcon} alt="" />
                            <p className="text-xs lg:text-base ">
                              from {formatDate(item.start_date)} until{" "}
                              {formatDate(item.end_date)} ,
                              {formatDateMonth(item.start_date)}
                              to {formatDateMonth(item.end_date)}
                            </p>
                          </div>
                          <div className="flex gap-5">
                            <img src={locationIcon} alt="" />
                            <p className="text-xs lg:text-base">
                              {item.location}
                            </p>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            )}
            <section>
              <div className="bg-black dark:bg-fourth-color h-[0.5px] w-1/2 lg:mx-10 "></div>
            </section>
            <section className="flex flex-col-reverse lg:flex lg:flex-row">
              <div>
                {data?.profile?.reviews
                  ?.slice(0, showAllReviews ? undefined : 2)
                  .map((item, profileIndex) => (
                    <div
                      key={profileIndex}
                      className="flex flex-col gap-10 my-10 "
                    >
                      <div className="flex items-end gap-5">
                        <img
                          src={item.image}
                          alt=""
                          className="rounded-full w-28 h-28 lg:w-20 lg:h-20"
                        />
                        <div className="flex flex-col gap-5 lg:flex-row lg:flex">
                          <Rating
                            className="review-rate"
                            precision={0.5}
                            readOnly
                            defaultValue={item.rate ?? "null"}
                          />
                          <p>{item.name}</p>
                        </div>
                      </div>

                      <div className="flex justify-center gap-10 lg:my-10 lg:w-1/2 ">
                        <div
                          title="scroll"
                          className={`flex lg:max-w-5xl w-full  gap-5   overflow-auto rounded-md select-none organizer-reviews ${
                            isDragging ? "grabbing" : "cursor-grab"
                          }`}
                          onMouseDown={(e) => {
                            setIsDragging(true);
                            setStartX(e.pageX - e.currentTarget.offsetLeft);
                            setScrollLeft(e.currentTarget.scrollLeft);
                          }}
                          onMouseUp={() => setIsDragging(false)}
                          onMouseLeave={() => setIsDragging(false)}
                          onMouseMove={(e) => {
                            if (!isDragging) return;
                            const x = e.pageX - e.currentTarget.offsetLeft;
                            const walk = (x - startX) * 2;
                            e.currentTarget.scrollLeft = scrollLeft - walk;
                          }}
                        >
                          {item?.reviews?.map((reviewItem, index) => (
                            <div
                              key={index}
                              className="flex flex-wrap w-full mx-10 my-5 rounded-md lg:my-10 "
                            >
                              <div className="h-full p-3 text-black rounded-lg bg-third-color dark:bg-fourth-color lg:p-10 w-1/1 ">
                                <div className="flex items-center gap-5 ">
                                  <img
                                    src={reviewItem.image}
                                    alt=""
                                    className="rounded-full w-14 h-14"
                                  />
                                  <div className="flex flex-col gap-4 ">
                                    <p className="capitalize">
                                      {reviewItem.first_name}{" "}
                                      {reviewItem.last_name}
                                    </p>
                                  </div>
                                </div>
                                <div>
                                  <p className="my-2 ">{reviewItem.review}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="w-32">
                {showAllReviews ? (
                  <button
                    className="underline decoration-1 "
                    onClick={toggleReviewsDisplay}
                  >
                    See less reviews
                  </button>
                ) : (
                  <button
                    className="underline decoration-1 "
                    onClick={toggleReviewsDisplay}
                  >
                    See all reviews
                  </button>
                )}
              </div>
            </section>
          </div>
          <div className="px-2 lg:w-6/12 ">
            <div className="w-full border rounded-md border-secondry-color">
              <div className="flex items-center justify-between p-2 ">
                <div className="justify-between w-full lg:flex-row lg:flex">
                  <p className="text-xl">Comments</p>
                  {data?.profile?.is_user_profile === true && (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            color="warning"
                            sx={{
                              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                {
                                  backgroundColor: "#f5881f",
                                },
                              "& .MuiSwitch-switchBase": {
                                "& + .MuiSwitch-track": {
                                  backgroundColor: "#f5881f",
                                },
                              },
                            }}
                            defaultValue={data?.profile?.is_comments_enabled}
                            checked={state.checkedA}
                            onChange={handleChange}
                            onClick={handleSwitchComments}
                            name="checkedA"
                          />
                        }
                        label="Display Comments"
                      />
                    </FormGroup>
                  )}
                </div>
                <div className="text-center w-1/1">
                  {!showAllComments && data?.profile?.comments?.length > 3 && (
                    <button
                      onClick={() => setShowAllComments(true)}
                      className="p-2 text-sm transition duration-200 border rounded-md cursor-pointer border-secondry-color text-secondry-color hover:bg-secondry-color hover:text-white hover:scale-105"
                    >
                      {t("Show All")}
                    </button>
                  )}
                  {showAllComments && (
                    <button
                      onClick={() => setShowAllComments(false)}
                      className="p-2 text-sm transition duration-200 border rounded-md cursor-pointer border-secondry-color text-secondry-color hover:bg-secondry-color hover:text-white hover:scale-105"
                    >
                      {t("Show Less")}
                    </button>
                  )}
                </div>
              </div>
              <div className="h-[0.1] w-full border dark:border-white border-gray-400 "></div>

              <div className="flex justify-between p-5 lg:gap-10 ">
                <div className="flex flex-col w-full gap-10 ">
                  {limitedComments?.length > 0 ? (
                    limitedComments?.map((item, index) => (
                      <div key={index} className="flex gap-5 lg:gap-5">
                        <div className="flex">
                          <img
                            src={item.user_image}
                            alt=""
                            className="w-12 h-12 rounded-full lg:h-12 lg:w-12 ring-1 ring-white"
                          />
                        </div>
                        <div className="flex flex-col gap-5">
                          <div className="flex gap-3">
                            <p>{item.user_first_name}</p>
                            <p>{item.user_last_name}</p>
                          </div>
                          <div>{item.body ?? "null"}</div>
                          <div className="flex gap-3 lg:gap-10">
                            <p>{item.likes_count}</p>
                            {role === "verified" ? (
                              item.likes_count <= 1 ? (
                                "like"
                              ) : (
                                "likes"
                              )
                            ) : darkToggle ? (
                              <img
                                src={likeIconWhite}
                                alt=""
                                className="cursor-pointer hover:scale-105"
                                // onClick={() =>
                                //   handleToggleLike(item.id, "like")
                                // }
                              />
                            ) : (
                              <img
                                src={likeIconBlack}
                                alt=""
                                className="cursor-pointer hover:scale-105"
                                // onClick={() =>
                                //   handleToggleLike(item.id, "like")
                                // }
                              />
                            )}
                            <p>{item.dislikes_count}</p>
                            {role === "verified" ? (
                              item.dislikes_count <= 1 ? (
                                "dislike"
                              ) : (
                                "dislikes"
                              )
                            ) : darkToggle ? (
                              <img
                                src={dislikeIconWhite}
                                alt=""
                                className="cursor-pointer hover:scale-105"
                                onClick={() =>
                                  handleToggleLike(item.id, "dislike")
                                }
                              />
                            ) : (
                              <img
                                src={dislikeIconBlack}
                                alt=""
                                className="cursor-pointer hover:scale-105"
                                onClick={() =>
                                  handleToggleLike(item.id, "dislike")
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>No Comments available.</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Verified;
