import { usePartners } from "hooks/usePartners";
import React from "react";

const Partners = () => {
  const { data, isLoading } = usePartners();
  return (
    <div className="flex flex-wrap justify-center gap-10">
      <h1 className="pt-20 pb-10 text-2xl font-bold text-center ">
        What it's all about
      </h1>
      <div className="flex justify-center w-full ">
        {isLoading && <p className="loader"> </p>}
      </div>
      {data?.partners?.map((item, index) => (
        <div key={index} className="">
          <img src={item.image} alt="" className="w-full" />
        </div>
      ))}
    </div>
  );
};

export default Partners;
