import React from "react";
import { useLogic } from "../hooks/useLogic";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import EventCard from "components/shared/EventCard";

const SimilarEvents = () => {
  const {
    event,
    eventLoading,
    settings,
    handleEventShowView,
    favoriteLoadingMap,
    hanldeFavorite,
    t,
  } = useLogic();

  return (
    <>
      {/* Semilier events */}
      <section className="px-5 lg:px-20">
        <div>
          <p className="font-bold">{t("Similar Events")}</p>
          <div>
            <div className="hidden text-black dark:text-white lg:block">
              <div className="flex justify-center w-full">
                {eventLoading && <p className="loader"> </p>}
              </div>
              <div className="gap-5 px-5 mt-10 lg:grid-cols-2 lg:grid 2xl:grid-cols-4 2xl:grid place-items-center">
                {event?.map((item, index) => (
                  <EventCard
                    key={index}
                    item={item}
                    image={item.image}
                    hanldeFavorite={() => hanldeFavorite(item.id)}
                    handleEventShowView={() => handleEventShowView(item.id)}
                    end_date={item.end_date}
                    start_date={item.start_date}
                    loadingFavorite={favoriteLoadingMap[item.id]}
                    is_favorite={item.is_favorite}
                    name={item.name}
                    location={item.location}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Semilier events res*/}

      <section className="block px-5 my-0 text-black md:hidden dark:text-white">
        <Swiper
          {...settings}
          // navigation
          autoplay={{ delay: 3000 }}
          modules={[Pagination, Autoplay]}
          slidesPerView={1}
          pagination={{ clickable: true }}
        >
          {event?.map((item, index) => (
            <SwiperSlide
              className="flex flex-col px-1 py-10 rounded-xl "
              key={index}
            >
              <EventCard
                index={index}
                item={item}
                image={item.image}
                hanldeFavorite={() => hanldeFavorite(item.id)}
                handleEventShowView={() => handleEventShowView(item.id)}
                end_date={item.end_date}
                start_date={item.start_date}
                loadingFavorite={favoriteLoadingMap[item.id]}
                is_favorite={item.is_favorite}
                name={item.name}
                location={item.location}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </>
  );
};

export default SimilarEvents;
