import React  from "react";
 
const HowItWorks = ({ children }) => {
 
 
  return (
    <>
      <section className="mx-5 text-black border border-white md:mx-20 md:block dark:text-white md:pb-10 md:px-0 bg-third-color dark:bg-black">
        <div className="flex flex-col px-10 md:pb-5">
          <p className="font-bold">How It Works?</p>
        </div>
        <div className="h-[0.5px] bg-primary-color m-5"></div>
        <div className="flex flex-col flex-wrap gap-5 px-10 pb-5">
          <p className="font-bold">1.Events</p>
          <p>
            1- Events Calendar: Sin is all about events, anyone can browse the
            site for upcoming events in the country. You can also look for
            what's happening during a specific month or on a specific date and
            filter according to your preferred event types! You can choose your
            favorite events with the heart symbol to personalize your
            preferences, and share them with your friends using social media,
            Whatsapp or email
          </p>
          <p>
            If you are the one organizing a concert, preparing a conference, or
            planning a hike or a public event of any kind, then you can publish
            your events on Sin free of charge and with just a few simple steps!*
            * To create events you need to be signed in, please click here to
            add your events for free:
          </p>
          <p className="font-bold">2-Activity Directory:</p>
          <p>
            Lebtivity is also about permanent activities and things to do at
            your own time, you can check all types of activities in the
            Activities Directory such as all Museums, Archeological sites, fun
            and cultural activities and also enjoy a comprehensive list of
            Restaurants, Accommodation and outings.
          </p>
          <p>Planning your own schedule</p>
          <p>
            {" "}
            With so much going on, it is challenging to keep track of your
            favorite events. When surfing through Sin, every time you see an
            event that you would like to attend, just click on the ”heart”
            button*:
          </p>
          <p>. The heart will change colors from white to green</p>
          <p>. The events you liked can be seen on your favorites page:</p>
          <p>
            . You will receive a reminder email a day before the event occurs.*
          </p>
          <p>
            . You will receive an email notification when important information
            about the event is changed (such as location or date).
          </p>
        </div>
        {children}
      </section>

      {/* <section className="block mx-5 text-black border border-white md:hidden dark:text-white md:pb-10 md:px-0 bg-third-color dark:bg-black">
        <div className="flex justify-between px-2">
          <div>
            <div className="flex items-center justify-between pb-5 pt-5x">
              <div>
                <p
                  // onClick={() => navigate(aboutNav[selectedItemIndex].navigate)}
                  className="font-bold "
                >
                  {aboutNav[selectedItemIndex].head}
                </p>
              </div>
            </div>
          </div>
          <div className="dropdown">
            <div className="flex items-center justify-center gap-2 ">
              {!darkToggle ? (
                <img
                  src={sortIconBlack}
                  alt=""
                  onClick={handleImageClick}
                  className={`cursor-pointer h-5 ${
                    isOpen ? "rotate-180" : ""
                  } transition-transform duration-300 ease-in-out`}
                />
              ) : (
                <img
                  src={sortIconWhite}
                  alt=""
                  onClick={handleImageClick}
                  className={`cursor-pointer h-5 ${
                    isOpen ? "rotate-180" : ""
                  } transition-transform duration-300 ease-in-out`}
                />
              )}

              <div className="relative flex flex-col text-secondry-color ">
                <div className="flex flex-col items-start w-full mt-5 dropdown-content">
                  {isOpen &&
                    aboutNav.map((item, index) => (
                      <div key={index}>
                        <p
                          onClick={() => {
                            handleItemClick(index);
                            navigate(item.navigate);
                          }}
                          className="font-bold transition duration-500 ease-in-out transform cursor-pointer hover:scale-[1.03]"
                        >
                          {item.head}
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-[0.5px] bg-primary-color m-5"></div>
        {children}
      </section> */}
    </>
  );
};

export default HowItWorks;
