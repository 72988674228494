import { _axios } from "interceptor/http-config";

export const _Search = {
  index: async ({ query }) => {
    if (!query) {
      return [];
    }
    return _axios
      .get(`/user/search?${query ? `value=${query}` : ""}`, {
        headers: {
          lang: ["en", "ar"].includes(localStorage.getItem("i18nextLng"))
            ? localStorage.getItem("i18nextLng")
            : "en",
        },
      })
      .then((res) => res.data);
  },
};
