import React from "react";
import { Rating } from "@mui/material";
import { useLogic } from "../hooks/useLogic";
import Modal from "react-modal";
import VerifiedIcon from "@mui/icons-material/Verified";

const ReviewsShow = () => {
  const {
    data,
    openModals,
    openModalsRes,
    openModal,
    closeModal,
    openModalRes,
    closeModalRes,
    darkToggle,
    setRating,
    ratingError,
    setRatingError,
    setReview,
    hanldeReview,
    showAllReview,
    setShowAllReview,
    limitedReviews,
    role,
    navigate,
    access_token,
    t
  } = useLogic();

  return (
    <div>
      <div className="flex flex-col w-full gap-10 ">
        <div className="flex items-center justify-between ">
          <p className="text-xl text-secondry-color">{t("Reviews")}:</p>
          <div className=" lg:text-center">
            {!showAllReview && data?.reviews?.length > 3 && (
              <button
                onClick={() => setShowAllReview(true)}
                className="p-2 text-sm transition duration-200 border rounded-md cursor-pointer border-secondry-color text-secondry-color hover:bg-secondry-color hover:text-white hover:scale-105"
              >
                {t("Show All")}
              </button>
            )}
            {showAllReview && (
              <button
                onClick={() => setShowAllReview(false)}
                className="p-2 text-sm transition duration-200 border rounded-md cursor-pointer border-secondry-color text-secondry-color hover:bg-secondry-color hover:text-white hover:scale-105"
              >
                {t("Show Less")}
              </button>
            )}
          </div>
        </div>
        <div className="h-[0.1] w-full border dark:border-white border-gray-400 "></div>
        <div className="flex flex-col-reverse justify-between lg:flex-row lg:flex ">
          <div className="flex flex-col p-5 lg:gap-10 ">
            {limitedReviews?.length > 0 ? (
              limitedReviews?.map((item, index) => (
                <div className="flex gap-5 lg:gap-5" key={index}>
                  <div className="w-12 lg:flex-[0.1]">
                    <img
                      className="flex justify-center w-12 h-12 text-2xl bg-gray-500 rounded-full cursor-pointer ring-1 ring-white"
                      src={item.image}
                      alt=""
                      onClick={() => {
                        item.role === "verified" &&
                          navigate(`/verified-profile/${item.user_id}`);
                      }}
                    />
                  </div>

                  <div className="flex flex-col justify-center w-1/2 gap-5 pb-10 mt-2 lg:flex-1 lg:w-1/2">
                    <div className="flex gap-3 ">
                      <p>{item.first_name}</p>
                      <p>{item.last_name}</p>
                      <p>
                        {item.role === "verified" && (
                          <VerifiedIcon
                            sx={{ color: "#f5881f", fontSize: "20px" }}
                          />
                        )}
                      </p>
                    </div>
                    <Rating
                      precision={0.5}
                      readOnly
                      defaultValue={item.rate ?? 0}
                    />

                    <p className="text-xs leading-5 lg:text-lg">
                      {item.review}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <div>{t("No Reviews available")}.</div>
            )}
          </div>
        </div>
        {role === "organizer" ? (
          ""
        ) : (
          <>
            {access_token ? (
              <>
                {new Date(data?.end_date) > new Date() ? (
                  ""
                ) : (
                  <button
                    type="button"
                    onClick={openModal}
                    className="hidden w-1/2 py-2 rounded lg:block sm:w-1/4 md:w-1/3 lg:text-xl lg:py-3 lg:w-1/1 2xl:w-1/4 bg-secondry-color"
                  >
                    {t("Write Review")}
                  </button>
                )}
                {new Date(data?.end_date) > new Date() ? (
                  ""
                ) : (
                  <button
                    type="button"
                    onClick={openModalRes}
                    className="block w-1/2 py-2 rounded lg:hidden sm:w-1/4 md:w-1/3 lg:text-xl lg:py-3 lg:w-1/1 2xl:w-1/4 bg-secondry-color"
                  >
                    {t("Write Review")}
                  </button>
                )}
              </>
            ) : (
              ""
            )}
          </>
        )}
      </div>
      <div className="hidden lg:block">
        <Modal
          isOpen={openModals}
          onRequestClose={closeModal}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              zIndex: 1000,
            },
            content: {
              zIndex: 1001,
              background: `${darkToggle ? "black" : "white"}`,
              border: "1px solid #f5881f",
              borderRadius: "8px",
              padding: "20px",
              width: "30%",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: "30%",
              "@media screen and (max-width: 600px)": {
                width: "80%",
                height: "60%",
              },
            },
          }}
        >
          <button
            className="absolute top-0 right-0 p-2 close-button rounded-tr-xl"
            onClick={closeModal}
          >
            {t("Close")}
          </button>

          <div className="flex justify-center w-full mb-10 text-2xl font-bold">
            <h2
              className={`text-center ${
                darkToggle ? "text-white" : "text-black"
              }  dark:`}
            >
              {t("Write a Review")}
            </h2>
          </div>
          <form
            className="flex flex-col w-full gap-5"
            onSubmit={(e) => hanldeReview(e, data.id)}
          >
            <textarea
              type="text"
              name="review"
              required
              onChange={(e) => setReview(e.target.value)}
              className={`bg-transparent border rounded-xl h-20 p-5  dark:${
                darkToggle
                  ? "border-white text-white "
                  : "border-gray-600 text-black "
              }`}
            />
            <div>
              <Rating
                name="half-rating"
                onChange={(e) => {
                  setRating(e.target.value);
                  setRatingError("");
                }}
                precision={0.5}
                required
                className="review-rate"
              />
              {ratingError && <p className="text-red-500">{ratingError}</p>}
            </div>
            <div className="flex justify-center w-full ">
              <button
                type="submit"
                className={`w-1/4 rounded-md py-2 ${
                  darkToggle ? "bg-fourth-color text-black" : "bg-black text-white"
                }`}
              >
                {t("Send now")}
              </button>
            </div>
          </form>
        </Modal>
      </div>
      <div className="block lg:hidden">
        <Modal
          isOpen={openModalsRes}
          onRequestClose={closeModalRes}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              zIndex: 1000,
            },
            content: {
              zIndex: 1001,
              background: `${darkToggle ? "black" : "white"}`,
              border: "1px solid #f5881f",
              borderRadius: "8px",
              padding: "20px",
              width: "90%",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: "40%",
            },
          }}
        >
          <button
            className="absolute top-0 right-0 p-2 close-button rounded-tr-xl"
            onClick={closeModalRes}
          >
            {t("Close")}
          </button>

          <div className="flex justify-center w-full mb-10 text-2xl font-bold">
            <h2
              className={`text-center ${
                darkToggle ? "text-white" : "text-black"
              }  dark:`}
            >
              {t("Write a Review")}
            </h2>
          </div>
          <form
            className="flex flex-col w-full gap-5"
            onSubmit={(e) => hanldeReview(e, data.id)}
          >
            <textarea
              type="text"
              name="review"
              required
              onChange={(e) => setReview(e.target.value)}
              className={`bg-transparent border rounded-xl h-20 p-5  dark:${
                darkToggle
                  ? "border-white text-white "
                  : "border-gray-600 text-black "
              }`}
            />
            <div>
              <Rating
                name="half-rating"
                onChange={(e) => {
                  setRating(e.target.value);
                  setRatingError("");
                }}
                precision={0.5}
                required
                className="review-rate"
              />
              {ratingError && <p className="text-red-500">{ratingError}</p>}
            </div>
            <div className="flex justify-center w-full ">
              <button
                type="submit"
                className={`w-1/4 rounded-md py-2 ${
                  darkToggle ? "bg-fourth-color text-black" : "bg-black text-white"
                }`}
              >
                {t("Send now")}
              </button>
            </div>
          </form>
        </Modal>
      </div>
    </div>
  );
};

export default ReviewsShow;
