import React from "react";
import arrowLeft from "assets/icons/ArrowLeftSlider.svg";
import arrowLeftDark from "assets/icons/ArrowLeftSliderDark.svg";
import dateIcon from "assets/icons/DateIcon.svg";
import locationIcon from "assets/icons/LocationIcon.svg";
import heartEmpty from "assets/icons/heartEmpty.svg";
import heartfill from "assets/icons/heartIconFill.png";
import { useLogic } from "./hooks/useLogic";
const Favorite = () => {
  const {
    t,
    data,
    isLoading,
    profile,
    darkToggle,
    direction,
    handleEventShowView,
    formatDate,
    formatDateMonth,
    navigate,
  } = useLogic();
  return (
    <div className="flex flex-col gap-10 py-10 pl-10 text-black dark:text-white lg:pl-40 ">
      {isLoading && (
        <div className="flex justify-center w-full ">
          <p className="loader"></p>
        </div>
      )}
      {data && (
        <>
          <div
            className="flex items-center gap-5 cursor-pointer hover:font-semibold"
            onClick={() => navigate(-1)}
          >
            {darkToggle ? (
              <img src={arrowLeft} alt="" className="w-5" />
            ) : (
              <img src={arrowLeftDark} alt="" className="w-5" />
            )}
            <p className="text-xl">{t("Back")}</p>
          </div>
          <div className="flex flex-col gap-5">
            <div className="flex gap-10">
              <p className="text-2xl">{profile?.profile?.favorites_count}</p>
              <h1 className="text-2xl">{t("Favorite")}</h1>
            </div>
            <div className="w-1/2 h-1 border border-t-0 border-l-0 border-r-0 border-gray-400 dark:border-white"></div>
          </div>
          <div className="element">
            {data && (
              <div className="gap-5  mt-5 md:grid-cols-2 md:grid lg:grid-cols-3 lg:grid 2xl:grid-cols-4 2xl:grid place-items-center h-full max-h-[700px] overflow-auto   example">
                {data?.favorites?.length > 0 &&
                  data?.favorites?.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => handleEventShowView(item.id)}
                      className="flex flex-col w-11/12 h-full gap-5 px-5 py-10 my-5 border border-gray-400 cursor-pointer dark:border-white rounded-xl "
                    >
                      <div className="relative w-full ">
                        <img
                          src={item.image}
                          alt=""
                          className="object-cover lg:object-fill w-80 h-80 lg:w-full lg:h-80 "
                        />
                        <div
                          className={`absolute ${
                            direction === "ltr"
                              ? " right-2  top-2"
                              : "left-2  top-2"
                          }`}
                        >
                          <img
                            src={item.is_favorite ? heartfill : heartEmpty}
                            alt=""
                            className="w-8 h-8 rounded-md "
                          />
                        </div>
                      </div>
                      <p className="text-xl">{item.name}</p>
                      <div className="flex gap-5">
                        <img src={dateIcon} alt="" />
                        <p>
                          from {formatDate(item.start_date)} until{" "}
                          {formatDate(item.end_date)} ,
                          {formatDateMonth(item.start_date)}
                          to {formatDateMonth(item.end_date)}
                        </p>
                      </div>
                      <div className="flex gap-5">
                        <img src={locationIcon} alt="" />
                        <p>{item.location}</p>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Favorite;
