import React, { createContext, useState, useEffect } from "react";

export const DarkModeContext = createContext();

export const DarkModeProvider = ({ children }) => {
  const [darkToggle, setDarkToggle] = useState(() => {
    const storedPreference = localStorage.getItem("darkMode");
    if (storedPreference !== null) {
      return JSON.parse(storedPreference);
    }
 
    return !window.matchMedia("(prefers-color-scheme:os)").matches;
  });

  useEffect(() => {
    localStorage.setItem("darkMode", JSON.stringify(darkToggle));
  }, [darkToggle]);

  return (
    <DarkModeContext.Provider value={{ darkToggle, setDarkToggle }}>
      {children}
    </DarkModeContext.Provider>
  );
};


// import React, { createContext, useState, useEffect } from "react";

// export const DarkModeContext = createContext();

// export const DarkModeProvider = ({ children }) => {
//   const [darkToggle, setDarkToggle] = useState(
//     () => JSON.parse(localStorage.getItem("darkMode")) || false
//   );

//   useEffect(() => {
//     localStorage.setItem("darkMode", JSON.stringify(darkToggle));
//   }, [darkToggle]);

//   return (
//     <DarkModeContext.Provider value={{ darkToggle, setDarkToggle }}>
//       {children}
//     </DarkModeContext.Provider>
//   );
// };
