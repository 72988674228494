import { useLogic } from "pages/Home/hooks/useLogic";
import React from "react";
import dateIcon from "assets/icons/DateIcon.svg";
import locationIcon from "assets/icons/LocationIcon.svg";
import heartEmpty from "assets/icons/heartEmpty.svg";
import heartfill from "assets/icons/heartIconFill.png";
const EventCard = ({
  name,
  item,
  index,
  image,
  location,
  end_date,
  start_date,
  is_favorite,
  hanldeFavorite,
  loadingFavorite,
  handleEventShowView,
}) => {
  const { t, direction, formatDate, formatDateMonth, navigate, access_token } =
    useLogic();

  return (
    <div
      key={index}
      className="flex flex-col w-full h-full gap-5 px-5 py-10 border border-gray-400 lg:w-11/12 dark:border-white rounded-xl "
    >
      <div className="relative w-full ">
        <img
          src={image}
          alt=""
          onClick={handleEventShowView}
          className="object-cover cursor-pointer lg:object-fill w-96 h-80 lg:w-full lg:h-80 "
        />
        <div
          className={`absolute ${
            direction === "ltr" ? " right-2  top-2" : "left-2  top-2"
          }`}
        >
          {access_token ? (
            loadingFavorite ? (
              <p className="loader"></p>
            ) : (
              <img
                onClick={hanldeFavorite}
                src={is_favorite ? heartfill : heartEmpty}
                alt=""
                className="w-8 h-8 transition duration-300 ease-linear rounded-md cursor-pointer hover:scale-105 hover:bg-opacity-10"
              />
            )
          ) : (
            <img
              onClick={() => {
                navigate("/singin");
              }}
              src={is_favorite ? heartfill : heartEmpty}
              alt=""
              className="w-8 h-8 transition duration-300 ease-linear rounded-md cursor-pointer hover:scale-105 hover:bg-red-700 hover:bg-opacity-10"
            />
          )}
        </div>
      </div>
      <p className="text-xl">{name}</p>
      <div className="flex gap-5">
        <img src={dateIcon} alt="" />
        <p>
          {t("from")} {formatDate(start_date)} {t("until")}{" "}
          {formatDate(end_date)} ,{formatDateMonth(item.start_date)}
          {t("to")} {formatDateMonth(item.end_date)}
        </p>
      </div>
      <div className="flex gap-5">
        <img src={locationIcon} alt="" />
        <p>{location}</p>
      </div>
    </div>
  );
};

export default EventCard;
