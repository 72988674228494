import { useLink } from "hooks/useLink";
import React from "react";
import ArrowLeft from "assets/icons/ArrowLeft.svg";
import ArrowRight from "assets/icons/ArrowRight.svg";
const SiinMediaTestimonials_Responsive = () => {
  const { data, isLoading } = useLink();
  const [count, setCount] = React.useState(0);
  const lang = localStorage.getItem("i18nextLng");
  const rotationDegree = lang === "ar" ? 90 : 0;
  const rotationClass =
    rotationDegree === 90 ? "rotate-[90deg]" : "rotate-[90deg]";

  const itemsPerSectionResponsive = 3;

  const fetchedLinksResponsive = data?.links || [];
  const displayedLinksResponsive = fetchedLinksResponsive.slice(
    count * itemsPerSectionResponsive,
    (count + 1) * itemsPerSectionResponsive
  );

  const handlePrevButtonClick = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };

  const handleNextButtonClickRes = () => {
    const maxCount =
      Math.ceil(fetchedLinksResponsive.length / itemsPerSectionResponsive) - 1;
    if (count < maxCount) {
      setCount(count + 1);
    }
  };
  return (
    <>
      {!!data ? (
        <div className="flex flex-col items-center justify-center h-full gap-20 pb-10 mt-10 md:hidden ">
          <img
            src={ArrowLeft}
            onClick={handlePrevButtonClick}
            className={`sm:mr-10 w-20 h-5 sm:h-10 flex items-center justify-center ${
              count > 0 ? "" : "opacity-10 "
            }${rotationClass}`}
            alt=""
          />
          <div className="flex flex-col justify-around w-full h-full gap-40 px-10 ">
            {displayedLinksResponsive
              ?.filter((item) => item.type === "social_media")
              .map((item, index) => (
                <div key={index}>
                  <a href={item.url}>
                    <img src={item.icon} alt="" />
                  </a>
                </div>
              ))}
          </div>
          <img
            src={ArrowRight}
            alt=""
            onClick={handleNextButtonClickRes}
            className={`w-28 h-5 sm:h-10 flex items-center justify-center ${
              count <
              Math.ceil(data?.links?.length / itemsPerSectionResponsive) - 1
                ? ""
                : "   opacity-10 "
            }${rotationClass}`}
          />
        </div>
      ) : (
        <div className="flex justify-center w-full ">
          {isLoading && <p className="loader"> </p>}
        </div>
      )}
    </>
  );
};

export default SiinMediaTestimonials_Responsive;
