import * as yup from "yup";

export const addEvent = () => {
  let schema = yup.object().shape({
    // name_en: yup.string().required("Name English is required"),
    // name_ar: yup.string().required("Name Arabic is required"),
    // location_en: yup.string().required("Location English is required"),
    // location_ar: yup.string().required("Location Arabic is required"),
    // description_en: yup.string().required("Description English is required"),
    // description_ar: yup.string().required("Description Arabic is required"),
    // details_en: yup.string().required("Details English is required"),
    // details_ar: yup.string().required("Details Arabic is required"),
    // phone: yup
    //   .string()
    //   .matches(/^\d+$/, "Phone must be a valid number")
    //   .required("Phone is required"),
    // category_id: yup.string().required("Category is required"),
    // city_id: yup.string().required("City is required"),
    // // sub_category_ids: yup
    // // .array()
    // // .min(1, "At least one sub-category is required"),
    // sub_category_ids: yup
    //   .array()
    //   .of(yup.string())
    //   .required("Sub-Category is required"),

    // link: yup
    //   .string()
    //   .matches(
    //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //     "Enter correct url!"
    //   )
    //   .required("Please enter link"),
    // ticket_link: yup
    //   .string()
    //   .matches(
    //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //     "Enter correct url!"
    //   )
    //   .required("Please enter ticket link"),
    // start_date: yup.string().required("Start Date is required"),
    // end_date: yup.string().required("End Date is required"),
    // tickets: yup.array().of(
    //   yup.object().shape({
    //     name: yup.string().required("Ticket name and price is required"),
    //   })
    // ),
    // tags: yup.array().of(
    //   yup.object().shape({
    //     name: yup.string().required("Tags name is required"),
    //   })
    // ),

    // selectedImages: yup
    //   .mixed()
    //   .required("selectedImages is required")
    //   .test(
    //     "fileSize",
    //     "File size is required and must be less than 4MB",
    //     (value) => value && value[0] && value[0].size <= 4096 * 4096
    //   ),
  });
  return {
    schema,
  };
};
