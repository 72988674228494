import React from "react";
import ArrowLeft from "assets/icons/ArrowLeft.svg";
import ArrowRight from "assets/icons/ArrowRight.svg";
import { useLink } from "hooks/useLink";
import SiinMediaTestimonialsResponsive from "./SiinMediaTestimonials_Responsive";

const SiinMediaTestimonials = () => {
  const { data, isLoading } = useLink();
  const [count, setCount] = React.useState(0);
  const lang = localStorage.getItem("i18nextLng");
  const rotationDegree = lang === "ar" ? 180 : 0;
  const rotationClass = rotationDegree === 180 ? "rotate-180" : "";
  const itemsPerSection = 3;
 

  const displayedLinks = data?.links?.slice(
    count * itemsPerSection,
    (count + 1) * itemsPerSection
  );

  const handlePrevButtonClick = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };

  const handleNextButtonClick = () => {
    const maxCount = Math.ceil(data?.links?.length / itemsPerSection) - 1;
    if (count < maxCount) {
      setCount(count + 1);
    }
  };

  return (
    <>
      <div className="hidden md:block">
        <h1 className="py-20 text-2xl font-bold text-center ">
          Siin in the Media & Testimonials
        </h1>
        <div className="flex justify-center w-full ">
          {isLoading && <p className="loader"> </p>}
        </div>
        <div className="flex items-center justify-between py-52">
          <img
            src={ArrowLeft}
            onClick={handlePrevButtonClick}
            className={`sm:mr-10 w-20 h-5 sm:h-10 flex items-center justify-center ${
              count > 0 ? "flex" : "  opacity-10 "
            } ${rotationClass}`}
            alt=""
          />
          <div className="flex justify-around w-full">
            {displayedLinks
              ?.filter((item) => item.type === "social_media")
              .map((item, index) => (
                <div key={index}>
                  <a href={item.url}>
                    <img src={item.icon} alt="" />
                  </a>
                </div>
              ))}
          </div>

          <img
            src={ArrowRight}
            alt=""
            onClick={handleNextButtonClick}
            className={`w-28 h-5 sm:h-10 flex items-center justify-center ${
              count < Math.ceil(data?.links?.length / itemsPerSection) - 1
                ? ""
                : "   opacity-10 "
            }${rotationClass}`}
          />
        </div>
      </div>
      {/* Responsive */}
      <SiinMediaTestimonialsResponsive />
    </>
  );
};

export default SiinMediaTestimonials;
