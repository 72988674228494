import { _axios } from "interceptor/http-config";
export const _AuthApi = {
  login: (data) => {
    return _axios.post("/user/login", data).then((res) => {
      _AuthApi.storeToken(res?.data?.user?.access_token);
      _AuthApi.storeRole(res?.data?.user?.role);
      _AuthApi.storeID(res?.data?.user?.id);
    });
  },

  register: async (data) => {
    return _axios
      .post("/user/register", data)
      .then((res) => console.log("done"));
  },
  verifyEmail: async (data) => {
    return _axios
      .post("/user/email-verify", data)
      .then((res) => console.log("done"));
  },
  storeToken: (access_token) => {
    localStorage.setItem("access_token", access_token);
  },
  storeRole: (role) => {
    localStorage.setItem("role", role);
  },
  storeID: (id) => {
    localStorage.setItem("id", id);
  },

  getToken: () => localStorage.getItem("access_token"),
  getRole: () => localStorage.getItem("role"),
  getID: () => localStorage.getItem("id"),

  destroyToken: () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("role");
    window.location.reload();
  },

  resetPass: async (data) => {
    return _axios
      .post("/user/resetPassword", data)
      .then((res) => console.log("done"));
  },
  verifyCode: async (data) => {
    return _axios
      .post("/user/checkCode", data)
      .then((res) => console.log("done"));
  },
  passEdit: async (data) => {
    return _axios
      .post("/user/editPassword", data)
      .then((res) => console.log("done"));
  },
};
