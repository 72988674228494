import React from "react";
import img1 from "assets/images/test/1.png";
import img2 from "assets/images/test/2.png";
import img3 from "assets/images/test/3.png";
import img4 from "assets/images/test/4.png";
import img5 from "assets/images/test/5.png";
import { settingsStore } from "store/settingsStore";
const About = () => {
  const [direction] = settingsStore((state) => [
    state.direction,
    state.setDirection,
  ]);
  const description = [
    {
      icon: img1,
    },
    {
      icon: img2,
    },
    {
      icon: img3,
    },
    {
      icon: img4,
    },
    {
      icon: img5,
    },
  ];
  return (
    <div className="flex flex-col items-center justify-center px-2 text-black flexs dark:text-white">
      <h1 className="py-20 text-2xl font-bold ">What it's all about</h1>
      {/* desktop */}
      <div className="hidden md:flex ">
        {description?.map((item, index) => (
          <div
            key={index}
            className={`
            ${direction === "ltr" && index === 0 ? "mt-20 -rotate-12  " : ""} 
            ${direction === "rtl" && index === 0 && " mt-20 rotate-[30deg]"} 
            ${direction === "rtl" && index === 1 && "rotate-[20deg]"} 
            ${direction === "rtl" && index === 3 && "-rotate-[20deg]"} 
            ${direction === "ltr" && index === 4 && "mt-20 rotate-12"} 
            ${direction === "rtl" && index === 4 && "mt-20 -rotate-45"}
              mx-[-30px] transform `}
          >
            <img src={item.icon} className="" alt="" />
            <h1>{item.desc}</h1>
          </div>
        ))}
      </div>
      {/* desktop */}

      {/* mobile */}
      <div className="flex flex-col md:hidden">
        <div className="flex">
          {description?.map((item, index) => (
            <div
              key={index}
              className={`
          ${
            direction === "ltr" && index === 0
              ? " hidden mt-20 -rotate-12  "
              : ""
          } 
          ${direction === "rtl" && index === 0 && " mt-20 rotate-[30deg]"} 
          ${direction === "rtl" && index === 1 && "rotate-[20deg]"} 
          ${direction === "rtl" && index === 3 && "-rotate-[20deg]"} 
          ${direction === "ltr" && index === 4 && "hidden mt-20 rotate-12"} 
          ${direction === "rtl" && index === 4 && "hidden mt-20 -rotate-45"}
          mx-[-5px]   transform `}
            >
              <img src={item.icon} className="" alt="" />
              <h1>{item.desc}</h1>
            </div>
          ))}
        </div>
        <div className="flex">
          {description?.map((item, index) => (
            <div
              key={index}
              className={`
          ${direction === "ltr" && index === 0 ? "mt-10 -rotate-[5deg]" : ""} 
          ${direction === "rtl" && index === 0 && "mt-10 rotate-[30deg]"} 
          ${index === 1 && "hidden "} 
          ${index === 2 && "hidden "} 
          ${index === 3 && "hidden "} 
          ${direction === "ltr" && index === 4 && "mt-10 rotate-[5deg]"} 
          ${direction === "rtl" && index === 4 && "mt-10 -rotate-45"}
          mx-[2px]   transform `}
            >
              <img src={item.icon} className="" alt="" />
              <h1>{item.desc}</h1>
            </div>
          ))}
        </div>
      </div>
      {/* mobile */}

      <div className="px-5 py-10 leading-10 text-center md:px-32 md:grid-cols-2 md:grid">
        <h1>
          <span className="text-secondry-color">
            Have you ever wondered if there was a single place online that you
            could go to and find out everything that was happening in the
            country?
          </span>
          <br />
          All events and all activities and things to do?One place where you
          could check out and pick which seminars or art exhibitions you would
          like to attend, what concerts you should not miss, where you could
          take your kids for some fun time, with whom you could go for a fresh
          nature hike, etc?
        </h1>
        <h1>
          Have you ever wondered if there was a single place online that you
          could go to and find out everything that was happening in the country?
          All events and all activities and things to do?One place where you
          could check out and pick which seminars or art exhibitions you would
          like to attend, what concerts you should not miss, where you could
          take your kids for some fun time, with whom you could go for a fresh
          nature hike, etc?
        </h1>
      </div>
      <h1 className="py-20 text-2xl text-secondry-color ">Our Mission</h1>
    </div>
  );
};

export default About;
