import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect, useState } from "react";
import { _axios } from "interceptor/http-config";
import { Box } from "@mui/material";
import { HttpRequestInterceptor } from "./../../interceptor/http-request.interceptor";
import { DarkModeContext } from "theme/provider/DarkModeProvider";

export default function ControllableStates({
  setSubCategory,
  category_id,
  label,
  helperText,
  error,
  ...rest
}) {
  const [value, setValue] = useState([]);
  const [options, setOptions] = useState([]);
  const { darkToggle } = React.useContext(DarkModeContext);

  useEffect(() => {
    if (category_id) {
      _axios
        .get(`user/sub-category?category=${category_id}`, category_id, {
          headers: { ...HttpRequestInterceptor() },
        })
        .then((response) => {
          setOptions(response.data.sub_categories);
        });
    }
  }, [category_id]);

  return (
    <Box>
      <Autocomplete
        value={value ?? []}
        multiple
        disableClearable
        loading
        onChange={(event, newValue) => {
          const selectedIds = newValue.map((option) => option.id);
          setSubCategory("sub_category_ids", selectedIds);
          setValue(newValue);
          rest?.onChange?.();
        }}
        id="controllable-states-demo"
        sx={{
          width: "100%",
          color: "gray",
          borderRadius: "gray",
          options: { color: "white" },
          borderColor: "gray",
        }}
        options={options || []}
        getOptionLabel={(option) => option?.name || ""}
        renderInput={(params) => {
          return (
            <TextField
              name="product"
              {...params}
              label={label}
              helperText={helperText}
              error={error}
              sx={{
                color: "#fff",
                label: { color: "gray" },
                options: { color: "#fff" },
                borderColor: "gray",
                border: darkToggle ? "1px solid gray" : "1px solid gray",
                borderRadius: "10px",
                "& .MuiOutlinedInput-root": {
                  "& .Mui-focused fieldset": {
                    borderColor: "gray",
                    border: darkToggle ? "1px solid gray" : "1px solid gray",
                    color: "white",
                  },
                },
                "& .MuiInputBase-input": {
                  color: darkToggle ? "white" : "black",
                },

                "& .css-i4bv87-MuiSvgIcon-root": {
                  backgroundColor: darkToggle ? "gray" : "",
                  borderRadius: "100%",
                },
                "& .css-6od3lo-MuiChip-label": {
                  color: darkToggle ? "white" : "black",
                },
                ".css-1jy569b-MuiFormLabel-root-MuiInputLabel-root": {
                  color: darkToggle ? "white" : "black",
                },
              }}
            />
          );
        }}
      >
        {options?.map((option) => (
          <div key={option?.id}>{option.name}</div>
        ))}
      </Autocomplete>
    </Box>
  );
}
