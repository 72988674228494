import React from "react";
import logo from "assets/images/sinLogo.png";
import logoWhite from "assets/images/logoSinDark.png";
import { useContext } from "react";
import { DarkModeContext } from "theme/provider/DarkModeProvider";

const LogoLodaer = () => {
  const { darkToggle } = useContext(DarkModeContext);

  return (
    <div className="logo-loader flex items-center h-screen w-full justify-center bg-transparent">
      <img
        src={darkToggle ? logo : logoWhite}
        alt=""
        className="w-40 h-40 lg:w-96 lg:h-80"
      />
    </div>
  );
};

export default LogoLodaer;
