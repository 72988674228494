import React from "react";
import logo from "assets/images/sinLogo.png";
import { NavLink } from "react-router-dom";
import Hamburger from "hamburger-react";
import lightIcon from "assets/icons/light_mode.svg";
import { motion } from "framer-motion";
import darkIcon from "assets/icons/dark_mode.svg";
import logoSinDark from "assets/images/logoSinDark.png";
import { _AuthApi } from "api/auth";
import searchIcon from "assets/icons/searchIcon.svg";
import filterNavBar from "assets/icons/FilterNavBar.svg";
import makeYourPlane from "assets/icons/makeYourPlane.svg";
import { useNavBarResponsiveLogic } from "./hooks/useNavBarResponsiveLogic";
import ResposniveSlider from "./components/ResposniveSlider";
// import homeIconBlack from "assets/icons/homeIconBlack.svg";

export let hanldeClickSub = "";

const NavBarResponsive = () => {
  const {
    t,
    profile,
    isLoading,
    darkToggle,
    setDarkToggle,
    isOpen,
    isOpenDrawer,
    selectedButton,
    eventTodayLoading,
    toggleLang,
    handleImageClick,
    handleLogout,
    drawerLinks,
    handleButtonClick,
    hanldeTodayEvent,
    navigate,
    direction,
    handleCloseNav,
    isOpenDrawerTop,
    setOpen,
    handleNavigateProfile,
    access_token,
    filter
  } = useNavBarResponsiveLogic();

  return (
    <div>
      {isOpenDrawerTop && (
        <div
          className="fixed top-0 w-full h-screen bg-black/40 dark:bg-gray-900/25"
          onClick={handleCloseNav}
        ></div>
      )}
      <div className="flex justify-between p-3">
        {darkToggle ? (
          <img
            src={logo}
            alt=""
            className="w-20"
            onClick={() => navigate("/")}
          />
        ) : (
          <img
            src={logoSinDark}
            alt=""
            className="w-20"
            onClick={() => navigate("/")}
          />
        )}
        {!_AuthApi.getToken() && (
          <div className="flex items-center h-10 gap-1 px-3 text-white rounded bg-third-color dark:bg-primary-color">
            <NavLink to="/singin">
              <p>{t("Sign in")}</p>
            </NavLink>{" "}
            /{" "}
            <NavLink to="/register">
              <p> {t("Sign up")} </p>
            </NavLink>
          </div>
        )}

        {_AuthApi.getToken() && (
          <div className="relative z-10 flex ">
            <p className="flex items-center px-5 text-center text-black capitalize dark:text-white">
              {t("Hi")}, {profile?.profile?.first_name}
            </p>
            {darkToggle ? (
              <Hamburger
                toggled={isOpenDrawerTop}
                toggle={setOpen}
                color="#ffff"
              />
            ) : (
              <Hamburger toggled={isOpenDrawerTop} toggle={setOpen} />
            )}

            <div
              className={`absolute ${
                direction === "ltr" ? "right-0 " : "left-0  text-left"
              } w-80 `}
            >
              {isOpenDrawerTop && (
                <motion.div
                  initial={{ x: direction === "ltr" ? "100%" : "-100%" }}
                  animate={{ x: direction === "ltr" ? 15 : -15 }}
                  transition={{ ease: "easeInOut", duration: 0.5 }}
                  className={`fixed top-0 z-30 h-screen  bg-fourth-color   dark:bg-black   w-80 `}
                >
                  <div className="flex flex-col p-4 text-black dark:text-white">
                    <div
                      className={`flex ${
                        direction === "ltr" ? "justify-end" : "justify-start"
                      } w-full `}
                    >
                      <Hamburger toggled={isOpenDrawerTop} toggle={setOpen} />
                    </div>
                    <div
                      onClick={handleNavigateProfile}
                      className={`  flex flex-start  ${
                        direction === "ltr"
                          ? "left-0"
                          : "justify-start flex flex-row-reverse"
                      }  w-full items-center gap-2 py-2 border border-primary-color border-l-0 border-r-0 border-t-0`}
                    >
                      {isLoading && <p className="loader"> </p>}

                      <img
                        className="w-12 h-12 rounded-full ring-2 ring-white"
                        src={profile?.profile?.image}
                        alt=""
                      />
                      <p>
                        {profile?.profile?.first_name} {""}{" "}
                        {profile?.profile?.last_name}
                      </p>
                    </div>
                    <div className="flex flex-col gap-5 mt-5">
                      {drawerLinks?.map((item, index) => (
                        <h1
                          key={index}
                          onClick={() => {
                            navigate(item.navigate);
                            setOpen(false);
                          }}
                          className="cursor-pointer hover:font-bold"
                        >
                          {item.body}
                        </h1>
                      ))}
                    </div>
                    <div
                      className={`${
                        direction === "ltr" ? "right-0 " : "justify-end "
                      }border border-primary-color border-l-0 border-r-0 border-b-0 w-full  flex mt-5 pt-2   gap-5 `}
                    >
                      <h1 onClick={handleLogout}>{t("Logout")}</h1>
                    </div>
                    <div
                      className={`${
                        direction === "ltr" ? "right-0 " : "justify-end "
                      }border border-primary-color border-l-0 border-r-0 border-b-0 w-full  flex mt-5 pt-2   gap-5 `}
                    >
                      <button
                        onClick={toggleLang}
                        className="text-black dark:text-white"
                      >
                        {t("AED")}
                      </button>
                      <button onClick={() => setDarkToggle(!darkToggle)}>
                        {darkToggle ? (
                          <img src={lightIcon} alt="" />
                        ) : (
                          <img src={darkIcon} alt="" />
                        )}
                      </button>
                    </div>
                  </div>
                </motion.div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-2 pb-8 mx-2">
        <div className="my-5">
          <form
          //  onSubmit={handleSearchSubmit}
          >
            <div
              style={{
                position: "relative",
                display: "inline-block",
              }}
            >
              <input
                type="search"
                placeholder="Search"
                className="py-2  bg-transparent border border-t-0 border-l-0 border-r-0 w-[350px]  mx-3 border-primary-color text-black dark:text-white"
              />
              <img
                src={searchIcon}
                alt="Search"
                className={`search-icon absolute top-[50%] ${
                  direction === "rtl" ? "left-[3px]" : "right-[3px]"
                }  translate-y-[-50%]`}
              />
            </div>
          </form>
        </div>

        <div className="flex items-start justify-start gap-2 w-1/1 ">
          <div className="relative flex flex-col text-secondry-color">
            <div className="flex items-center gap-3 p-2 rounded-md bg-third-color dark:bg-primary-color w-28 ">
              <img
                src={filterNavBar}
                alt=""
                onClick={handleImageClick}
                className={`cursor-pointer h-5 ${
                  isOpen ? "rotate-180" : ""
                } transition-transform duration-300 ease-in-out `}
              />

              <button
                onClick={handleImageClick}
                className={` w-full text-sm    text-black dark:text-white text-start${
                  isOpenDrawer ? "-z-10" : ""
                }`}
              >
                {t(selectedButton)}
              </button>
            </div>
            <div
              className={`absolute left-0   z-10 flex flex-col items-start   ${
                darkToggle ? "bg-black" : "bg-fourth-color"
              }  shadow top-full`}
              style={{
                transformOrigin: "top",
                transform: isOpen ? "scaleY(1)" : "scaleY(0)",
                transition: "transform 0.5s ease-in-out",
              }}
            >
              {isOpen && (
                <div className="flex flex-col items-start gap-1 py-1 ">
                  {filter?.map((item, index) => (
                    <button
                      key={index}
                      onClick={() =>
                        handleButtonClick(
                          item.buttonFirstValue,
                          item.buttonSecondValue
                        )
                      }
                      className={`w-28 text-start p-1 text-sm bg-third-color dark:bg-primary-color text-black dark:text-white rounded-md   `}
                    >
                      {item.value}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="flex gap-5 px-2 pb-10">
            <div className="flex items-center w-full gap-3 p-2 rounded-md bg-secondry-color ">
              <img
                src={makeYourPlane}
                alt=""
                onClick={handleImageClick}
                className={`cursor-pointer h-5 transition-transform duration-300 ease-in-out `}
              />
              {access_token ? (
                <button
                  className={` w-28 text-sm  bg-secondry-color text-black text-start font-bold  `}
                >
                  <NavLink to="/calendar">
                    <p>{t("Make your plan")}</p>
                  </NavLink>
                </button>
              ) : (
                <button
                  className={` w-28 text-sm  bg-secondry-color text-black text-start font-bold  `}
                >
                  <NavLink to="/singin">
                    <p>{t("Make your plan")}</p>
                  </NavLink>
                </button>
              )}
            </div>

            <button
              className="w-1/3 px-2 text-xs text-black rounded h-9 bg-third-color dark:bg-primary-color dark:text-white"
              onClick={hanldeTodayEvent}
            >
              {eventTodayLoading ? (
                <p className="loader"></p>
              ) : (
                <p>{t("Today")}</p>
              )}
            </button>
          </div>
        </div>
        {/* slider */}
        <ResposniveSlider />
      </div>
    </div>
  );
};

export default NavBarResponsive;
