import React from "react";

const WhatDoIneed = () => {
  return (
    <div className="flex flex-col items-center justify-center text-black flexs dark:text-white">
      <h1 className="hidden py-20 text-2xl font-bold md:block ">Why do I need this?</h1>
      <h1 className="text-2xl text-secondry-color ">
        Are you an events addict?
      </h1>
      <div className="gap-10 px-5 py-10 leading-10 text-center md:px-32 md:grid-cols-2 md:grid">
        <h1>
          Are you outgoing, like to mingle and meet new people? Are you living
          abroad and planning your next summer vacation back home? Would you
          like to take your family out on a Sunday afternoon? Are you an art
          enthusiast or an outdoor adventurer or a night owl? A foodie or a
          professional looking to expand your skills and advance your
          career?Lebtivity is the place for you to explore what is happening in
          the country; check out every event, activity, exhibition, or workshop,
          and plan what you want to do with your free time. Just head over to
          the homepage or any of your preferred categories, and have a look at
          the array of options that you can go to.
        </h1>
        <h1>
          If you want to remain in control of the way events are proposed, keep
          them in check with the sort "by time" option. If you'd rather have a
          look at whatever's most trending, flip the switch and observe what the
          Lebtivity community has to suggest with the "by popularity" sorting.To
          learn more about the advantages of using Lebtivity as your reference
          for events, head over to the Basics section in the support page.If you
          can't wait to enjoy discovering the wondrous possibilities that this
          country has to offer, don't give it another thought and just click
          here to connect and start surfing!
        </h1>
      </div>
      <h1 className="text-2xl text-secondry-color ">
        Are you organizing an event?
      </h1>
      <div className="gap-10 px-5 py-10 leading-10 text-center md:px-32 md:grid-cols-2 md:grid">

        <h1>
          Did you finish planning the coolest event ever? Are the venue and
          speakers for your seminar booked and ready? Are you looking for more
          exposure, for ways to let the online community know that they cannot
          miss this incredible opportunity?Lebtivity is a crowdsourced social
          tool that enables you to easily create and submit your own events to a
          unified national database and automatically promote them on the ever
          more popular calendar-based website visited by more than a thousand
          persons daily!
        </h1>
        <h1>
          Are you already signed in? Just head{" "}
          <span className="text-secondry-color">here</span> and upload your
          first event! For more info, check out our{" "}
          <span className="text-secondry-color">support</span> section.
        </h1>
      </div>
    </div>
  );
};

export default WhatDoIneed;
