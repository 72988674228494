import React from "react";
import { Rating } from "@mui/material";
import Carousel from "./Carousel";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
// import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/scrollbar";
import SpeciaEventCarousel from "./SpeciaEventCarousel";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import shareIcon from "assets/icons/shareIcon.svg";
import Modal from "react-modal";
import { useLogic } from "./hooks/useLogic";
import { Pagination as PaginationEvents } from "@mui/material";
import EventCard from "components/shared/EventCard";

Modal.setAppElement("#root");
const Home = () => {
  const {
    openModals,
    openModalsRes,
    closeModal,
    closeModalRes,
    hanldeFavorite,
    settings,
    heroImages,
    heroLoading,
    specialEvent,
    specialEventLoading,
    event,
    eventLoading,
    handleShare,
    handleShareRes,
    handleEventShowView,
    t,
    page,
    setPage,
    count,
  } = useLogic();
  return (
    <div className="">
      <div className="flex justify-center w-full py-5">
        {heroLoading && <p className="loader"> </p>}
      </div>
      <Carousel>
        {heroImages?.length > 0 &&
          heroImages?.map((item, index) => (
            <div key={index}>
              <img
                className="slider-image"
                src={item.image}
                alt={`img${index}`}
              />
            </div>
          ))}
      </Carousel>
      {/* <div className="flex justify-center w-full">
        {isLoading && <p className="loader"> </p>}
      </div>
      <div className="py-10 lg:mx-10 md:mx-0">
        {type === "category" ? (
          <div className="mt-10 md:justify-around md:items-center md:flex ">
            {subCategory?.map((item, index) => (
              <div
                key={index}
                className="flex flex-col items-center gap-5 py-2 mx-5 transition ease-linear rounded md:w-96 hover:scale-105 "
              >
                <img
                  src={item.image}
                  alt=""
                  className=" dark:hover:shadow-light-shadow hover:shadow-custom-shadow rounded-xl w-96 h-96"
                />
                <h1 className="text-2xl text-secondry-color">{item.name}</h1>
              </div>
            ))}
          </div>
        ) : (
          <div></div>
        )}
      </div> */}
      <div className="flex justify-end w-full my-10 ">
        <p className="w-40 text-center text-white rounded bg-secondry-color">
          {t("Event")}
        </p>
      </div>
      {/* Special Event */}
      <div className="block md:hidden">
        <div className="flex items-center justify-center w-full ">
          {specialEventLoading && <p className="loader"> </p>}
        </div>

        <SpeciaEventCarousel className="mt-80">
          {specialEvent?.map((item, index) => (
            <div key={index}>
              <div className="px-5 py-10 text-black border border-gray-400 dark:border-white rounded-xl dark:text-white">
                <img
                  src={item.image}
                  alt=""
                  onClick={() => handleEventShowView(item.id)}
                  className="drop-shadow-[0_35px_35px_rgba(0,0,0,0.15)] w-96 h-52"
                />
                <h1 className="py-2 text-xl font-bold text-start">
                  {item.name}
                </h1>
                <h1 className="py-2 text-start">{item.description}</h1>
                <div className="flex justify-between gap-5 ">
                  <Rating
                    precision={0.5}
                    readOnly
                    defaultValue={item.rate ?? "null"}
                  />
                  <img
                    src={shareIcon}
                    alt=""
                    onClick={() => handleShareRes(item.id)}
                  />
                </div>
              </div>
              <Modal
                isOpen={openModalsRes[item.id]}
                onRequestClose={() => handleShareRes(item.id)}
                style={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.4)",
                    zIndex: 1000,
                    width: "100%",
                  },
                  content: {
                    zIndex: 1001,
                    background: "white",
                    border: "none",
                    borderRadius: "8px",
                    width: "100%",
                    top: "90%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    height: "30%",
                  },
                }}
              >
                <div className="flex justify-between mb-10">
                  <h2>Share Options</h2>
                  <button className="close-button" onClick={closeModalRes}>
                    Close
                  </button>
                </div>
                <div className="flex w-full gap-5 share-icons">
                  <FacebookShareButton
                    url={`http://localhost:3000/event-show/${item.id}`}
                    quote={""}
                    hashtag={"#"}
                  >
                    <FacebookIcon size={40} round={true} />
                  </FacebookShareButton>
                  <TelegramShareButton
                    url={`http://localhost:3000/event-show/${item.id}`}
                    quote={""}
                    hashtag={"#"}
                  >
                    <TelegramIcon size={40} round={true} />
                  </TelegramShareButton>
                  <WhatsappShareButton
                    url={`http://localhost:3000/event-show/${item.id}`}
                    quote={""}
                    hashtag={"#"}
                  >
                    <WhatsappIcon size={40} round={true} />
                  </WhatsappShareButton>
                  <EmailShareButton
                    url={`http://localhost:3000/event-show/${item.id}`}
                    quote={""}
                    hashtag={"#"}
                  >
                    <EmailIcon size={40} round={true} />
                  </EmailShareButton>
                  <TwitterShareButton
                    url={`http://localhost:3000/event-show/${item.id}`}
                    quote={""}
                    hashtag={"#"}
                  >
                    <TwitterIcon size={40} round={true} />
                  </TwitterShareButton>
                  <FacebookMessengerShareButton
                    url={`http://localhost:3000/event-show/${item.id}`}
                    quote={""}
                    hashtag={"#"}
                  >
                    <FacebookMessengerIcon size={40} round={true} />
                  </FacebookMessengerShareButton>
                </div>
              </Modal>
            </div>
          ))}
        </SpeciaEventCarousel>
      </div>

      <section className="hidden md:block">
        <div className="flex items-center justify-center w-full ">
          {specialEventLoading && <p className="loader"> </p>}
        </div>
        <Swiper
          {...settings}
          navigation
          autoplay={{ delay: 3000 }}
          modules={[Navigation, Autoplay]}
          slidesPerView={3}
          id="MultiImage"
        >
          {specialEvent?.map((item, index) => (
            <SwiperSlide className="mx-2" key={index}>
              <div className="flex flex-col w-10/12 px-5 py-10 text-black border border-gray-400 dark:border-white rounded-xl dark:text-white">
                <img
                  src={item.image}
                  alt=""
                  onClick={() => handleEventShowView(item.id)}
                  className="drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] w-1/1 h-96"
                />
                <h1 className="text-xl font-bold ">{item.name}</h1>
                <p>{item.description}</p>
                <div className="flex justify-between gap-5 ">
                  <Rating
                    precision={0.5}
                    readOnly
                    defaultValue={item.rate ?? "null"}
                  />
                  <img
                    src={shareIcon}
                    alt=""
                    onClick={() => handleShare(item.id)}
                  />
                </div>
              </div>
              <Modal
                isOpen={openModals[item.id]}
                onRequestClose={() => handleShare(item.id)}
                style={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.4)",
                    zIndex: 1000,
                  },
                  content: {
                    zIndex: 1001,
                    background: "white",
                    border: "none",
                    borderRadius: "8px",
                    padding: "20px",
                    width: "20%",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    height: "20%",
                  },
                }}
              >
                <div className="flex justify-between mb-10">
                  <h2>Share Options</h2>
                  <button className="close-button" onClick={closeModal}>
                    Close
                  </button>
                </div>
                <div className="flex gap-5 share-icons">
                  <FacebookShareButton
                    url={`http://localhost:3000/event-show/${item.id}`}
                    quote={""}
                    hashtag={"#"}
                  >
                    <FacebookIcon size={40} round={true} />
                  </FacebookShareButton>
                  <TelegramShareButton
                    url={`http://localhost:3000/event-show/${item.id}`}
                    quote={""}
                    hashtag={"#"}
                  >
                    <TelegramIcon size={40} round={true} />
                  </TelegramShareButton>
                  <WhatsappShareButton
                    url={`http://localhost:3000/event-show/${item.id}`}
                    quote={""}
                    hashtag={"#"}
                  >
                    <WhatsappIcon size={40} round={true} />
                  </WhatsappShareButton>
                  <EmailShareButton
                    url={`http://localhost:3000/event-show/${item.id}`}
                    quote={""}
                    hashtag={"#"}
                  >
                    <EmailIcon size={40} round={true} />
                  </EmailShareButton>
                  <TwitterShareButton
                    url={`http://localhost:3000/event-show/${item.id}`}
                    quote={""}
                    hashtag={"#"}
                  >
                    <TwitterIcon size={40} round={true} />
                  </TwitterShareButton>
                  <FacebookMessengerShareButton
                    url={`http://localhost:3000/event-show/${item.id}`}
                    quote={""}
                    hashtag={"#"}
                  >
                    <FacebookMessengerIcon size={40} round={true} />
                  </FacebookMessengerShareButton>
                </div>
              </Modal>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>

      {/* Special Event */}
      {/* Event */}

      <section className="hidden text-black dark:text-white md:block">
        <div>
          <div className="px-10">
            <p>
              {event.pag ?? "null"} Events in Syria on,{" "}
              {new Date().getFullYear()}
            </p>
          </div>
        </div>
        <div className="flex justify-center w-full">
          {eventLoading && <p className="loader"> </p>}
        </div>
        <div className="gap-5 px-5 mt-10 md:grid-cols-2 md:grid lg:grid-cols-3 lg:grid 2xl:grid-cols-4 2xl:grid place-items-center">
          {event?.events.length > 0 &&
            event?.events?.map((item, index) => (
              <EventCard
                key={index}
                item={item}
                image={item.image}
                hanldeFavorite={() => hanldeFavorite(item.id)}
                handleEventShowView={() => handleEventShowView(item.id)}
                end_date={item.end_date}
                start_date={item.start_date}
                loadingFavorite={item.loadingFavorite}
                is_favorite={item.is_favorite}
                name={item.name}
                location={item.location}
              />
            ))}
        </div>
       
      </section>
      {/* res */}
      <section className="block px-5 my-0 text-black md:hidden dark:text-white">
        <Swiper
          {...settings}
          // navigation
          autoplay={{ delay: 3000 }}
          modules={[Pagination, Autoplay]}
          slidesPerView={1}
          pagination={{ clickable: true }}
        >
          {event?.events?.map((item, index) => (
            <SwiperSlide
              className="flex flex-col px-1 py-10 rounded-xl "
              key={index}
            >
              <EventCard
                index={index}
                item={item}
                image={item.image}
                hanldeFavorite={() => hanldeFavorite(item.id)}
                handleEventShowView={() => handleEventShowView(item.id)}
                end_date={item.end_date}
                start_date={item.start_date}
                loadingFavorite={item.loadingFavorite}
                is_favorite={item.is_favorite}
                name={item.name}
                location={item.location}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
      <div className="flex justify-center my-10 ">
          {event.pag && (
            <PaginationEvents
              page={page}
              onChange={(e, page) => setPage(page)}
              sx={{
                ".MuiPaginationItem-root.Mui-selected": {
                  backgroundColor: "#f5881f",
                },
                ".css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root": {
                  backgroundColor: "lightgray",
                },
              }}
              
              count={Math.ceil(event.pag / count) || 0}
            />
          )}
        </div>
      {/* Event */}
    </div>
  );
};

export default Home;
