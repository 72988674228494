import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useState } from "react";
import { _axios } from "interceptor/http-config";
import { Box } from "@mui/material";
import { HttpRequestInterceptor } from "./../../interceptor/http-request.interceptor";
import { DarkModeContext } from "theme/provider/DarkModeProvider";
import { useQuery } from "react-query";

export default function ControllableStates({
  setCityID,
  label,
  helperText,
  error,
  ...rest
}) {
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);
  const { darkToggle } = React.useContext(DarkModeContext);

  const { data: eventOrganizer } = useQuery(
    ["city"],
    async () => {
      return await _axios
        .get(`/user/city`, {
          headers: {
            ...HttpRequestInterceptor(),
          },
        })
        .then((res) => setOptions(res.data.cites));
    },
    {}
  );

  return (
    <Box>
      <Autocomplete
        disableClearable
        loading
        value={value}
        onChange={(event, newValue) => {
          setCityID("city_id", newValue?.id ?? null);
          setValue(newValue);
          // Reset the error when a new value is selected
          rest?.onChange?.();
        }}
        id="controllable-states-demo"
        sx={{
          width: "100%",
          color: "white",
          borderRadius: "gray",
          options: { color: "white" },
          borderColor: "gray",
          // backgroundColor:"red"
        }}
        options={options}
        getOptionLabel={(option) => option?.name || ""}
        renderInput={(params) => {
          return (
            <TextField
              name="product"
              {...params}
              label={label}
              helperText={helperText}
              error={error}
              sx={{
                color: "#fff",
                label: { color: "gray" },
                options: { color: "#fff" },
                borderColor: "gray",
                border: darkToggle ? "1px solid gray" : "1px solid gray",
                borderRadius: "10px",
                "& .MuiOutlinedInput-root": {
                  "& .Mui-focused fieldset": {
                    borderColor: "gray",
                    border: darkToggle ? "1px solid gray" : "1px solid gray",
                    color: "white",
                  },
                },
                "& .MuiInputBase-input": {
                  color: darkToggle ? "white" : "black",
                },
                "& .css-i4bv87-MuiSvgIcon-root": {
                  backgroundColor: darkToggle ? "gray" : "",
                  borderRadius: "100%",
                },
                ".css-1jy569b-MuiFormLabel-root-MuiInputLabel-root": {
                  color: darkToggle ? "white" : "black",
                },
              }}
            />
          );
        }}
      >
        {eventOrganizer?.map((option) => (
          <div key={option?.id}>{option.name}</div>
        ))}
      </Autocomplete>
    </Box>
  );
}
