import { yupResolver } from "@hookform/resolvers/yup";
import { _AddEvents } from "api/AddEvents/addEvents";
import axios from "axios";
import { _axios } from "interceptor/http-config";
import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { DarkModeContext } from "theme/provider/DarkModeProvider";
import { settingsStore } from "store/settingsStore";
import { addEvent } from "../validation/addEvent";
import { enqueueSnackbar } from "notistack";

export const useAddEvent = () => {
  const { schema } = addEvent();
  const { t } = useTranslation("index");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const formOptions = { resolver: yupResolver(schema) };
  const { register, handleSubmit, formState, setValue, watch, clearErrors } =
    useForm(formOptions);
  const { errors } = formState;
  const { mutate } = useMutation((data) => createPost(data));
  const { darkToggle } = useContext(DarkModeContext);
  const [direction] = settingsStore((state) => [state.direction]);
  const [selectedImagesMultiple, setSelectedImagesMultiple] = useState([]);
  const [selectedImages, setSelectedImage] = useState(null);
  const [createEventLoading, setCreateEventLoading] = useState(false);
  const lang = localStorage.getItem("i18nextLng");
  const [tickets, setTickets] = useState([{ name: "" }]);
  const [tags, setTags] = useState([{ name: "" }]);
  useEffect(() => {
    window.scrollTo(400, 400);
  }, []);

  const details = [
    {
      head: t("Name English"),
      type: "text",
      placeholder: t("Name English"),
      name: "name_en",
      register: "name_en",
      error: "name_en",
      helperText: "name_en",
    },
    {
      head: t("Name Arabic"),
      type: "text",
      placeholder: t("Name Arabic"),
      name: "name_ar",
      register: "name_ar",
      error: "name_ar",
      helperText: "name_ar",
    },
    {
      head: t("Start Date"),
      type: "datetime-local",
      placeholder: t("Start Date"),
      name: "start_date",
      register: "start_date",
      error: "start_date",
      helperText: "start_date",
    },
    {
      head: t("End Date"),
      type: "datetime-local",
      placeholder: t("End Date"),
      name: "end_date",
      register: "end_date",
      error: "end_date",
      helperText: "end_date",
    },
    {
      head: t("Phone"),
      type: "number",
      placeholder: t("Phone"),
      name: "phone",
      register: "phone",
      error: "phone",
      helperText: "phone",
    },
    {
      head: t("Location English"),
      type: "text",
      placeholder: t("Location English"),
      name: "location_en",
      register: "location_en",
      error: "location_en",
      helperText: "location_en",
    },
    {
      head: t("Location Ararbic"),
      type: "text",
      placeholder: t("Location Ararbic"),
      name: "location_ar",
      register: "location_ar",
      error: "location_ar",
      helperText: "location_ar",
    },
    {
      head: t("Description English"),
      type: "text",
      placeholder: t("Description English"),
      name: "description_en",
      register: "description_en",
      error: "description_en",
      helperText: "description_en",
    },
    {
      head: t("Description Arabic"),
      type: "text",
      placeholder: t("Description Arabic"),
      name: "description_ar",
      register: "description_ar",
      error: "description_ar",
      helperText: "description_ar",
    },
    {
      head: t("Details English"),
      type: "text",
      placeholder: t("Details English"),
      name: "details_en",
      register: "details_en",
      error: "details_en",
      helperText: "details_en",
    },
    {
      head: t("Details Arabic"),
      type: "text",
      placeholder: t("Details Arabic"),
      name: "details_ar",
      register: "details_ar",
      error: "details_ar",
      helperText: "details_ar",
    },
    {
      head: t("Link"),
      type: "text",
      placeholder: t("Link"),
      name: "link",
      register: "link",
      error: "link",
      helperText: "link",
    },
    {
      head: t("Ticket Link"),
      type: "text",
      placeholder: t("Ticket Link"),
      name: "ticket_link",
      register: "ticket_link",
      error: "ticket_link",
      helperText: "ticket_link",
    },
  ];

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImagesMultiple([...selectedImagesMultiple, ...files]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    setSelectedImagesMultiple([...selectedImagesMultiple, ...files]);
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleDrag = (e) => {
    e.preventDefault();
  };

  const handleDropImage = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setSelectedImage(file);
  };

  const handleTicketChange = (event, index, field) => {
    const newTickets = [...tickets];
    newTickets[index][field] = event.target.value;
    setTickets(newTickets);
  };

  const handleAddTicket = () => {
    setTickets([...tickets, { name: "" }]);
  };

  const handleRemoveLastTicket = () => {
    if (tickets.length > 1) {
      const newTickets = [...tickets];
      newTickets.pop();
      setTickets(newTickets);
    }
  };
  const handleTagsChange = (event, index, field) => {
    const newTags = [...tickets];
    newTags[index][field] = event.target.value;
    setTags(newTags);
  };

  const handleAddTags = () => {
    setTags([...tags, { name: "" }]);
  };

  const handleRemoveLastTags = () => {
    if (tags.length > 1) {
      const newTags = [...tags];
      newTags.pop();
      setTags(newTags);
    }
  };
  const { data: eventOrganizer, isLoading } = useQuery(
    ["organizer"],
    async () => {
      return await _axios
        .get(`/user/organizer/event?count=4`, {
          headers: {
            ...HttpRequestInterceptor(),
          },
        })
        .then((res) => res.data?.events);
    },
    {}
  );

  const formatDate = (dateTimeString) => {
    const formattedTime = new Date(dateTimeString).toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return formattedTime;
  };
  const formatDateMonth = (dateTimeString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Date(dateTimeString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };
  const handleEventShowView = (id) => {
    navigate(`/event-show/${id}`);
  };

  async function createPost(data) {
    setCreateEventLoading(true);
    await axios;
    _AddEvents
      .post(data, setLoading, navigate)
      .then((res) => {
        setLoading(true);
        setCreateEventLoading(true);
      })
      .catch((error) => {
        enqueueSnackbar(error?.response?.data?.message, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });
      })
      .finally(() => {
        setLoading(false);
        setCreateEventLoading(false);
      });
  }

  const hanldeCreate = (input) => {
    const formData = new FormData();
    formData.append("name_en", input.name_en);
    formData.append("name_ar", input.name_ar);
    formData.append("start_date", input.start_date);
    formData.append("end_date", input.end_date);
    formData.append("phone", input.phone);
    formData.append("location_en", input.location_en);
    formData.append("location_ar", input.location_ar);
    formData.append("description_en", input.description_en);
    formData.append("description_ar", input.description_ar);
    formData.append("details_en", input.details_en);
    formData.append("details_ar", input.details_ar);
    formData.append("link", input.link);
    formData.append("ticket_link", input.ticket_link);
    formData.append("category_id", input.category_id);
    formData.append("city_id", input.city_id);
    formData.append("card_image", selectedImages);
    for (let i = 0; i < selectedImagesMultiple.length; i++) {
      formData.append(`images[${i}]`, selectedImagesMultiple[i]);
    }

    input.tickets.forEach((ticket, index) => {
      formData.append(`ticket[${index}]`, ticket.name);
    });
    input.tags.forEach((tag, index) => {
      formData.append(`tag[${index}]`, tag.name);
    });
    input.sub_category_ids?.forEach((sub, index) => {
      formData.append(`sub_category_ids[${index}]`, sub);
    });

    mutate(formData);
    setLoading(true);
  };

  return {
    t,
    loading,
    register,
    handleSubmit,
    setValue,
    errors,
    darkToggle,
    details,
    selectedImagesMultiple,
    setSelectedImagesMultiple,
    handleImageChange,
    handleDragOver,
    handleDrop,
    selectedImages,
    setSelectedImage,
    handleImage,
    handleDrag,
    handleDropImage,
    lang,
    tickets,
    setTickets,
    tags,
    setTags,
    handleTicketChange,
    handleAddTicket,
    handleRemoveLastTicket,
    handleTagsChange,
    handleAddTags,
    handleRemoveLastTags,
    hanldeCreate,
    eventOrganizer,
    isLoading,
    direction,
    formatDate,
    formatDateMonth,
    handleEventShowView,
    watch,
    clearErrors,
    createEventLoading,
  };
};
