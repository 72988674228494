import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { settingsStore } from "store/settingsStore";
import { useTranslation } from "react-i18next";
import { DarkModeContext } from "theme/provider/DarkModeProvider";
import { _AuthApi } from "api/auth";
import { typeStore } from "store/typeStore";
import { categoryStore } from "store/categoryStore";
import { startDateStore } from "store/startDateStore";
import { cityStore } from "store/cityStore";
import { _axios } from "interceptor/http-config";
import { useProfile } from "hooks/useProfile";

export let hanldeClickSub = "";
export const useNavBarResponsiveLogic = () => {
  const role = _AuthApi.getRole();
  const navigate = useNavigate();
  const access_token = _AuthApi.getToken();
  const { t, i18n } = useTranslation("navbar");
  const id = _AuthApi.getID();
  const { data: profile, isLoading } = useProfile(id);
  const { darkToggle, setDarkToggle } = useContext(DarkModeContext);
  const [isOpen, setIsOpen] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [isOpenDrawer, setOpenDrawe] = useState(false);
  const [type, setType] = typeStore((state) => [state.type, state.setType]);
  const [setCategory] = categoryStore((state) => [state.setCategory]);
  const [setCity] = cityStore((state) => [state.setCity]);
  const [setStartDate] = startDateStore((state) => [state.setStartDate]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedButton, setSelectedButton] = useState("Categories");
  const [selectedItem, setSelectedItem] = useState(null);
  const [isOpenDrawerTop, setOpen] = useState(false);
  const [eventTodayLoading, setEventTodayLoading] = useState(false);
  // const [active, setActive] = useState({ first: false, second: false });
  const [direction, setDirection] = settingsStore((state) => [
    state.direction,
    state.setDirection,
  ]);

  const itemsPerPage = 3;
  const canScrollLeft = startIndex > 0;
  const canScrollRight = startIndex + itemsPerPage < data?.items?.length;
  const filter = [
    {
      buttonFirstValue: "category",
      buttonSecondValue: t("Categories"),
      value: t("Categories"),
    },
    {
      buttonFirstValue: "date",
      buttonSecondValue: t("Date"),
      value: t("Date"),
    },
    {
      buttonFirstValue: "city",
      buttonSecondValue: t("City"),
      value: t("City"),
    },
  ];
  const drawerLinks = [
    { body: t("Add An Event"), navigate: "/add-event" },
    { body: t("My created events"), navigate: "/my-created-events" },

    { body: t("About"), navigate: "/siin-info/about" },
    { body: t("How it works"), navigate: "/how-it-works" },
  ];
  const handleScroll = (direction) => {
    if (direction === "left") {
      setStartIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    } else if (direction === "right") {
      setStartIndex((prevIndex) =>
        Math.min(prevIndex + 1, data?.items.length - itemsPerPage)
      );
    }
  };

  const hanldeClickReq = useCallback(() => {
    const apiUrl = `/user/home/navbar?${
      type !== "" ? `type=${type}` : "category"
    }`;
    setLoading(true);
    _axios
      .get(apiUrl)
      .then((res) => {
        setData(res.data);
        setLoading(true);
      })
      .finally(() => setLoading(false));
  }, [type]);
  if (type === "category") {
    hanldeClickSub = (clickedCategory) => {
      setCategory(clickedCategory);
    };
  } else if (type === "city") {
    hanldeClickSub = (clickedCity) => {
      setCity(clickedCity);
    };
  } else if (type === "date") {
    hanldeClickSub = (clickedStartDate) => {
      setStartDate(clickedStartDate);
    };
  }

  const handleItemClick = (itemName) => {
    setSelectedItem(itemName);
    hanldeClickSub(itemName);
    if (window.location.pathname !== "/") {
      navigate("/");
    }
  };
  const handleImageClick = () => {
    setIsOpen(!isOpen);
  };
  const handleButtonClick = (newType, buttonText) => {
    setSelectedButton(buttonText);
    setType(newType);
    setIsOpen(false);
  };

  const toggleLang = () => {
    setDirection(direction === "ltr" ? "rtl" : "ltr");
    i18n.changeLanguage(direction === "ltr" ? "ar" : "en");
  };
  const handleCloseNav = () => {
    setOpen(!isOpenDrawerTop);
  };
  const handleLogout = (input) => {
    _AuthApi.destroyToken(input)?.catch((err) => console.log(err));
  };

  useEffect(() => {
    hanldeClickReq();
  }, [type, hanldeClickReq]);

  const hanldeTodayEvent = () => {
    setEventTodayLoading(true);
    _axios
      .get("/user/home/event?today=true")
      .then(() => {
        setEventTodayLoading(false);
        if (window.location.pathname !== "/") {
          navigate("/");
        }
        window.scrollTo(2000, 2000);
      })
      .catch((error) => {
        setEventTodayLoading(false);
      });
  };
  const handleNavigateProfile = () => {
    if (role === "normal") {
      navigate(`/normal-profile/${id}`);
      setOpen(false);
    } else if (role === "organizer") {
      navigate(`/organizer-profile/${id}`);
      setOpen(false);
    } else if (role === "verified") {
      navigate(`/verified-profile/${id}`);
      setOpen(false);
    }
    window.scrollTo(0, 0);
  };
  return {
    filter,
    hanldeClickSub,
    role,
    t,
    profile,
    isLoading,
    darkToggle,
    setDarkToggle,
    data,
    setData,
    loading,
    setLoading,
    isOpen,
    setIsOpen,
    isOpenDrawer,
    setOpenDrawe,
    selectedButton,
    selectedItem,
    eventTodayLoading,
    toggleLang,
    handleImageClick,
    handleLogout,
    drawerLinks,
    handleButtonClick,
    handleItemClick,
    hanldeTodayEvent,
    navigate,
    direction,
    id,
    handleScroll,
    canScrollLeft,
    canScrollRight,
    handleCloseNav,
    isOpenDrawerTop,
    setOpen,
    startIndex,
    itemsPerPage,
    handleNavigateProfile,
    access_token,
  };
};
