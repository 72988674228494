import { _axios } from "interceptor/http-config";
import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";
import React, { useContext, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import dateIcon from "assets/icons/DateIcon.svg";
import locationIcon from "assets/icons/LocationIcon.svg";
import tagIcon from "assets/icons/eventShow/tagIcon1.svg";
import ticketIcon from "assets/icons/eventShow/ticketIcon.svg";
import { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { settingsStore } from "store/settingsStore";
import heartEmpty from "assets/icons/heartEmpty.svg";
import heartfill from "assets/icons/heartIconFill.png";
import linkIcon from "assets/icons/eventShow/linkIcon.svg";
import { DarkModeContext } from "theme/provider/DarkModeProvider";
import { kindStore } from "store/kindStore";
import { _AuthApi } from "api/auth";
import { useTranslation } from "react-i18next";

export const useLogic = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("index");
  const params = useParams();
  const access_token = _AuthApi.getToken();
  const role = _AuthApi.getRole();
  const queryClient = useQueryClient();
  const [body, setBody] = useState("");
  const [favoriteLoadingMap, setFavoriteLoadingMap] = useState({});
  const [dataGallery, setDataGallery] = React.useState();
  const [direction] = settingsStore((state) => [state.direction]);
  const [openModals, setOpenModals] = useState(false);
  const [openModalsRes, setOpenModalsRes] = useState(false);
  const [loading, setLoading] = useState(false);
  const { darkToggle } = useContext(DarkModeContext);
  const [rate, setRating] = useState("");
  const [ratingError, setRatingError] = useState("");
  const [review, setReview] = useState("");
  const [showAllComments, setShowAllComments] = useState(false);
  const [showAllReview, setShowAllReview] = useState(false);
  const [kind] = kindStore((state) => [state.kind]);
  const [commentLoading, setCommentLoading] = useState(false);
  const [remidenrLoading, setremidenrLoading] = useState(false);
  const settings = {
    dots: "true",
    infinite: "true",
    speed: 500,
    slidestoshow: 1,
    slidestoscroll: 1,
  };

  const { data, isLoading } = useQuery(
    ["event-show", `id-${params.id}`],
    async () => {
      return await _axios
        .get(`/user/event/${params.id}`, {
          headers: {
            ...HttpRequestInterceptor(),
          },
        })
        .then((res) => res.data?.event);
    },
    {}
  );

  const { data: event, isLoading: eventLoading } = useQuery(
    ["similer-event"],
    async () => {
      return await _axios
        .get(`user/similar-event/${params.id}`, {
          headers: {
            ...HttpRequestInterceptor(),
          },
        })
        .then((res) => res.data?.similar_events);
    },
    {}
  );

  const handleComment = async (e, event_id) => {
    e.preventDefault();
    setCommentLoading(true);
    const formData = new FormData();
    formData.append("body", body);
    formData.append("event_id", event_id);

    try {
      const response = await _axios.post("/user/comment", formData, {
        headers: {
          ...HttpRequestInterceptor(),
        },
      });
      console.log("Comment posted:", response.data);
    } catch (error) {
      console.error("Error posting comment:", error);
    } finally {
      setCommentLoading(false);
    }
  };

  const formatDate = (dateTimeString) => {
    const formattedTime = new Date(dateTimeString).toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return formattedTime;
  };
  const formatDateMonth = (dateTimeString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Date(dateTimeString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };

  const Section = ({ icon, content }) => (
    <div className="flex items-center gap-10 lg:px-10 ">
      {icon && (
        <img
          src={icon}
          onClick={() =>
            icon === data?.organizer_image
              ? navigate(`/organizer-profile/${data?.organizer_id}`)
              : ""
          }
          alt=""
          className={`w-8 h-8 lg:h-10 lg:w-10 ${
            icon === data?.organizer_image
              ? "cursor-pointer hover:scale-110"
              : ""
          }`}
        />
      )}
      <div>{content}</div>
    </div>
  );

  const sectionsContent = [
    {
      icon: data?.organizer_image,
      content: (
        <div
          className="cursor-pointer"
          onClick={() => navigate(`/organizer-profile/${data?.organizer_id}`)}
        >
          {`${data?.organizer_first_name} ${data?.organizer_last_name}`},
        </div>
      ),
    },
    {
      icon: dateIcon,
      content: (
        <h1>
          from {formatDate(data?.start_date)} until {formatDate(data?.end_date)}
          ,{formatDateMonth(data?.start_date)} to{" "}
          {formatDateMonth(data?.end_date)}
        </h1>
      ),
    },
    {
      icon: locationIcon,
      content: <h1>{data?.location}</h1>,
    },
    {
      icon: tagIcon,
      content: (
        <h1 className="flex items-center gap-2">
          {data?.tags?.map((item, index) => (
            <div key={index}>
              {item}
              {index !== data.tags.length - 1 && " & "}
            </div>
          ))}
        </h1>
      ),
    },
    {
      icon: linkIcon,
      content: (
        <a
          className="text-blue-600 underline decoration-1 "
          href={`http://${data?.link}`}
        >
          {data?.link}
        </a>
      ),
    },
    {
      content: (
        <>
          <h1 className="py-10 text-2xl font-bold text-secondry-color">
            {data?.name ?? "null"}
          </h1>
          <h1
            className="flex flex-col lg:flex-row lg:flex "
            style={{ whiteSpace: "pre-line" }}
          >
            {data?.details ?? "null"}
          </h1>
        </>
      ),
    },
    {
      content: (
        <>
          <h1 className="py-10 text-2xl font-bold text-secondry-color">
            {t("Tickets")}:
          </h1>
          <h1 className="flex flex-col items-center lg:flex-row lg:flex ">
            {data?.tickets?.map((item, index) => (
              <div key={index} className="flex items-center ">
                <img
                  src={ticketIcon}
                  alt=""
                  className="w-8 h-8 lg:h-10 lg:w-10"
                />
                {item}
              </div>
            ))}
          </h1>
        </>
      ),
    },
    {
      content: (
        <>
          <p className="flex gap-5 py-10 lg:text-2xl ">
            {t("Tickets on")}:
            <a
              href={`http://${data?.ticket_link}`}
              target="_balank"
              className="text-blue-600 underline decoration-1 "
            >
              {data?.ticket_link ?? "null"}
            </a>
          </p>
        </>
      ),
    },
  ];

  const hanldeReview = async (e, event_id) => {
    e.preventDefault();
    if (rate === "") {
      setRatingError("Please select a rating");
      return;
    }
    const formData = new FormData();
    formData.append("rate", rate);
    formData.append("review", review);
    formData.append("event_id", event_id);

    try {
      const response = await _axios.post("/user/review", formData, {
        headers: {
          ...HttpRequestInterceptor(),
        },
      });
      console.log("review posted:", response.data);
    } catch (error) {
      console.error("Error posting review:", error);
    }
  };

  const addPlan = useMutation(
    (id) => _axios.get(`/user/event/plan-toggle/${data?.id}`),
    {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: () => {
        queryClient.invalidateQueries();
      },

      onSettled: () => {
        setLoading(false);
      },
    }
  );

  const addReminder = useMutation(
    (id) => _axios.get(`/user/event/reminder-toggle/${data?.id}`),
    {
      onMutate: () => {
        setremidenrLoading(true);
      },
      onSuccess: () => {
        queryClient.invalidateQueries();
      },

      onSettled: () => {
        setremidenrLoading(false);
      },
    }
  );
  const like = useMutation(
    ({ likeId, likeType }) =>
      _axios.get(`/user/comment/like-toggle/${likeId}?type=${likeType}`),
    {
      onMutate: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
      onSettled: () => {},
    }
  );

  const handleToggleAddToPlane = (id) => {
    addPlan.mutate(id);
  };
  const handleToggleAddReminder = (id) => {
    addReminder.mutate(id);
  };
  const handleToggleLike = (likeId, likeType) => {
    like.mutate({ likeId, likeType });
  };
  const handleClick = (index) => setDataGallery(index);

  const handleChange = (e) => {
    setBody(e.target.value);
  };
  const openModal = () => {
    setOpenModals(true);
  };
  const closeModal = () => {
    setOpenModals(false);
  };

  const openModalRes = () => {
    setOpenModalsRes(true);
  };
  const closeModalRes = () => {
    setOpenModalsRes(false);
  };
  const handleEventShowView = (id) => {
    navigate(`/event-show/${id}`);
    window.scrollTo(0, 0);
  };
  
  const toggleFavorite = useMutation(
    (id) => {
      setFavoriteLoadingMap((prevMap) => ({
        ...prevMap,
        [id]: true,
      }));
      const formData = new FormData();
      formData.append("kind", kind);
      formData.append("id", id);
      return _axios.post("/user/favorite-toggle", formData);
    },
    {
      onSettled: () => {
        setFavoriteLoadingMap(false);
      },
      onSuccess: (id) => {
        queryClient.invalidateQueries();
        setFavoriteLoadingMap((prevMap) => ({
          ...prevMap,
          [id]: false,
        }));
      },
    }
  );

  const hanldeFavorite = (id) => {
    toggleFavorite.mutate(id);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const limitedComments = showAllComments
    ? data?.comments
    : data?.comments?.slice(0, 3);

  const limitedReviews = showAllReview
    ? data?.reviews
    : data?.reviews?.slice(0, 3);

  return {
    kind,
    dataGallery,
    handleClick,
    isLoading,
    handleChange,
    handleComment,
    Section,
    sectionsContent,
    data,
    event,
    formatDate,
    eventLoading,
    dateIcon,
    formatDateMonth,
    locationIcon,
    direction,
    heartEmpty,
    heartfill,
    settings,
    openModals,
    openModalsRes,
    loading,
    openModal,
    closeModal,
    openModalRes,
    closeModalRes,
    darkToggle,
    setRating,
    ratingError,
    setRatingError,
    setReview,
    hanldeReview,
    handleToggleAddToPlane,
    handleToggleLike,
    handleEventShowView,
    showAllComments,
    setShowAllComments,
    showAllReview,
    setShowAllReview,
    limitedComments,
    limitedReviews,
    queryClient,
    role,
    navigate,
    favoriteLoadingMap,
    hanldeFavorite,
    access_token,
    t,
    commentLoading,
    handleToggleAddReminder,
    remidenrLoading,
  };
};
