import { useProfile } from "hooks/useProfile";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { settingsStore } from "store/settingsStore";
import { _axios } from "interceptor/http-config";
import { kindStore } from "store/kindStore";
import { useMutation, useQueryClient } from "react-query";
import { _AuthApi } from "api/auth";
import { useTranslation } from "react-i18next";

export const useLogic = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const access_token = _AuthApi.getToken();
  const queryClient = useQueryClient();
  const { data, isLoading } = useProfile(params.id);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [direction] = settingsStore((state) => [state.direction]);
  const [kind] = kindStore((state) => [state.kind]);
  const [showAllReviews, setShowAllReviews] = useState(false);
  const [coverImage, setCoverImage] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [coverImageLoading, setCoverImageLoading] = useState(false);
  const [profileImageLoading, setProfileImageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const handleEventShowView = (id) => {
    navigate(`/event-show/${id}`);
  };
  const formatDate = (dateTimeString) => {
    const formattedTime = new Date(dateTimeString).toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return formattedTime;
  };
  const formatDateMonth = (dateTimeString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Date(dateTimeString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };

  const handleNextEvent = () => {
    setActiveIndex(
      (prevIndex) => (prevIndex + 1) % data?.profile?.plans.length
    );
  };

  const handlePrevEvent = () => {
    setActiveIndex(
      (prevIndex) =>
        (prevIndex - 1 + data?.profile?.plans.length) %
        data?.profile?.plans.length
    );
  };

  const favoriteToggle = useMutation(
    (id) => {
      const formData = new FormData();
      formData.append("kind", kind);
      formData.append("id", id);
      return _axios.post("/user/favorite-toggle", formData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    }
  );

  const hanldeFavorite = (id) => {
    favoriteToggle.mutate(id);
  };

  const toggleReviewsDisplay = () => {
    setShowAllReviews(!showAllReviews);
  };
  const followtoggle = useMutation(
    (id) => _axios.get(`/user/follow-toggle/${id}`),
    {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: () => {
        queryClient.invalidateQueries();
      },

      onSettled: () => {
        setLoading(false);
      },
    }
  );

  const handleToggleUnFollow = (id) => {
    followtoggle.mutate(id);
  };

  return {
    t,
    data,
    params,
    startX,
    loading,
    direction,
    isLoading,
    coverImage,
    isDragging,
    scrollLeft,
    activeIndex,
    queryClient,
    access_token,
    profileImage,
    showAllReviews,
    coverImageLoading,
    profileImageLoading,
    setStartX,
    setLoading,
    formatDate,
    setScrollLeft,
    setCoverImage,
    setIsDragging,
    setActiveIndex,
    hanldeFavorite,
    setProfileImage,
    handleNextEvent,
    handlePrevEvent,
    formatDateMonth,
    handleEventShowView,
    handleToggleUnFollow,
    toggleReviewsDisplay,
    setCoverImageLoading,
    setProfileImageLoading,
  };
};
