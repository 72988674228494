import React, { useState } from "react";
import lightIcon from "assets/icons/light_mode.svg";
import darkIcon from "assets/icons/dark_mode.svg";
import searchIcon from "assets/icons/searchIcon.svg";
import sortIconWhite from "assets/icons/sortIconWhite.svg";
import sortIconBlack from "assets/icons/sortIconBlack.svg";
import logo from "assets/images/sinLogo.png";
import logoSinDark from "assets/images/logoSinDark.png";
import NavBarResponsive from "./NavBarResponsive";
import Hamburger from "hamburger-react";
import { motion } from "framer-motion";
import { _AuthApi } from "api/auth";
import { NavLink } from "react-router-dom";
import { useNavBarLogic } from "./hooks/useNavBarLogic";
import { useDebounce } from "hooks/useDebounce";
import { useSearch } from "hooks/useSearch";

function NavBar() {
  const {
    role,
    t,
    profile,
    isLoading,
    darkToggle,
    setDarkToggle,
    data,
    loading,
    isOpen,
    isOpenDrawer,
    setOpenDrawe,
    selectedButton,
    selectedItem,
    eventTodayLoading,
    toggleLang,
    handleImageClick,
    handleLogout,
    drawerLinks,
    handleButtonClick,
    handleItemClick,
    hanldeTodayEvent,
    navigate,
    direction,
    id,
    acces_token,
  } = useNavBarLogic();
  const { data: searchData, isLoading: searchLoading, setQuery } = useSearch();
  const [showSearch, setShowSearch] = useState(false);

  const handleSearchClick = () => {
    setShowSearch(!showSearch);
  };
  const hanldeView = (id) => {
    navigate(`/sub-category/product/${id}`);
    setShowSearch(false);
  };
  const handleSearchSubmit = (e) => {
    e.preventDefault();
  };
  const handleSearch = useDebounce((e) => {
    setQuery(e.target.value);
  }, 1000);
  return (
    <>
      <div className="hidden py-7 md:block ">
        <div className="flex items-center justify-between gap-10 px-5">
          <div>
            {darkToggle ? (
              <img
                src={logo}
                alt=""
                className="cursor-pointer w-80 md:w-40 "
                onClick={() => navigate("/")}
              />
            ) : (
              <img
                src={logoSinDark}
                alt=""
                className="cursor-pointer w-80 md:w-40"
                onClick={() => navigate("/")}
              />
            )}
          </div>
          <div className="flex flex-wrap ">
            <div className="flex flex-wrap gap-2 mx-10 ">
              <form onSubmit={handleSearchSubmit}>
                <div style={{ position: "relative", display: "inline-block" }}>
                  <input
                    type="search"
                    placeholder={t("Search")}
                    onChange={handleSearch}
                    className="w-full px-4 py-2 pr-8 text-black bg-transparent border border-t-0 border-l-0 border-r-0 2xl:w-60 border-primary-color dark:text-white"
                  />
                  <img
                    src={searchIcon}
                    alt="Search"
                    className={`search-icon absolute top-[50%] ${
                      direction === "rtl" ? "left-[3px]" : "right-[3px]"
                    } translate-y-[-50%] `}
                  />
                </div>
                <div className="flex items-center justify-center w-full ">
                  {searchLoading && <p className="loader"></p>}
                </div>
              </form>
            
              <button
                onClick={hanldeTodayEvent}
                className="  sm:w-[100px] 2xl:w-[150px] text-xs 2xl:text-sm px-1 bg-third-color dark:bg-primary-color rounded text-black dark:text-white"
              >
                {eventTodayLoading ? (
                  <p className="loader"></p>
                ) : (
                  <p>{t("Today")}</p>
                )}
              </button>
              {acces_token ? (
                <button
                  onClick={() => navigate("/calendar")}
                  className="  sm:w-[100px] 2xl:w-[150px] text-xs 2xl:text-sm px-1 bg-third-color dark:bg-primary-color rounded text-black dark:text-white"
                >
                  <p>{t("Make your plan")}</p>
                </button>
              ) : (
                <button
                  onClick={() => navigate("/singin")}
                  className="  sm:w-[100px] 2xl:w-[150px] text-xs 2xl:text-sm px-1 bg-third-color dark:bg-primary-color rounded text-black dark:text-white"
                >
                  <p>{t("Make your plan")}</p>
                </button>
              )}
              {role === "organizer" && (
                <button
                  onClick={() => navigate("/add-event")}
                  className="  sm:w-[100px] 2xl:w-[180px] text-xs 2xl:text-sm px-1 bg-secondry-color text-white rounded"
                >
                  <p>{t("Add an Events, it's free")}</p>
                </button>
              )}
              {!_AuthApi.getToken() ? (
                <div className="flex items-center gap-1 text-black dark:text-white">
                  <NavLink to="/singin">
                    <p className="cursor-pointer hover:font-bold">
                      {t("Sign in")}
                    </p>
                  </NavLink>{" "}
                  /{" "}
                  <NavLink to="/register">
                    <p className="cursor-pointer hover:font-bold">
                      {t("Sign Up")}
                    </p>
                  </NavLink>
                </div>
              ) : (
                <div></div>
              )}
            </div>

            <div className="flex items-center gap-2 sm:mt-5 md:mt-0 2xl:mt-0">
              <button
                onClick={toggleLang}
                className="mx-2 text-black dark:text-white"
              >
                {t("AED")}
              </button>
              <button onClick={() => setDarkToggle(!darkToggle)}>
                {darkToggle ? (
                  <img src={lightIcon} alt="" />
                ) : (
                  <img src={darkIcon} alt="" />
                )}
              </button>
            </div>
            {_AuthApi.getToken() ? (
              <p className="flex items-center px-5 text-center text-black capitalize dark:text-white">
                {t("Hi")}, {profile?.profile?.first_name}
              </p>
            ) : (
              ""
            )}
            {_AuthApi.getToken() ? (
              <div>
                <div className="relative z-10 ">
                  {darkToggle ? (
                    <Hamburger
                      toggled={isOpenDrawer}
                      toggle={setOpenDrawe}
                      color="#ffff"
                    />
                  ) : (
                    <Hamburger toggled={isOpenDrawer} toggle={setOpenDrawe} />
                  )}

                  <div
                    className={`absolute ${
                      direction === "ltr" ? "right-0 " : "left-0 text-left"
                    } w-60 top-[100%]  `}
                  >
                    {isOpenDrawer && (
                      <motion.div
                        transition={{ ease: "easeInOut", duration: 0.5 }}
                        className={`  bg-fourth-color  mt-2  dark:bg-black z-50`}
                      >
                        {isLoading && <p className="loader"> </p>}

                        <div className="flex flex-col p-4 text-black dark:text-white">
                          <div
                            onClick={() => {
                              if (role === "normal") {
                                navigate(`/normal-profile/${id}`);
                                setOpenDrawe(false);
                              } else if (role === "organizer") {
                                navigate(`/organizer-profile/${id}`);
                                setOpenDrawe(false);
                              } else if (role === "verified") {
                                navigate(`/verified-profile/${id}`);
                                setOpenDrawe(false);
                              }
                            }}
                            className={`  cursor-pointer flex flex-start ${
                              direction === "ltr"
                                ? "right-0 "
                                : "justify-start flex flex-row-reverse"
                            }  w-full items-center gap-2 py-2 border border-primary-color border-l-0 border-r-0 border-t-0`}
                          >
                            <img
                              className="w-12 h-12 rounded-full cursor-pointer ring-2 ring-white"
                              src={profile?.profile?.image}
                              alt=""
                            />
                            <p>
                              {profile?.profile?.first_name} {""}{" "}
                              {profile?.profile?.last_name}
                            </p>
                          </div>
                          <div className="flex flex-col gap-5 mt-5">
                            {drawerLinks?.map((item, index) => (
                              <p
                                key={index}
                                onClick={() => {
                                  navigate(item.navigate);
                                  setOpenDrawe(false);
                                }}
                                className="cursor-pointer hover:font-bold"
                              >
                                {item.body}
                              </p>
                            ))}
                          </div>
                          <div
                            className={`${
                              direction === "ltr" ? "right-0 " : "justify-end "
                            }border border-primary-color border-l-0 border-r-0 border-b-0 w-full  flex mt-5 pt-2   gap-5 `}
                          >
                            <p
                              onClick={handleLogout}
                              className="cursor-pointer hover:font-bold"
                            >
                              {t("Logout")}
                            </p>
                          </div>
                        </div>
                      </motion.div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <div className="fixed grid grid-cols-2 gap-10 place-items-center bg">
                {searchData?.event?.map((item, index) => (
                  <div
                    key={index}
                    className="flex justify-between mt-5 max-h-48"
                    onClick={() => hanldeView(item.id)}
                  >
                    <h1 className="text-white">{item.name}</h1>
                    <img src={item.image} alt="" className="w-20 h-20" />
                  </div>
                ))}
              </div>
        </div>
        <div className="flex items-center justify-end px-5 ">
          {/* <div className="flex gap-5">
            <button
              className="  sm:w-[100px] 2xl:w-[150px] text-xs 2xl:text-sm px-1 bg-secondry-color rounded h-10 text-white"
              onClick={() => navigate("/")}
            >
              <p>Home</p>
            </button>
          </div> */}
          <div className="flex items-center justify-center h-20 gap-2 ">
            {!darkToggle ? (
              <img
                src={sortIconBlack}
                alt=""
                onClick={handleImageClick}
                className={`cursor-pointer w-6 ${
                  isOpen ? "rotate-180" : ""
                } transition-transform duration-300 ease-in-out`}
              />
            ) : (
              <img
                src={sortIconWhite}
                alt=""
                onClick={handleImageClick}
                className={`cursor-pointer w-6 ${
                  isOpen ? "rotate-180" : ""
                } transition-transform duration-300 ease-in-out`}
              />
            )}
            <p className="text-black dark:text-white">{t("Sort by")}:</p>
            <div className="relative flex flex-col text-secondry-color">
              <input
                type="text"
                onChange={(e) => e}
                value={t(selectedButton)}
                className={`h-10 bg-transparent ${isOpenDrawer ? "-z-10" : ""}`}
              />

              <div
                className={`absolute left-0 flex flex-col items-start w-full ${
                  darkToggle ? "bg-black" : "bg-fourth-color"
                }  shadow top-full`}
                style={{
                  transformOrigin: "top",
                  transform: isOpen ? "scaleY(1)" : "scaleY(0)",
                  transition: "transform 0.5s ease-in-out",
                }}
              >
                {isOpen && (
                  <div className="flex flex-col items-start w-full ">
                    <button
                      onClick={() =>
                        handleButtonClick("category", t("Categories"))
                      }
                      className={`w-full p-3 text-left border border-t-0 border-l-0 border-r-0 ${
                        selectedButton === "Categories"
                          ? "border-primary-color"
                          : ""
                      }`}
                    >
                      {t("Categories")}
                    </button>
                    <button
                      onClick={() => handleButtonClick("date", t("Date"))}
                      className={`w-full p-3 text-left border border-t-0 border-l-0 border-r-0 ${
                        selectedButton === "Date" ? "border-primary-color" : ""
                      }`}
                    >
                      {t("Date")}
                    </button>
                    <button
                      onClick={() => handleButtonClick("city", t("City"))}
                      className={`w-full p-3 text-left border border-t-0 border-l-0 border-r-0 ${
                        selectedButton === "City" ? "border-primary-color" : ""
                      }`}
                    >
                      {t("City")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="w-full h-[0.1px] border border-black dark:border-white font-thin"></div>
        </div>
        <div className="flex justify-center w-full ">
          {loading && <p className="loader"> </p>}
        </div>
        <div className="flex flex-wrap justify-between h-full gap-5 px-5 my-10 text-black dark:text-white">
          {data?.items?.map((item, index) => (
            <div
              className={`
              ${
                selectedItem === item.name ||
                (item.default === 1 && selectedItem === null) ||
                (index === 0 && selectedItem === null && item.default !== 0)
                  ? "text-secondry-color hover:font-bold"
                  : ""
              }
         
              `}
              key={index}
            >
              <p
                onClick={() => handleItemClick(item.name)}
                className="cursor-pointer"
              >
                {item.name}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="block md:hidden">
        <NavBarResponsive />
      </div>
    </>
  );
}

export default NavBar;
