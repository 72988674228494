import React, { useState, useEffect } from "react";
import arrowLeft from "assets/icons/ArrowLeftSlider.svg";
import arrowLeftDark from "assets/icons/ArrowLeftSliderDark.svg";
import arrowRight from "assets/icons/ArrowRightSlider.svg";
import arrowRightDark from "assets/icons/ArrowRightSliderBlack.svg";
import { useContext } from "react";
import { DarkModeContext } from "theme/provider/DarkModeProvider";
const SpeciaEventCarousel = ({ children }) => {
  const [counter, setCounter] = useState(1);
  const [pause, setPause] = useState(false);
  const content = children;
  const { darkToggle } = useContext(DarkModeContext);

  const handleNext = () => {
    if (counter !== content?.length) {
      setCounter(counter + 1);
    } else {
      setCounter(1);
    }
  };

  const handlePre = () => {
    if (counter !== 1) {
      setCounter(counter - 1);
    } else {
      setCounter(content?.length);
    }
  };

  // const handlePage = (page) => {
  //   setCounter(page);
  // };

  const handleMouse = () => {
    setPause(!pause);
  };

  useEffect(() => {
    let interval = setInterval(() => {
      if (!pause) {
        handleNext();
      } else {
        clearInterval(interval);
      }
    }, 3000);
    return () => clearInterval(interval);
  });

  return (
    <div
      className=" slide-special-event"
      onMouseEnter={handleMouse}
      onMouseLeave={handleMouse}
    >
      {content && (
        <div className="w-full my-10 text-center">
          {content?.length > 1 && (
            <button className="prev-special-event" onClick={handlePre}>
              {darkToggle ? (
                <img src={arrowLeft} className="w-5" alt="" />
              ) : (
                <img src={arrowLeftDark} className="w-5" alt="" />
              )}
            </button>
          )}
        </div>
      )}
      <div>
        {content?.length > 0 &&
          content?.map((item, index) => (
            <div
              className={
                counter - 1 === index ? "show-special-event" : "not-show"
              }
              key={index}
            >
              {item}
            </div>
          ))}
      </div>
      {content && (
        <div className="w-full my-10 text-center">
          {content?.length > 1 && (
            <button className="next-special-event" onClick={handleNext}>
              {darkToggle ? (
                <img src={arrowRight} className="w-5 " alt="" />
              ) : (
                <img src={arrowRightDark} className="w-5 " alt="" />
              )}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default SpeciaEventCarousel;
