import { useFollowers } from "hooks/useFollowers";
import { useContext, useState } from "react";
import { useProfile } from "hooks/useProfile";
import { DarkModeContext } from "theme/provider/DarkModeProvider";
import { useMutation, useQueryClient } from "react-query";
import { _axios } from "interceptor/http-config";
import { useNavigate } from "react-router-dom";
import { _AuthApi } from "api/auth";
import { useTranslation } from "react-i18next";
export const useLogic = () => {
  const { t } = useTranslation("index");
  const { data, isLoading } = useFollowers();
  const id = _AuthApi.getID();
  const { data: profile } = useProfile(id);
  const { darkToggle } = useContext(DarkModeContext);
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const followMutation = useMutation(
    (id) => _axios.get(`/user/follow-toggle/${id}`),
    {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: () => {
        queryClient.invalidateQueries();
      },

      onSettled: () => {
        setLoading(false);
      },
    }
  );

  const handleToggleFollow = (id) => {
    followMutation.mutate(id);
  };
  return {
    t,
    data,
    isLoading,
    profile,
    darkToggle,
    loading,
    handleToggleFollow,
    navigate,
  };
};
