import { Box, Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { _AuthApi } from "api/auth";
import { DarkModeContext } from "theme/provider/DarkModeProvider";
let schema = yup.object().shape({
  code: yup
    .string()
    .required("Verification code is required")
    .length(6, "Verification code must be 6 characters long")
    .matches(/^[0-9]+$/, "Verification code must be a number"),
});
const VerificationCodeForm = () => {
  const { t } = useTranslation("index");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { darkToggle } = useContext(DarkModeContext);
  const { email } = useParams();
  const [code, setCode] = useState("");
  const formOptions = { resolver: yupResolver(schema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const onSubmit = async (e) => {
    setLoading(true);
    _AuthApi.verifyCode({ email, code }).then((res) =>
      navigate(`/reset-password/edit-password/${email}/${code}`, {
        replace: true,
      })
        ?.finally(() => setLoading(false))
        .catch((err) => console.log(err))
    );
  };

  return (
    <section>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "transparent",

          flexDirection: "column",
          backdropFilter: "bluer(20px)",
        }}
        className="flex flex-row"
      >
        <Box
          className="dark:bg-[#272727]/50 bg-third-color"
          sx={{
            minWidth: "350px",
            width: "30%",
            padding: "40px",
            zIndex: 10000,
            backgroundColor: "transparent",
            border: darkToggle
              ? "1px solid rgba(255,255,255,0.2)"
              : "1px solid rgba(0,0,0,0.2)",
            borderRadius: "10px",
          }}
          style={{
            backdropFilter: " blur(3px)",
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <p className="text-2xl text-black dark:text-white ">
              {t("Enter Your Code")}
            </p>

            <p className="my-5 text-xl text-center text-secondry-color">
              An email with a verification code has been sent to {email}
            </p>
          </Grid>

          <Box
            sx={{ width: "100%", mt: "30px" }}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box sx={{ margin: "0 0 8px 5px" }}>
              <p className="text-black dark:text-white">{t("Code")}</p>
            </Box>

            <input
              type="text"
              sx={{ width: "100%" }}
              placeholder="Enter your code"
              {...register("code", { validate: true })}
              id="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              className="w-full px-5 py-5 text-black bg-transparent border border-black rounded dark:text-white "
            />
            {errors.code && (
              <span className="field_level_error" style={{ color: "red" }}>
                {errors.code.message}
              </span>
            )}

            <div className="flex items-center justify-center w-full my-5">
              <button
                type="submit"
                className="relative h-12 text-white rounded w-1/1 bg-secondry-color"
                onClick={handleSubmit}
                // disabled={loading}
              >
                <div className="px-5">
                  {loading ? <p className="loader"></p> : <p>Verify Code</p>}
                </div>
              </button>
            </div>
          </Box>
        </Box>
      </Box>
    </section>
  );
};

export default VerificationCodeForm;
