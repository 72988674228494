import { Box } from "@mui/material";
import { _axios } from "interceptor/http-config";
import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import heartEmpty from "assets/icons/heartEmpty.svg";
import heartfill from "assets/icons/heartIconFill.png";
import dateIcon from "assets/icons/DateIcon.svg";
import locationIcon from "assets/icons/LocationIcon.svg";
import { settingsStore } from "store/settingsStore";
import { useNavigate } from "react-router-dom";
const MyCreatedEvent = () => {
  const navigate = useNavigate();
  const [direction] = settingsStore((state) => [state.direction]);

  useEffect(() => {
    window.scrollTo(400, 400);
  }, []);

  const { data: eventOrganizer, isLoading } = useQuery(
    ["organizer"],
    async () => {
      return await _axios
        .get(`/user/organizer/event`, {
          headers: {
            ...HttpRequestInterceptor(),
          },
        })
        .then((res) => res.data?.events);
    },
    {}
  );

  const formatDate = (dateTimeString) => {
    const formattedTime = new Date(dateTimeString).toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return formattedTime;
  };
  const formatDateMonth = (dateTimeString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Date(dateTimeString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };
  const handleEventShowView = (id) => {
    navigate(`/event-show/${id}`);
  };

  return (
    <section>
      <Box className="w-full text-black dark:text-white">
        <div className="flex justify-center w-full">
          {isLoading && <p className="loader"> </p>}
        </div>
        <div className="grid grid-cols-1 gap-5 px-5 lg:grid lg:grid-cols-3 place-items-center">
          {eventOrganizer?.map((item, index) => (
            <div
              key={index}
              onClick={() => handleEventShowView(item.id)}
              className="flex flex-col gap-5 px-5 py-10 border border-gray-400 dark:border-white w-1/1 rounded-xl "
            >
              <div className="relative w-full h-80">
                <img
                  src={item.image}
                  alt=""
                  className="object-cover w-full h-full lg:object-fill"
                />
                <div
                  className={`absolute ${
                    direction === "ltr" ? " right-2  top-2" : "left-2  top-2"
                  }`}
                >
                  <img
                    src={item.is_favorite ? heartfill : heartEmpty}
                    alt=""
                    className="w-8 h-8"
                  />
                </div>
              </div>
              <h1 className="text-3xl">{item.name}</h1>
              <div className="flex gap-5">
                <img src={dateIcon} alt="" />
                <p>
                  from {formatDate(item.start_date)} until{" "}
                  {formatDate(item.end_date)} ,
                  {formatDateMonth(item.start_date)}
                  to {formatDateMonth(item.end_date)}
                </p>
              </div>
              <div className="flex gap-5">
                <img src={locationIcon} alt="" />
                <p>{item.location}</p>
              </div>
            </div>
          ))}
        </div>
      </Box>
    </section>
  );
};

export default MyCreatedEvent;
