import { Box, Grid } from "@mui/material";

import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useTranslation } from "react-i18next";
import { _AuthApi } from "api/auth";
import { DarkModeContext } from "theme/provider/DarkModeProvider";
let schema = yup.object().shape({
  email: yup
    .string()
    .email("This must be a valid email")
    .required("Email is required"),
});
const ResetPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("index");
  const { darkToggle } = useContext(DarkModeContext);
  const [email, setEmail] = useState("");
  const [loading, setloading] = useState(false);
  const formOptions = { resolver: yupResolver(schema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const onSubmit = async (e) => {
    setloading(true);
    _AuthApi
      .resetPass({ email })
      .then((res) =>
        navigate(`/reset-password/check-code/${email}`, { replace: true })
      )
      ?.finally(() => setloading(false))
      .catch((err) => console.log(err));
  };

  return (
    <section>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "transparent",

          flexDirection: "column",
          backdropFilter: "bluer(20px)",
        }}
      >
        <Box
          className="dark:bg-[#272727]/50 bg-third-color"
          sx={{
            minWidth: "350px",
            width: "30%",
            padding: "40px",
            zIndex: 10000,
            backgroundColor: "transparent",
            border: darkToggle
              ? "1px solid rgba(255,255,255,0.2)"
              : "1px solid rgba(0,0,0,0.2)",
            borderRadius: "10px",
          }}
          style={{
            backdropFilter: " blur(3px)",
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <p className="text-2xl dark:text-white text-black ">
              {t("Reset Your Password")}
            </p>
            <p className="text-secondry-color my-5 text-xl text-center">
              {t("Enter your credentials to continue")}
            </p>
          </Grid>
          <Box
            sx={{ width: "100%", mt: "30px" }}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box sx={{ margin: "0 0 8px 5px" }}>
              <p className="dark:text-white text-black">{t("Email")}</p>
            </Box>

            <input
              type="email"
              placeholder="Enter your email"
              {...register("email", { validate: true })}
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full py-5  text-black bg-transparent border border-black rounded px-5 dark:text-white "
            />
            {errors.email && (
              <span className="field_level_error" style={{ color: "red" }}>
                {errors.email.message}
              </span>
            )}
            <div className="w-full flex items-center justify-center my-5">
              <button
                type="submit"
                className="relative w-1/1 h-12 text-white rounded bg-secondry-color"
                onClick={handleSubmit}
                // disabled={loading}
              >
                <div className="px-5">
                  {loading ? (
                    <p className="loader"></p>
                  ) : (
                    <p>Send Verification Code</p>
                  )}
                </div>
              </button>
            </div>
          </Box>
        </Box>
      </Box>
    </section>
  );
};

export default ResetPassword;
