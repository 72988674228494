import React from "react";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { useNavBarResponsiveLogic } from "layout/hooks/useNavBarResponsiveLogic";
const ResposniveSlider = () => {
  const {
    data,
    loading,
    selectedItem,
    handleItemClick,
    handleScroll,
    canScrollLeft,
    canScrollRight,
    startIndex,
    itemsPerPage,
    direction,
  } = useNavBarResponsiveLogic();
  return (
    <div className="flex justify-center w-full ">
      {loading ? (
        <p className="loader"> </p>
      ) : (
        <>
          <div
            className={`  left-0 dark:text-white  h-8  items-center flex ${
              direction === "rtl" ? "rotate-180" : ""
            }`}
          >
            {canScrollLeft && (
              <KeyboardDoubleArrowLeftIcon
                onClick={() => handleScroll("left")}
              />
            )}
          </div>

          <div className="flex items-center justify-center w-full h-8 mx-5 text-black dark:text-white">
            <div className="flex items-center justify-center w-full gap-2 overflow-x-auto ">
              {data?.items
                ?.slice(startIndex, startIndex + itemsPerPage)
                .map((item, index) => (
                  <div
                    className={`
                ${
                  selectedItem === item.name ||
                  (item.default === 1 && selectedItem === null) ||
                  (index === 0 && selectedItem === null && item.default !== 0)
                    ? "text-secondry-color hover:font-bold"
                    : ""
                }`}
                    key={index}
                  >
                    <h1
                      onClick={() => handleItemClick(item.name)}
                      className={`text-xs `}
                    >
                      {item.name}
                    </h1>
                  </div>
                ))}
            </div>
          </div>
          <div
            className={` ${
              direction === "rtl" ? "rotate-180" : ""
            } right-0 flex items-center h-8 dark:text-white`}
          >
            {canScrollRight && (
              <KeyboardDoubleArrowRightIcon
                onClick={() => handleScroll("right")}
                className="cursor-pointer"
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ResposniveSlider;
