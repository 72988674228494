import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import showPassIcon from "assets/images/showPass.png";
import hiddenPassIcon from "assets/images/hiddenPass.png";
import { _AuthApi } from "api/auth";
import { NavLink, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
let schema = yup.object().shape({
  email: yup.string().required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "The Password must be of six characters")
    .max(20, "The Password must be of 20 characters"),
});
const Singin = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const navigate = useNavigate();

  const hanldeShowPass = () => {
    setShowPass(!showPass);
  };
  const { t } = useTranslation("navbar");

  const { mutate } = useMutation((data) => createPost(data));
  const formOptions = { resolver: yupResolver(schema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  async function createPost(data, e) {
    setLoading(true);
    await axios;
    _AuthApi
      .login(data, setLoading, navigate, enqueueSnackbar)
      .then((res) => {
        enqueueSnackbar(t("Done"), {
          variant: "success",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });
        navigate("/");
      })
      .catch((error) => {
        enqueueSnackbar(error?.response?.data?.message, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });
      })
      .finally((res) => {
        setLoading(false);
      });
  }
  const { errors } = formState;
  const onSubmit = async (input) => {
    const formData = new FormData();

    formData.append("email", input.email);
    formData.append("password", input.password);
    mutate(formData);
  };
  useEffect(() => {
    window.scrollTo(300, 300);
  }, []);

  return (
    <div className="flex flex-col items-center justify-center px-10 py-10 pb-20 text-white md:pb-10 md:px-0">
      <div className=" flex flex-col w-full max-w-[1200px] gap-10 py-20 rounded dark:bg-[#272727]/50 bg-third-color">
        <h1 className="text-center text-black dark:text-white">Login</h1>
        <form
          className="flex flex-col w-full max-w-[1200px] gap-10 "
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="relative mx-10 rounded">
            <h1 className="absolute text-gray-500 transform -translate-y-1/2 pointer-events-none left-5 top-1/2">
              Email
            </h1>
            <input
              type="email"
              id="email"
              name="email"
              {...register("email")}
              className="w-full py-5 pl-16 pr-8 text-black bg-transparent border border-black rounded md:pl-32 dark:text-white"
            />
            {errors.email && (
              <span className="text-red-500">{errors.email.message}</span>
            )}
          </div>
          <div className="relative mx-10 rounded">
            <h1 className="absolute text-gray-500 transform -translate-y-1/2 pointer-events-none left-4 top-1/2">
              Password
            </h1>
            <input
              type={showPass ? "text" : "password"}
              id="password"
              name="password"
              {...register("password")}
              className="w-full py-5 pl-24 pr-8 text-black bg-transparent border border-black rounded md:pl-32 dark:text-white "
            />
            {errors.password && (
              <span className="text-red-500">{errors.password.message}</span>
            )}
            <img
              src={showPass ? showPassIcon : hiddenPassIcon}
              className="absolute w-5 text-black transform -translate-y-1/2 cursor-pointer right-2 top-1/2"
              alt=""
              onClick={hanldeShowPass}
            />
          </div>
          <div className="flex justify-between mx-10 ">
            <NavLink to="/reset-password">
              <h1 className="text-black underline decoration-1 dark:text-white">
                Reset Password?
              </h1>
            </NavLink>
            <h1
              className="cursor-pointer hover:font-semibold text-secondry-color"
              onClick={() => navigate("/register")}
            >
              Sign up
            </h1>
          </div>
          <div className="flex items-center justify-center w-full">
            {!loading ? (
              <button
                type="submit"
                className="relative w-1/2 h-12 text-white rounded bg-secondry-color"
                onClick={handleSubmit}
                disabled={loading}
              >
                <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                  Sign in
                </div>
              </button>
            ) : (
              <button
                className="relative w-1/2 h-12 text-white rounded bg-secondry-color"
                onClick={handleSubmit}
                disabled={loading}
              >
                <div className="flex items-center justify-center w-full text-center ">
                  <div className="loader"> </div>
                </div>
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Singin;
