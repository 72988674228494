import React from "react";
import { useLogic } from "../hooks/useLogic";
import likeIconWhite from "assets/icons/eventShow/likeIconeWhite.svg";
import likeIconBlack from "assets/icons/eventShow/likeIconBlack.svg";
import dislikeIconWhite from "assets/icons/eventShow/dislikeIconWhite.svg";
import dislikeIconBlack from "assets/icons/eventShow/dislikeIconBlack.svg";
import VerifiedIcon from "@mui/icons-material/Verified";

const CommentsShow = () => {
  const {
    data,
    darkToggle,
    handleToggleLike,
    showAllComments,
    setShowAllComments,
    limitedComments,
    role,
    navigate,
    access_token,
    t,
  } = useLogic();

  return (
    <div className="w-full border rounded-md border-secondry-color">
      <div className="flex items-center justify-between p-2 ">
        <div>
          <p className="text-xl">{t("Comments")}</p>
        </div>
        <div className="text-center ">
          {!showAllComments && data?.comments?.length > 3 && (
            <button
              onClick={() => setShowAllComments(true)}
              className="p-2 text-sm transition duration-200 border rounded-md cursor-pointer border-secondry-color text-secondry-color hover:bg-secondry-color hover:text-white hover:scale-105"
            >
              {t("Show All")}
            </button>
          )}
          {showAllComments && (
            <button
              onClick={() => setShowAllComments(false)}
              className="p-2 text-sm transition duration-200 border rounded-md cursor-pointer border-secondry-color text-secondry-color hover:bg-secondry-color hover:text-white hover:scale-105"
            >
              {t("Show Less")}
            </button>
          )}
        </div>
      </div>
      <div className="h-[0.1] w-full border dark:border-white border-gray-400 "></div>

      <div className="flex justify-between p-5 lg:gap-10 ">
        <div className="flex flex-col w-full gap-10 ">
          {limitedComments?.length > 0 ? (
            limitedComments?.map((item, index) => (
              <div key={index} className="flex gap-5 lg:gap-5">
                <div className="flex">
                  <img
                    src={item.user_image}
                    alt=""
                    onClick={() => {
                      item.role === "verified" &&
                        navigate(`/verified-profile/${item.user_id}`);
                    }}
                    className="w-12 h-12 rounded-full cursor-pointer lg:h-12 lg:w-12 ring-1 ring-white"
                  />
                </div>
                <div className="flex flex-col gap-5">
                  <div className="flex gap-3">
                    <p>{item.user_first_name}</p>
                    <p>{item.user_last_name}</p>
                    <p>
                      {item.role === "verified" && (
                        <VerifiedIcon
                          sx={{ color: "#f5881f", fontSize: "20px" }}
                        />
                      )}
                    </p>
                  </div>
                  <div>{item.body ?? "null"}</div>
                  <div className="flex gap-3 lg:gap-10">
                    <p>{item.likes_count}</p>
                    {role === "organizer" ? (
                      item.likes_count <= 1 ? (
                        t("like")
                      ) : (
                        t("likes")
                      )
                    ) : darkToggle ? (
                      access_token ? (
                        <img
                          src={likeIconWhite}
                          alt=""
                          className="cursor-pointer hover:scale-105"
                          onClick={() => handleToggleLike(item.id, "like")}
                        />
                      ) : (
                        <img
                          src={likeIconWhite}
                          alt=""
                          className="cursor-pointer hover:scale-105"
                          onClick={() => navigate("/singin")}
                        />
                      )
                    ) : access_token ? (
                      <img
                        src={likeIconBlack}
                        alt=""
                        className="cursor-pointer hover:scale-105"
                        onClick={() => handleToggleLike(item.id, "like")}
                      />
                    ) : (
                      <img
                        src={likeIconBlack}
                        alt=""
                        className="cursor-pointer hover:scale-105"
                        onClick={() => navigate("/singin")}
                      />
                    )}
                    <p>{item.dislikes_count}</p>
                    {role === "organizer" ? (
                      item.dislikes_count <= 1 ? (
                        t("dislike")
                      ) : (
                        t("dislikes")
                      )
                    ) : darkToggle ? (
                      access_token ? (
                        <img
                          src={dislikeIconWhite}
                          alt=""
                          className="cursor-pointer hover:scale-105"
                          onClick={() => handleToggleLike(item.id, "dislike")}
                        />
                      ) : (
                        <img
                          src={dislikeIconWhite}
                          alt=""
                          className="cursor-pointer hover:scale-105"
                          onClick={() => navigate("/singin")}
                        />
                      )
                    ) : access_token ? (
                      <img
                        src={dislikeIconBlack}
                        alt=""
                        className="cursor-pointer hover:scale-105"
                        onClick={() => handleToggleLike(item.id, "dislike")}
                      />
                    ) : (
                      <img
                        src={dislikeIconBlack}
                        alt=""
                        className="cursor-pointer hover:scale-105"
                        onClick={() => navigate("/singin")}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>{t("No Comments available")}.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommentsShow;
