import { _axios } from "interceptor/http-config";
import { enqueueSnackbar } from "notistack";
import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";

export const _AboutApi = {
  member: async () => {
    return _axios
      .get(`/user/members`, {
        headers: {
          ...HttpRequestInterceptor(),
        },
      })
      .then((res) => res.data)
      .catch((err) =>
        enqueueSnackbar("members Error", {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
        })
      );
  },
  links: async () => {
    return _axios
      .get(`/user/social-media-links`, {
        headers: {
          ...HttpRequestInterceptor(),
        },
      })
      .then((res) => res.data)

      .catch((err) =>
        enqueueSnackbar("Social Media Error", {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
        })
      );
  },
  partners: async () => {
    return _axios
      .get(`/user/partners`, {
        headers: {
          ...HttpRequestInterceptor(),
        },
      })
      .then((res) => res.data)

      .catch((err) =>
        enqueueSnackbar("partners Error", {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
        })
      );
  },
};
