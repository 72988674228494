import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import showPassIcon from "assets/images/showPass.png";
import hiddenPassIcon from "assets/images/hiddenPass.png";
import { _AuthApi } from "api/auth";
import { useNavigate } from "react-router-dom";
let schema = yup.object().shape({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "The Password must be of six characters")
    .max(20, "The Password must be of 20 characters"),

  password_confirmation: yup
    .string()
    .required("Confirm password is required")
    .min(6, "The confirm password must be of six characters")
    .max(20, "The confirm password must be of 20 characters")
    .oneOf([yup.ref("password")], "your password does not match"),
  news_letter: yup.boolean(),
});
const Register = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const formOptions = { resolver: yupResolver(schema) };
  const { register, handleSubmit, formState } = useForm(formOptions);

  const { errors } = formState;
  const [email, setEmail] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");
  const [news_letter, setNews_letter] = useState(1 || 0);

  const onSubmit = async () => {
    setLoading(true);
    _AuthApi
      .register({
        email,
        last_name,
        first_name,
        news_letter,
        password,
        password_confirmation,
      })
      .then((res) => navigate(`/register/otp/${email}`))
      ?.finally(() => setLoading(false))
      .catch((err) => console.log(err));
  };

  const hanldeShowPass = () => {
    setShowPass(!showPass);
  };
  const hanldeShowConfirmPass = () => {
    setShowConfirmPass(!showConfirmPass);
  };

 
  useEffect(() => {
    window.scrollTo(300, 300);
  }, []);
 
  return (
    <div className="flex flex-col items-center justify-center px-10 py-10 pb-20 text-white md:pb-10 md:px-0">
      <div className=" flex flex-col w-full max-w-[1200px] gap-10 py-20 rounded dark:bg-[#272727]/50 bg-third-color">
        <p className="text-center text-black dark:text-white">Register</p>
        <form
          className="flex flex-col w-full max-w-[1200px] gap-10 "
          // onSubmit={handleSubmit(onSubmit)}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="relative mx-2 rounded md:mx-10">
            <p className="absolute text-gray-500 transform -translate-y-1/2 pointer-events-none left-5 top-1/2">
              First Name
            </p>
            <input
              type="text"
              placeholder="Enter your First Name"
              {...register("first_name", { validate: true })}
              id="first_name"
              value={first_name}
              onChange={(e) => setFirst_name(e.target.value)}
              className="w-full py-5 pl-32 pr-8 text-black bg-transparent border border-black rounded dark:text-white"
            />
            {errors.first_name && (
              <span className="text-red-500">{errors.first_name.message}</span>
            )}
          </div>
          <div className="relative mx-2 rounded md:mx-10">
            <p className="absolute text-gray-500 transform -translate-y-1/2 pointer-events-none left-5 top-1/2">
              Last Name
            </p>
            <input
              type="text"
              placeholder="Enter your Last Name"
              {...register("last_name", { validate: true })}
              id="last_name"
              value={last_name}
              onChange={(e) => setLast_name(e.target.value)}
              className="w-full py-5 pl-32 pr-8 text-black bg-transparent border border-black rounded dark:text-white"
            />
            {errors.last_name && (
              <span className="text-red-500">{errors.last_name.message}</span>
            )}
          </div>
          <div className="relative mx-2 rounded md:mx-10">
            <p className="absolute text-gray-500 transform -translate-y-1/2 pointer-events-none left-5 top-1/2">
              Email
            </p>
            <input
              type="email"
              placeholder="Enter your email"
              {...register("email", { validate: true })}
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full py-5 pl-20 pr-8 text-black bg-transparent border border-black rounded md:pl-32 dark:text-white"
            />
            {errors.email && (
              <span className="text-red-500">{errors.email.message}</span>
            )}
          </div>
          <div className="relative mx-2 rounded md:mx-10">
            <p className="absolute text-gray-500 transform -translate-y-1/2 pointer-events-none left-4 top-1/2">
              Password
            </p>
            <input
              type={showPass ? "text" : "password"}
              placeholder="Enter your Password"
              {...register("password", { validate: true })}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full py-5 pl-32 pr-8 text-black bg-transparent border border-black rounded dark:text-white "
            />
            {errors.password && (
              <span className="text-red-500">{errors.password.message}</span>
            )}
            <img
              src={showPass ? showPassIcon : hiddenPassIcon}
              className="absolute w-5 text-black transform -translate-y-1/2 cursor-pointer right-2 top-1/2"
              alt=""
              onClick={hanldeShowPass}
            />
          </div>
          <div className="relative mx-2 rounded md:mx-10">
            <p className="absolute text-gray-500 transform -translate-y-1/2 pointer-events-none left-4 top-1/2">
              Confirm Password
            </p>
            <input
              type={showConfirmPass ? "text" : "password"}
              placeholder="Enter your Password confirmation"
              {...register("password_confirmation", { validate: true })}
              id="password_confirmation"
              value={password_confirmation}
              onChange={(e) => setPassword_confirmation(e.target.value)}
              className="w-full py-5 pl-40 pr-8 text-black bg-transparent border border-black rounded dark:text-white "
            />
            {errors.password_confirmation && (
              <span className="text-red-500">
                {errors.password_confirmation.message}
              </span>
            )}
            <img
              src={showPass ? showPassIcon : hiddenPassIcon}
              className="absolute w-5 text-black transform -translate-y-1/2 cursor-pointer right-2 top-1/2"
              alt=""
              onClick={hanldeShowConfirmPass}
            />
          </div>
          <div className="flex flex-col justify-between gap-5 mx-10">
            <p className="flex items-center gap-5 text-black underline decoration-1 dark:text-white">
              <input
                type="checkbox"
                {...register("news_letter", { validate: true })}
                id="news_letter"
                value={news_letter}
                onChange={(e) => setNews_letter(e.target.value)}
              />
              Subscribe to our Newsletter
            </p>

            <p className="text-black dark:text-white">
              Have an account? login{" "}
              <span
                onClick={() => navigate("/singin")}
                className="duration-100 ease-linear cursor-pointer hover:font-bold transform-transition text-secondry-color "
              >
                Login
              </span>
            </p>
          </div>
          <div className="flex items-center justify-center w-full">
            {!loading ? (
              <button
                type="submit"
                className="relative w-1/2 h-12 text-white rounded bg-secondry-color"
                onClick={handleSubmit}
                disabled={loading}
              >
                <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                  Register
                </div>
              </button>
            ) : (
              <button
                className="relative w-1/2 h-12 text-white rounded bg-secondry-color"
                onClick={handleSubmit}
                disabled={loading}
              >
                <div className="flex items-center justify-center w-full text-center ">
                  <div className="loader"> </div>
                </div>
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
