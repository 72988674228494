import { Box, Button, TextField, Typography } from "@mui/material";

import React from "react";
import CategoryID from "components/shared/CategoryId";
import CityID from "components/shared/CityId";

import imageDrop from "assets/icons/addevent/imageDropIcon.svg";
import imageDropDark from "assets/icons/addevent/imageDropIconDark.svg";
import SubCategoryId from "components/shared/SubCategory";
import heartEmpty from "assets/icons/heartEmpty.svg";
import heartfill from "assets/icons/heartIconFill.png";
import dateIcon from "assets/icons/DateIcon.svg";
import locationIcon from "assets/icons/LocationIcon.svg";
import { useAddEvent } from "./hooks/useAddEvent";

const AddEvents = () => {
  const {
    t,
    register,
    handleSubmit,
    setValue,
    errors,
    darkToggle,
    details,
    selectedImagesMultiple,
    handleImageChange,
    handleDragOver,
    handleDrop,
    selectedImages,
    handleImage,
    handleDrag,
    handleDropImage,
    lang,
    tickets,
    tags,
    handleTicketChange,
    handleAddTicket,
    handleRemoveLastTicket,
    handleTagsChange,
    handleAddTags,
    handleRemoveLastTags,
    hanldeCreate,
    eventOrganizer,
    isLoading,
    direction,
    formatDate,
    formatDateMonth,
    handleEventShowView,
    watch,
    clearErrors,
    createEventLoading,
  } = useAddEvent();

  return (
    <section className="flex flex-col gap-5 px-5 text-black lg:gap-2 lg:px-20 lg:flex-row lg:flex dark:text-white">
      <Box className="p-5 border border-gray-400 rounded-lg lg:w-8/12 dark:border-white">
        <Box component="form">
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              flexDirection: { xs: "column", sm: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 5,
                mb: { xs: "50px", lg: 0 },
              }}
            >
              <Box>
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleImageChange}
                  className="hidden"
                  id="image-upload"
                />

                <Box
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  className="flex flex-col items-center p-4 mt-4 border border-gray-400 rounded-lg dark:border-white lg:w-60"
                  style={{ minHeight: "100px" }}
                >
                  <label
                    htmlFor="image-upload"
                    className="w-full text-center cursor-pointer "
                  >
                    {selectedImagesMultiple ? (
                      <div className="flex flex-col items-center justify-center gap-2">
                        {darkToggle ? (
                          <img src={imageDrop} alt="" className="w-10 h-10" />
                        ) : (
                          <img
                            src={imageDropDark}
                            alt=""
                            className="w-10 h-10"
                          />
                        )}
                        {t("Drag and drop or click to add image")}
                      </div>
                    ) : (
                      ""
                    )}
                  </label>
                  {selectedImagesMultiple?.map((image, index) => (
                    <img
                      key={index}
                      src={URL.createObjectURL(image)}
                      alt={`Uploaded img ${index}`}
                      className="object-contain w-32 h-32 m-2"
                    />
                  ))}
                </Box>
              </Box>
              <Box>
                <Typography>{t("Card Image")}</Typography>

                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImage}
                  className="hidden"
                  id="upload-image"
                />

                <Box
                  onDragOver={handleDrag}
                  onDrop={handleDropImage}
                  className="flex flex-col items-center p-4 mt-4 border border-gray-400 rounded-lg dark:border-white lg:w-60"
                  style={{ minHeight: "100px" }}
                >
                  <label
                    htmlFor="upload-image"
                    className="w-full text-center cursor-pointer "
                  >
                    {selectedImages ? (
                      <div className="flex flex-col items-center justify-center gap-2">
                        {/* Display your image preview here */}

                        <img
                          src={URL.createObjectURL(selectedImages)}
                          alt="Uploaded img"
                          className="object-contain w-32 h-32 m-2"
                        />
                      </div>
                    ) : (
                      <div className="flex flex-col items-center justify-center gap-2">
                        {darkToggle ? (
                          <img src={imageDrop} alt="" className="w-10 h-10" />
                        ) : (
                          <img
                            src={imageDropDark}
                            alt=""
                            className="w-10 h-10"
                          />
                        )}
                        {t("Drag and drop or click to add image")}
                      </div>
                    )}
                  </label>
                </Box>
              </Box>
            </Box>

            <Box sx={{ backgroundClip: "black", width: "100%" }}>
              <Box>
                {details.map((item, index) => (
                  <Box key={index} sx={{ p: "10px" }}>
                    <Box sx={{ margin: "0 0 8px 5px" }}>
                      <Typography variant="inputTitle">{item.head}</Typography>
                    </Box>
                    <TextField
                      sx={{
                        "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            color: darkToggle ? "white" : "black",
                            border:
                              lang === "ar"
                                ? "1px solid white"
                                : "1px solid gray",
                            borderRadius: "10px",
                          },
                        borderColor: "gray",
                        border: darkToggle
                          ? "1px solid gray"
                          : "1px solid gray",
                        borderRadius: "10px",

                        width: "100%",
                        my: "5px",
                      }}
                      type={item.type}
                      placeholder={item.placeholder}
                      defaultValue={item.defaultValue}
                      name={item.name}
                      {...register(item.register)}
                      error={errors[item.error]?.message}
                      helperText={errors[item.helperText]?.message || ""}
                    />
                  </Box>
                ))}
                <Box>
                  <Box sx={{ p: "10px" }}>
                    <Box sx={{ margin: "0 0 8px 5px" }}>
                      <Typography variant="inputTitle">
                        {t("Category")}
                      </Typography>
                    </Box>

                    <CategoryID
                      setCategoryID={setValue}
                      label={t("Category")}
                      {...register("category_id")}
                      error={errors.category_id?.message}
                      helperText={errors.category_id?.message || ""}
                      onChange={() => clearErrors("category_id")}
                    />
                  </Box>
                  <Box sx={{ p: "10px" }}>
                    <Box sx={{ margin: "0 0 8px 5px" }}>
                      <Typography variant="inputTitle">{t("City")}</Typography>
                    </Box>

                    <CityID
                      setCityID={setValue}
                      label={t("City")}
                      {...register("city_id")}
                      error={errors.city_id?.message}
                      helperText={errors.city_id?.message || ""}
                      onChange={() => clearErrors("city_id")}
                    />
                  </Box>
                  <Box sx={{ p: "10px" }}>
                    <Box sx={{ margin: "0 0 8px 5px" }}>
                      <Typography variant="inputTitle">
                        {t("Sub Category")}
                      </Typography>
                    </Box>

                    <SubCategoryId
                      category_id={watch("category_id")}
                      setSubCategory={setValue}
                      label={t("Sub Category")}
                      {...register("sub_category_ids")}
                      error={errors.sub_category_ids?.message}
                      helperText={errors.sub_category_ids?.message || ""}
                      onChange={() => clearErrors("sub_category_ids")}
                    />
                  </Box>
                </Box>
              </Box>

              <Box sx={{ p: "10px" }}>
                {tickets.map((ticket, index) => (
                  <Box key={index}>
                    <Box sx={{ my: "10px" }}>
                      <Typography variant="inputTitle">
                        {t("Ticket")}
                        {` ${index + 1}`}
                      </Typography>
                    </Box>
                    <TextField
                      sx={{
                        "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            color: darkToggle ? "white" : "black",
                            border:
                              lang === "ar"
                                ? "1px solid white"
                                : "1px solid gray",
                            borderRadius: "10px",
                          },
                        // border:
                        //   lang === "ar" ? "1px solid white" : "1px solid gray",
                        borderColor: "gray",
                        border: darkToggle
                          ? "1px solid gray"
                          : "1px solid gray",
                        borderRadius: "10px",

                        width: "100%",
                        my: "5px",
                      }}
                      type="text"
                      placeholder={` ${index + 1} ${t("ticket name")} : ${t(
                        "price"
                      )} `}
                      name={`tickets[${index}][name]`}
                      defaultValue={ticket.name}
                      onChange={(event) =>
                        handleTicketChange(event, index, "name")
                      }
                      {...register(`tickets[${index}].name`)}
                      error={errors?.tickets?.[index]?.name}
                      helperText={errors?.tickets?.[index]?.name?.message}
                    />
                  </Box>
                ))}
                <Box
                  sx={{
                    display: "flex",
                    gap: 5,
                  }}
                >
                  {tickets.length > 1 && (
                    <Button
                      sx={{
                        color: darkToggle ? "#fff" : "black",

                        backgroundColor: "origin.main",
                        "&:hover": { backgroundColor: "origin.main" },
                        margin: "20px 0 20px 0",
                      }}
                      variant="contained"
                      color="error"
                      type="button"
                      onClick={handleRemoveLastTicket}
                    >
                      {t("Remove")}
                    </Button>
                  )}
                  <Button
                    sx={{
                      color: darkToggle ? "#fff" : "black",
                      backgroundColor: lang === "ar" ? "#f5881f" : "#f5881f",
                      margin: "20px 0 20px 0px",
                      "&:hover": {
                        backgroundColor: "#f1853f",
                        borderColor: "#f5881f",
                      },
                      borderColor: "#f5881f",
                    }}
                    variant="outlined"
                    color="primary"
                    onClick={handleAddTicket}
                  >
                    {t("Add Ticket")}
                  </Button>
                </Box>
              </Box>
              <Box sx={{ p: "10px" }}>
                {tags.map((tag, index) => (
                  <Box key={index}>
                    <Box sx={{ my: "10px" }}>
                      <Typography variant="inputTitle">{`${t("Tag")} ${
                        index + 1
                      }`}</Typography>
                    </Box>
                    <TextField
                      sx={{
                        "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            color: darkToggle ? "white" : "black",
                            border:
                              lang === "ar"
                                ? "1px solid white"
                                : "1px solid gray",
                            borderRadius: "10px",
                          },
                        // border:
                        //   lang === "ar" ? "1px solid white" : "1px solid gray",
                        borderColor: "gray",
                        border: darkToggle
                          ? "1px solid gray"
                          : "1px solid gray",
                        borderRadius: "10px",

                        width: "100%",
                        my: "5px",
                      }}
                      type="text"
                      placeholder={` ${index + 1} ${t("tag name")}`}
                      name={`tags[${index}][name]`}
                      defaultValue={tag.name}
                      onChange={(event) =>
                        handleTagsChange(event, index, "name")
                      }
                      {...register(`tags[${index}].name`)}
                      error={errors?.tags?.[index]?.name}
                      helperText={errors?.tags?.[index]?.name?.message}
                    />
                  </Box>
                ))}
                <Box
                  sx={{
                    display: "flex",
                    gap: 5,
                  }}
                >
                  {tags.length > 1 && (
                    <Button
                      sx={{
                        color: darkToggle ? "#fff" : "black",

                        backgroundColor: "origin.main",
                        "&:hover": { backgroundColor: "origin.main" },
                        margin: "20px 0 20px 0",
                      }}
                      variant="contained"
                      color="error"
                      type="button"
                      onClick={handleRemoveLastTags}
                    >
                      {t("Remove")}
                    </Button>
                  )}
                  <Button
                    sx={{
                      color: darkToggle ? "#fff" : "black",
                      backgroundColor: lang === "ar" ? "#f5881f" : "#f5881f",
                      margin: "20px 0 20px 0px",
                      "&:hover": {
                        backgroundColor: "#f1853f",
                        borderColor: "#f5881f",
                      },
                      borderColor: "#f5881f",
                    }}
                    variant="outlined"
                    color="primary"
                    onClick={handleAddTags}
                  >
                    {t("Add Tags")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              disabled={createEventLoading}
              onClick={() => handleSubmit(hanldeCreate)()}
              variant="outlined"
              sx={{
                borderColor: "#f5881f",
                color: darkToggle ? "white" : "black",
                textTransform: "capitalize",
                backgroundColor: "#f2681f",

                "&:hover": {
                  backgroundColor: "#f2681f",
                  borderColor: "white",
                },
              }}
            >
              {t("Create Event")}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box className="lg:w-1/3 ">
        <div className="flex justify-center w-full">
          {isLoading && <p className="loader"> </p>}
        </div>
        <div className="grid grid-cols-1 gap-5 place-items-center ">
          {eventOrganizer?.map((item, index) => (
            <div
              key={index}
              onClick={() => handleEventShowView(item.id)}
              className="flex flex-col w-full gap-5 px-5 py-10 border border-gray-400 dark:border-white rounded-xl"
            >
              <div className="relative w-full h-80">
                <img
                  src={item.image}
                  alt=""
                  className="object-cover w-full h-full lg:object-fill"
                />
                <div
                  className={`absolute ${
                    direction === "ltr" ? " right-2  top-2" : "left-2  top-2"
                  }`}
                >
                  <img
                    src={item.is_favorite ? heartfill : heartEmpty}
                    alt=""
                    className="w-8 h-8"
                  />
                </div>
              </div>
              <h1 className="text-3xl">{item.name}</h1>
              <div className="flex gap-5">
                <img src={dateIcon} alt="" />
                <h1>
                  {t("from")} {formatDate(item.start_date)} {t("until")}{" "}
                  {formatDate(item.end_date)} ,
                  {formatDateMonth(item.start_date)}
                  {t("to")} {formatDateMonth(item.end_date)}
                </h1>
              </div>
              <div className="flex gap-5">
                <img src={locationIcon} alt="" />
                <h1>{item.location}</h1>
              </div>
            </div>
          ))}
        </div>
      </Box>
    </section>
  );
};

export default AddEvents;
