import React from "react";
import settingIcon from "assets/icons/eventShow/settingIcon.svg";
import { useLogic } from "./hooks/useLogic";
import Modal from "react-modal";
import RecomandedShow from "./components/RecomandedShow";
import CommentsShow from "./components/CommentsShow";
import ReviewsShow from "./components/ReviewsShow";
import SimilarEvents from "./components/SimilarEvents";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
Modal.setAppElement("#root");

const EventShow = () => {
  const {
    dataGallery,
    handleClick,
    isLoading,
    handleChange,
    handleComment,
    Section,
    sectionsContent,
    data,
    handleToggleAddToPlane,
    role,
    navigate,
    loading,
    access_token,
    t,
    commentLoading,
    handleToggleAddReminder,
    remidenrLoading,
  } = useLogic();

  return (
    <div className="text-black bg-fourth-color dark:bg-black dark:text-white">
      <div className="flex items-center justify-center w-full ">
        {isLoading && <p className="loader"> </p>}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: 2.4,
          alignItems: "center",
        }}
      >
        <img
          src={
            !dataGallery
              ? data?.gallery[0]?.url
              : data?.gallery[dataGallery].url
          }
          className="w-full lg:h-[700px] object-fill relative "
          alt="events"
        />
        {data && (
          <div className="absolute w-full mt-2 text-black">
            <p className="p-2 text-base bg-fourth-color lg:p-5 lg:text-2xl lg:w-1/4 rounded-2xl">
              {data?.description}
            </p>
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            paddingTop: "20px",
            overflowX: "auto",
            width: "100%",
          }}
          //   className="grid justify-around w-full grid-cols-2 pt-10 overflow-auto md:flex-row md:flex"
        >
          {data?.gallery?.map((item, index) => (
            <div
              style={{
                padding: "20px",
                minWidth: "200px",
              }}
              key={index}
            >
              <img
                src={item.url}
                onClick={() => handleClick(index)}
                height="70"
                width="400px"
                alt="Events"
                className="transition duration-300 ease-linear rounded-md cursor-pointer hover:scale-105"
              />
            </div>
          ))}
        </div>
      </div>
      <section className="flex flex-col gap-5 2xl:flex-row 2xl:flex ">
        {data ? (
          <>
            <div className="flex flex-col justify-between flex-1 gap-5 px-5 py-5 rounded-lg lg:p-5 lg:px-20 bg-third-color dark:bg-primary-color">
              <div className="flex flex-col-reverse justify-between flex-1 gap-5 lg:flex-row lg:flex ">
                <div className="flex flex-col gap-10">
                  {sectionsContent.map((section, index) => (
                    <Section
                      key={index}
                      icon={section.icon}
                      content={section.content}
                    />
                  ))}
                </div>
                <div className="flex gap-2 md:h-14">
                  {loading ? (
                    <p className="loader"></p>
                  ) : access_token ? (
                    <button
                      onClick={(e) => handleToggleAddToPlane(e, data?.id)}
                      className="flex items-center w-1/2 gap-2 px-2 py-2 text-sm text-center transition duration-300 ease-linear rounded-lg sm:w-1/4 lg:px-5 lg:text-sm lg:py-3 lg:w-52 bg-secondry-color hover:scale-110"
                    >
                      <img
                        src={settingIcon}
                        alt=""
                        className="w-4 h-4 2xl:w-8 2xl:h-8"
                      />
                      {data?.in_plan === 0
                        ? t("Add to Plan")
                        : t("Remove from Plan")}
                    </button>
                  ) : (
                    <button
                      onClick={() => navigate("/singin")}
                      className="flex items-center w-1/2 gap-2 px-2 py-2 text-sm text-center transition duration-300 ease-linear rounded-lg sm:w-1/4 lg:px-5 lg:text-sm lg:py-3 lg:w-52 bg-secondry-color hover:scale-110"
                    >
                      <img
                        src={settingIcon}
                        alt=""
                        className="w-4 h-4 2xl:w-8 2xl:h-8"
                      />
                      {data?.in_plan === 0
                        ? t("Add to Plan")
                        : t("Remove from Plan")}
                    </button>
                  )}
                  {/* Reminder */}

                  {data?.in_plan === 1 ? (
                    remidenrLoading ? (
                      <p className="loader"></p>
                    ) : access_token ? (
                      <button
                        onClick={(e) => handleToggleAddReminder(e, data?.id)}
                        className="flex items-center w-1/2 gap-2 px-2 py-2 text-sm text-center transition duration-300 ease-linear rounded-lg sm:w-1/4 lg:px-5 lg:text-sm lg:py-3 lg:w-52 bg-secondry-color hover:scale-110"
                      >
                        <CalendarTodayIcon className="w-4 h-4 2xl:w-8 2xl:h-8" />
                        {/* <img
                        src={CalendarTodayIcon}
                        alt=""
                        className="w-4 h-4 2xl:w-8 2xl:h-8"
                      /> */}
                        {data?.is_reminder === 0
                          ? t("Add reminder")
                          : t("Remove reminder")}
                      </button>
                    ) : (
                      <button
                        onClick={() => navigate("/singin")}
                        className="flex items-center w-1/2 gap-2 px-2 py-2 text-sm text-center transition duration-300 ease-linear rounded-lg sm:w-1/4 lg:px-5 lg:text-sm lg:py-3 lg:w-52 bg-secondry-color hover:scale-110"
                      >
                        <CalendarTodayIcon className="w-4 h-4 2xl:w-8 2xl:h-8" />
                        {/* <img
                        src={CalendarTodayIcon}
                        alt=""
                        className="w-4 h-4 2xl:w-8 2xl:h-8"
                      /> */}
                        {data?.is_reminder === 0
                          ? t("Add Reminder")
                          : t("Remove Reminder")}
                      </button>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {role === "organizer" ? (
                ""
              ) : (
                <>
                  {access_token ? (
                    <>
                      <div className="h-[0.1] w-full border dark:border-white border-gray-400 "></div>
                      <form
                        onSubmit={(e) => handleComment(e, data.id)}
                        className="flex flex-col mt-5"
                      >
                        <div className="flex gap-2 lg:gap-10">
                          <p className="flex justify-center h-12 text-2xl bg-gray-500 rounded-full w-14 ring-1 ring-white">
                            {data?.name[0]}
                          </p>
                          <textarea
                            name="body"
                            onChange={handleChange}
                            required
                            className="w-full py-5 bg-transparent border border-gray-400 dark:border-white rounded-2xl placeholder:px-10 placeholder:pt-2"
                            placeholder="Add Your Comment...."
                          ></textarea>
                        </div>
                        <div className="flex justify-end my-10 ml-24 lg:justify-start">
                          {commentLoading ? (
                            <div className="w-1/2 py-2 rounded sm:w-1/4 md:w-1/3 lg:text-xl lg:py-3 lg:w-1/1 2xl:w-1/4 bg-secondry-color">
                              <p className="loader"></p>
                            </div>
                          ) : (
                            <button
                              type="submit"
                              className="w-1/2 py-2 rounded sm:w-1/4 md:w-1/3 lg:text-xl lg:py-3 lg:w-1/1 2xl:w-1/4 bg-secondry-color"
                            >
                              {t("Add")}
                            </button>
                          )}
                        </div>
                      </form>
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
            <div className="block w-full h-10 bg-fourth-color lg:hidden dark:bg-black"></div>
            <div className="flex flex-col flex-1 gap-10 p-5 rounded-lg lg:p-5 lg:mx-0 bg-third-color dark:bg-primary-color">
              {/* Comments */}
              <CommentsShow />
              {/* Comments */}
              {/* Recommended */}
              <RecomandedShow />
              {/* Recommended */}
              {/* reviews */}
              <ReviewsShow />
              {/* reviews */}
            </div>
          </>
        ) : (
          <div className="w-full text-center">{t("loading")}</div>
        )}
      </section>
      {/* Semilier events */}
      <SimilarEvents />
      {/* Semilier events */}
    </div>
  );
};

export default EventShow;
