 
import { _axios } from "../../interceptor/http-config";

export const _AddEvents = {
  index: async ({ query, page, count = 10 }) => {
    return _axios
      .get(
        `/api/admin/sub-category?page=${page}&count=${count}${
          query !== "" ? `&value=${query}` : ""
        }`
      )
      .then((res) => res.data);
  },
  post: (data, setLoading, navigate) =>
    _axios.post("/user/event", data).then((res) => {
      setLoading(true);
      navigate(-1);
    }),
};
