import { useQuery } from "react-query";
import { useState } from "react";
import { _Search } from "api/search";

export const useSearch = () => {
  const [query, setQuery] = useState("");

  const { data, isLoading } = useQuery(
    ["search", query],
    () => _Search.index({ query }).then((res) => res),
    {
      keepPreviousData: true,
    }
  );
  return {
    data,
    isLoading,
    setQuery,
  };
};
