import { useProfile } from "hooks/useProfile";
import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

const Normal = () => {
  const navigate = useNavigate();
  const sectionRef = useRef(null);
  const params = useParams();
  const { data, isLoading } = useProfile(params.id);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <div ref={sectionRef}>
      <div className="w-full flex justify-center ">
        {isLoading && <p className="loader"></p>}
      </div>
      {data ? (
        <section className="text-black dark:text-white lg:flex-row lg:flex justify-start items-center flex flex-col gap-24  ">
          <div className=" flex flex-col items-center lg:w-1/3 gap-10">
            <img
              src={data?.profile?.image}
              alt=""
              className="w-1/2 ring-8 ring-secondry-color rounded-full"
            />
            <div className="flex ">
              <h1 className="lg:text-xl capitalize">
                {data?.profile?.first_name} {data?.profile?.last_name}
              </h1>
            </div>
            <p className="lg:text-xl capitalize">{data?.profile?.email}</p>
          </div>
          <div className="lg:w-1/3 flex justify-center flex-col items-center gap-10">
            <div
              className=" flex items-center justify-center w-32 h-32 rounded-full lg:h-40 lg:w-40 ring-8 ring-secondry-color cursor-pointer"
              onClick={() => navigate("/followers")}
            >
              <p>{data?.profile?.followers_count}</p>
            </div>
            <p className="lg:text-xl capitalize">Followers</p>
          </div>
          <div className="lg:w-1/3 flex justify-center flex-col items-center gap-10">
            <div
              className=" flex items-center justify-center w-32 h-32 rounded-full lg:h-40 lg:w-40 ring-8 ring-secondry-color cursor-pointer"
              onClick={() => navigate("/favorite")}
            >
              <p>{data?.profile?.favorites_count}</p>
            </div>
            <p className="lg:text-xl capitalize">Favorites</p>
          </div>
        </section>
      ) : (
        <div className="w-full flex justify-center ">
          <p>Network Error..</p>
        </div>
      )}
    </div>
  );
};

export default Normal;
