import { useContext } from "react";
import { useProfile } from "hooks/useProfile";
import { DarkModeContext } from "theme/provider/DarkModeProvider";
import { useNavigate } from "react-router-dom";
import { settingsStore } from "store/settingsStore";
import { useFavorite } from "hooks/useFavorite";
import { _AuthApi } from "api/auth";
import { useTranslation } from "react-i18next";
export const useLogic = () => {
  const id = _AuthApi.getID();
  const { t } = useTranslation();
  const { data, isLoading } = useFavorite();
  const { data: profile } = useProfile(id);
  const { darkToggle } = useContext(DarkModeContext);
  const [direction] = settingsStore((state) => [state.direction]);

  const navigate = useNavigate();
  const handleEventShowView = (id) => {
    navigate(`/event-show/${id}`);
  };
  const formatDate = (dateTimeString) => {
    const formattedTime = new Date(dateTimeString).toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return formattedTime;
  };
  const formatDateMonth = (dateTimeString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Date(dateTimeString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };
  return {
    t,
    data,
    isLoading,
    profile,
    darkToggle,
    direction,
    handleEventShowView,
    formatDate,
    formatDateMonth,
    navigate,
  };
};
