import { useMembers } from "hooks/useMembers";
import React from "react";

const MeetTheSiinTeam = () => {
  const { data, isLoading } = useMembers();
  return (
    <div className="flex flex-col items-center justify-center text-black flexs dark:text-white">
      <h1 className="py-20 text-2xl font-bold ">Meet the Siin team!</h1>
      <div className="flex justify-center w-full ">
        {isLoading && <p className="loader"> </p>}
      </div>
      <div className="w-full md:grid-cols-3 md:grid pb-80 justify-items-center">
        {data?.members?.map((item, index) => (
          <div key={index} className="flex flex-col items-center gap-2 my-20">
            <h1 className="text-2xl font-medium">{item.name}</h1>
            <p>{item.description}</p>

            {/* <img src={item.image} alt="" className="rounded-full w-1/1 h-1/1" />{" "} */}
            <img
              src={item.image}
              alt=""
              className="rounded-full w-60 h-60"
            />
            <div className="flex justify-center w-full gap-10 px-10 mt-10 md:px-0">
              {item?.links?.map((item, index) => (
                <div className="flex " key={index}>
                  <a href={item.url}  >
                    <img src={item.icon} alt="" className="w-1/2 md:w-7 md:h-7" />
                  </a>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MeetTheSiinTeam;
