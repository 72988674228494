import { useProfile } from "hooks/useProfile";
import React, { useContext, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { settingsStore } from "store/settingsStore";
import { _axios } from "interceptor/http-config";
import { kindStore } from "store/kindStore";
import { useMutation, useQueryClient } from "react-query";

import { DarkModeContext } from "theme/provider/DarkModeProvider";
import { _AuthApi } from "api/auth";
import { enqueueSnackbar } from "notistack";
import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";
import { useTranslation } from "react-i18next";
export const useLogic = () => {
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const role = _AuthApi.getRole();
  const coverImageRef = useRef(null);
  const profileImageRef = useRef(null);
  const queryClient = useQueryClient();
  const [startX, setStartX] = useState(0);
  const access_token = _AuthApi.getToken();
  const [loading, setLoading] = useState(false);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const { data, isLoading } = useProfile(params.id);
  const [kind] = kindStore((state) => [state.kind]);
  const { darkToggle } = useContext(DarkModeContext);
  const [isDragging, setIsDragging] = useState(false);
  const [showAllReviews, setShowAllReviews] = useState(false);
  const [showAllComments, setShowAllComments] = useState(false);
  const [direction] = settingsStore((state) => [state.direction]);
  const [isModalOpenCoverImage, setIsModalOpenCoverImage] = useState(false);
  const [isModalOpenProfileImage, setIsModalOpenProfileImage] = useState(false);

  const [coverImage, setCoverImage] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [coverImageLoading, setCoverImageLoading] = useState(false);
  const [profileImageLoading, setProfileImageLoading] = useState(false);
  const [state, setState] = React.useState({
    checkedA: true,
  });
  const handleEventShowView = (id) => {
    navigate(`/event-show/${id}`);
  };
  const formatDate = (dateTimeString) => {
    const formattedTime = new Date(dateTimeString).toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return formattedTime;
  };
  const formatDateMonth = (dateTimeString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Date(dateTimeString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };

  const handleNextEvent = () => {
    setActiveIndex(
      (prevIndex) => (prevIndex + 1) % data?.profile?.plans.length
    );
  };

  const handlePrevEvent = () => {
    setActiveIndex(
      (prevIndex) =>
        (prevIndex - 1 + data?.profile?.plans.length) %
        data?.profile?.plans.length
    );
  };

  const mutation = useMutation(
    (id) => {
      const formData = new FormData();
      formData.append("kind", kind);
      formData.append("id", id);
      return _axios.post("/user/favorite-toggle", formData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    }
  );

  const hanldeFavorite = (id) => {
    mutation.mutate(id);
  };

  const toggleReviewsDisplay = () => {
    setShowAllReviews(!showAllReviews);
  };
  const limitedComments = showAllComments
    ? data?.profile?.comments
    : data?.profile?.comments?.slice(0, 3);
  const like = useMutation(
    ({ likeId, likeType }) =>
      _axios.get(`/user/comment/like-toggle/${likeId}?type=${likeType}`),
    {
      onMutate: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
      onSettled: () => {},
    }
  );

  const handleToggleLike = (likeId, likeType) => {
    like.mutate({ likeId, likeType });
  };

  const followToggle = useMutation(
    (id) => _axios.get(`/user/follow-toggle/${id}`),
    {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: () => {
        queryClient.invalidateQueries();
      },

      onSettled: () => {
        setLoading(false);
      },
    }
  );

  const handleToggleUnFollow = (id) => {
    followToggle.mutate(id);
  };
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleSwitchComments = () => {
    _axios
      .get("/user/profile/comment-show-toggle")
      .then((res) => console.log(res));
  };
  const mutationCoverUpdate = useMutation(
    () => {
      if (coverImage) {
        setCoverImageLoading(true);
        const formData = new FormData();
        formData.append("cover", coverImage);
        formData.append("_method", "put");
        return _axios.post("/user/profile/update-cover", formData, {
          headers: {
            ...HttpRequestInterceptor(),
          },
        });
      }
    },
    {
      onSettled: () => {
        setCoverImageLoading(false);
        queryClient.invalidateQueries();
      },
    },
    {
      onError: (error) => {
        enqueueSnackbar(error?.response?.data?.message, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });
      },
    }
  );
  const handleCoverUpdate = () => {
    if (coverImage) {
      setIsModalOpenCoverImage(true);
    }
    coverImageRef.current.value = null;
  };

  const handleSubmitCoverImage = (formData) => {
    setIsModalOpenCoverImage(false);
    setCoverImageLoading(true);

    mutationCoverUpdate.mutate(formData);
  };

  const mutationProfileImageUpdate = useMutation(
    () => {
      if (profileImage) {
        setProfileImageLoading(true);
        const formData = new FormData();
        formData.append("image", profileImage);
        formData.append("_method", "put");
        return _axios.post("/user/profile/update-image", formData, {
          headers: {
            ...HttpRequestInterceptor(),
          },
        });
      }
    },
    {
      onSettled: () => {
        setProfileImageLoading(false);
        queryClient.invalidateQueries();
      },
    },
    {
      onError: (error) => {
        enqueueSnackbar(error?.response?.data?.message, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });
      },
    }
  );

  const handleProfileUpdate = () => {
    if (profileImage) {
      setIsModalOpenProfileImage(true);
    }
    profileImageRef.current.value = null;
  };
  const handleSubmitProfileImage = (formData) => {
    setIsModalOpenProfileImage(false);
    setProfileImageLoading(true);

    mutationProfileImageUpdate.mutate(formData);
  };
  return {
    t,
    data,
    role,
    state,
    params,
    startX,
    loading,
    direction,
    isLoading,
    setStartX,
    formatDate,
    coverImage,
    isDragging,
    scrollLeft,
    darkToggle,
    queryClient,
    activeIndex,
    access_token,
    profileImage,
    handleChange,
    setCoverImage,
    coverImageRef,
    setIsDragging,
    setScrollLeft,
    hanldeFavorite,
    showAllReviews,
    profileImageRef,
    handlePrevEvent,
    setProfileImage,
    handleNextEvent,
    showAllComments,
    formatDateMonth,
    limitedComments,
    handleToggleLike,
    coverImageLoading,
    handleCoverUpdate,
    setShowAllComments,
    profileImageLoading,
    handleEventShowView,
    handleProfileUpdate,
    setCoverImageLoading,
    handleToggleUnFollow,
    toggleReviewsDisplay,
    handleSwitchComments,
    isModalOpenCoverImage,
    setProfileImageLoading,
    handleSubmitCoverImage,
    isModalOpenProfileImage,
    setIsModalOpenCoverImage,
    setIsModalOpenProfileImage,
    handleSubmitProfileImage,
  };
};
