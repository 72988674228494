import React, { useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { useQuery } from "react-query";
import { _axios } from "interceptor/http-config";
import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { settingsStore } from "store/settingsStore";

const localizer = momentLocalizer(moment);

const CalendarPlan = () => {
  const [direction] = settingsStore((state) => [state.direction]);
  useEffect(() => {
    if (direction === "rtl") {
      require("moment/locale/ar.js");
    }
  }, [direction]);

  const { t } = useTranslation("index");

  const { data } = useQuery(
    ["plan"],
    async () => {
      return await _axios
        .get(`/user/plan`, {
          headers: {
            ...HttpRequestInterceptor(),
          },
        })
        .then((res) => res?.data?.plans);
    },
    {}
  );
  const events = data?.map((item) => {
    return {
      id: item?.event_id,
      title: item?.name,
      start: new Date(item?.start_date),
      end: new Date(item?.end_date),
    };
  });
  const navigate = useNavigate();
  const handleEventShowView = (event) => {
    const id = event.id;
    navigate(`/event-show/${id}`);
  };

  useEffect(() => {
    window.scrollTo(100, 100);
  }, []);
  return (
    // <div className="fixed z-50 flex items-center justify-center w-full text-black bg-white bg-center dark:bg-black/0 w-1/1">
    <div className=" dark:text-white">
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: " 100vh" }}
        onSelectEvent={handleEventShowView}
        messages={{
          next: t("next"),
          previous: t("previous"),
          today: t("today"),
          month: t("month"),
          week: t("week"),
          day: t("day"),
          agenda: t("agenda"),
        }}
      />
    </div>
    // </div>
  );
};

export default CalendarPlan;
