import React from "react";
import arrowLeft from "assets/icons/ArrowLeftSlider.svg";
import arrowLeftDark from "assets/icons/ArrowLeftSliderDark.svg";
import { useLogic } from "./hooks/useLogic";
const Followers = () => {
  const {
    t,
    data,
    isLoading,
    profile,
    darkToggle,
    // loading,
    handleToggleFollow,
    navigate,
  } = useLogic();
  return (
    <div className="flex flex-col gap-10 py-10 pl-10 text-black dark:text-white lg:pl-40 ">
      {isLoading && (
        <div className="flex justify-center w-full ">
          <p className="loader"></p>
        </div>
      )}
      {data && (
        <>
          <div
            className="flex items-center gap-5 cursor-pointer hover:font-semibold"
            onClick={() => navigate(-1)}
          >
            {darkToggle ? (
              <img src={arrowLeft} alt="" className="w-5" />
            ) : (
              <img src={arrowLeftDark} alt="" className="w-5" />
            )}
            <p className="text-xl">{t("Back")}</p>
          </div>
          <div className="flex flex-col gap-5">
            <div className="flex gap-10">
              <p className="text-2xl">{profile?.profile?.followers_count}</p>
              <h1 className="text-2xl">{t("Following")}</h1>
            </div>
            <div className="w-1/2 h-1 border border-t-0 border-l-0 border-r-0 border-gray-400 dark:border-white"></div>
          </div>
          <div className="element">
            {data && (
              <div className="h-full max-h-[500px] overflow-auto lg:w-1/2  example">
                {data?.followers?.map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-col py-10 lg:flex-row lg:flex lg:gap-10"
                  >
                    <div className="flex items-center justify-between w-full gap-2">
                      <div className="flex gap-2 lg:gap-10">
                        <div className="w-1/3">
                          <img
                            src={item?.image ?? "null"}
                            alt=""
                            className="w-10 lg:w-40"
                          />
                        </div>
                        <div className="flex items-center gap-5 lg:text-xl ">
                          <p>{item.first_name}</p>
                          <p>{item.last_name}</p>
                        </div>
                      </div>
                      <div className="px-4 lg:px-10">
                        <button
                          className="p-1 text-sm transition duration-300 ease-in-out rounded-md bg-secondry-color lg:text-lg lg:p-2 hover:font-semibold hover:scale-110"
                          onClick={() => handleToggleFollow(item.id)}
                        >
                          {item.is_follower === true ? "UnFollow" : "follow"}
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Followers;
