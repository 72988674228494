import { _axios } from "interceptor/http-config";
import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";
import { enqueueSnackbar } from "notistack";

export const _ProfileApi = {
  index: async ({ id }) => {
    if (id === (undefined || null)) {
      return [];
    } else {
      return _axios
        .get(`/user/profile/${id}`, {
          headers: {
            ...HttpRequestInterceptor(),
          },
        })
        .then((res) => res.data)
        .catch((err) =>
          enqueueSnackbar("profile Error", {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
          })
        );
    }
  },
};
