import React from "react";
import Modal from "react-modal";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useLogic } from "../hooks/useLogic";
Modal.setAppElement("#root");
const RecomandedShow = () => {
  const { data, navigate, t } = useLogic();

  return (
    <div className="flex flex-col w-full gap-10">
      <p className="text-xl text-secondry-color">{t("Recommended by")} :</p>
      <div className="flex justify-around p-5 border rounded-md border-secondry-color">
        <div
          className="flex flex-row gap-20"
          style={{ maxHeight: "150px", overflowY: "auto" }}
        >
          {data?.recommendations?.length > 0 ? (
            data?.recommendations?.map((item, index) => (
              <div key={index} className="flex items-center gap-2 py-5">
                <img
                  src={item.verified_image}
                  alt=""
                  onClick={() => {
                    item.role === "verified" &&
                      navigate(`/verified-profile/${item.user_id}`);
                  }}
                  className="w-12 h-12 rounded-full cursor-pointer lg:h-12 lg:w-12 ring-1 ring-white"
                />

                <p>{item.verified_first_name}</p>
                <p>{item.verified_last_name}</p>
                {item.role === "verified" && (
                  <VerifiedIcon sx={{ color: "#f5881f", fontSize: "20px" }} />
                )}
              </div>
            ))
          ) : (
            <div>{t("No Recommended available")}.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecomandedShow;
