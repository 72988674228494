import { useQuery } from "react-query";
import { _FollowersApi } from "api/followers/followers";

export const useFollowers = () => {
  const { data, isLoading } = useQuery(
    ["followers"],
    () => _FollowersApi.index().then((res) => res),
    {
      keepPreviousData: true,
    }
  );
  return {
    data,
    isLoading,
  };
};
