import { _AuthApi } from "api/auth";
import { _axios } from "interceptor/http-config";
import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";
import { enqueueSnackbar } from "notistack";

const access_token = _AuthApi.getToken();
export const _FollowersApi = {
  index: async () => {
    if (!access_token) {
      return [];
    }
    return _axios
      .get(`/user/profile/followers`, {
        headers: {
          ...HttpRequestInterceptor(),
        },
      })
      .then((res) => res.data)
      .catch((err) =>
        enqueueSnackbar("Followers Error", {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        })
      );
  },
};
