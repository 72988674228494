import { Box, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { _AuthApi } from "api/auth";
import { DarkModeContext } from "theme/provider/DarkModeProvider";
import swal from "sweetalert";
import { useMutation } from "react-query";

let schema = yup.object().shape({
  code: yup
    .string()
    .required("Verification code is required")
    .length(5, "Verification code must be 5 characters long")
    .matches(/^[0-9]+$/, "Verification code must be a number"),
});
const OtpCode = () => {
  const { t } = useTranslation("index");
  const navigate = useNavigate();

  const { darkToggle } = useContext(DarkModeContext);
  const { email } = useParams();
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  const formOptions = { resolver: yupResolver(schema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const { mutate } = useMutation((data) => verifyOtp(data));

  const verifyOtp = async (data) => {
    setLoading(true);
    try {
      await _AuthApi.verifyEmail({ email, code: data.code });
      swal("Thanks.", "success");
      navigate("/");
    } catch (error) {
      console.error(error);
      swal("Error", "Invalid verification code", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(300, 300);
  }, []);

  const onSubmit = (data) => {
    mutate(data);
  };
  return (
    <section>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "transparent",
          flexDirection: "column",
          backdropFilter: "bluer(20px)",
        }}
        className="flex flex-row"
      >
        <Box
          className="dark:bg-[#272727]/50 bg-third-color"
          sx={{
            minWidth: "350px",
            width: "30%",
            padding: "40px",
            zIndex: 10000,
            backgroundColor: "transparent",
            border: darkToggle
              ? "1px solid rgba(255,255,255,0.2)"
              : "1px solid rgba(0,0,0,0.2)",
            borderRadius: "10px",
          }}
          style={{
            backdropFilter: " blur(3px)",
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <p className="text-2xl text-black dark:text-white">
              {t("Enter Your Code")}
            </p>

            <p className="my-5 text-xl text-center text-secondry-color">
              {t("An email with a verification code has been sent to")} {email}
            </p>
          </Grid>

          <Box
            sx={{ width: "100%", mt: "30px" }}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box sx={{ margin: "0 0 8px 5px" }}>
              <p className="text-black dark:text-white">{t("Code")}</p>
            </Box>

            <input
              type="text"
              placeholder="Enter your code"
              fullWidth
              {...register("code")}
              value={code}
              onChange={(e) => setCode(e.target.value)}
              className="w-full px-5 py-5 text-black bg-transparent border border-black rounded dark:text-white "
            />

            {errors.code && (
              <span className="field_level_error" style={{ color: "red" }}>
                {errors.code.message}
              </span>
            )}
            <div className="flex items-center justify-center w-full my-10">
              {!loading ? (
                <button
                  type="submit"
                  className="relative w-1/2 h-12 text-white rounded bg-secondry-color"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                    Register
                  </div>
                </button>
              ) : (
                <button
                  className="relative w-1/2 h-12 text-white rounded bg-secondry-color"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  <div className="flex items-center justify-center w-full text-center ">
                    <div className="loader"> </div>
                  </div>
                </button>
              )}
            </div>
          </Box>
        </Box>
      </Box>
    </section>
  );
};

export default OtpCode;
