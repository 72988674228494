import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import sortIconWhite from "assets/icons/sortIconWhite.svg";
import sortIconBlack from "assets/icons/sortIconBlack.svg";
import { DarkModeContext } from "theme/provider/DarkModeProvider";

const AboutLayout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { darkToggle } = useContext(DarkModeContext);

  const handleImageClick = () => {
    setIsOpen(!isOpen);
  };
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);
  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const navigate = useNavigate();
  const aboutNav = [
    { head: "About Us", navigate: "about" },
    { head: "Why do I need this?", navigate: "why-do-i-need" },
    { head: "Meet the Siin team!", navigate: "meet-the-siin-team" },
    {
      head: "Siin in the Media & Testimonials",
      navigate: "siin-in-the-media&testimonials",
    },
    { head: "Friends & Partners", navigate: "partners" },
    { head: "Sponsorships", navigate: "sponsorships" },
    { head: "Get in touch", navigate: "get-in-touch" },
  ];

  return (
    <>
      <section className="hidden mx-20 text-black border border-white md:block dark:text-white md:pb-10 md:px-0 bg-third-color dark:bg-black">
        <div className="flex justify-around pb-5 pt-5x ">
          {aboutNav?.map((item, index) => (
            <div key={index}>
              <h1
                onClick={() => navigate(`/siin-info/${item.navigate}`)}
                className="font-bold transition duration-500 ease-in-out transform cursor-pointer hover:scale-110"
              >
                {item.head}
              </h1>
            </div>
          ))}
        </div>
        <div className="h-[0.5px] bg-primary-color m-5"></div>
        {children}
      </section>

      <section className="block mx-5 text-black border border-white md:hidden dark:text-white md:pb-10 md:px-0 bg-third-color dark:bg-black">
        <div className="flex justify-between px-2">
          <div>
            <div className="flex items-center justify-between pb-5 pt-5x">
              <div>
                <h1
                  // onClick={() => navigate(aboutNav[selectedItemIndex].navigate)}
                  className="font-bold "
                >
                  {aboutNav[selectedItemIndex].head}
                </h1>
              </div>
            </div>
          </div>
          <div className="dropdown">
            <div className="flex items-center justify-center gap-2 ">
              {!darkToggle ? (
                <img
                  src={sortIconBlack}
                  alt=""
                  onClick={handleImageClick}
                  className={`cursor-pointer h-5 ${
                    isOpen ? "rotate-180" : ""
                  } transition-transform duration-300 ease-in-out`}
                />
              ) : (
                <img
                  src={sortIconWhite}
                  alt=""
                  onClick={handleImageClick}
                  className={`cursor-pointer h-5 ${
                    isOpen ? "rotate-180" : ""
                  } transition-transform duration-300 ease-in-out`}
                />
              )}

              <div className="relative flex flex-col text-secondry-color ">
                <div className="flex flex-col items-start w-full mt-5 dropdown-content">
                  {isOpen &&
                    aboutNav.map((item, index) => (
                      <div key={index}>
                        <h1
                          onClick={() => {
                            handleItemClick(index);
                            navigate(`/siin-info/${item.navigate}`);
                            setIsOpen(false);
                          }}
                          className="font-bold transition duration-500 ease-in-out transform cursor-pointer hover:scale-[1.03]"
                        >
                          {item.head}
                        </h1>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-[0.5px] bg-primary-color m-5"></div>
        {children}
      </section>
    </>
  );
};

export default AboutLayout;
