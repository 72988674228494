import "./App.css";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Layout from "layout/Layout";
import Home from "pages/Home/Home";
import { useContext, useEffect } from "react";
import { DarkModeContext } from "theme/provider/DarkModeProvider";
import Singin from "pages/Auth/Singin/Singin";
import Register from "pages/Auth/Register/Register";
import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";
// import { HttpResponseInterceptor } from "interceptor/http-response.interceptor";
// import { useSnackbar } from "notistack";
import AboutLayout from "pages/About/AboutLayout";
import AboutRouting from "pages/About/AboutRouting";
import ShouldBeLogged from "middlewares/ShouldBeLogged";
import HowItWorks from "pages/HowItWorks/HowItWorks";
import EventShow from "pages/EventShow/EventShow";
import AddEvents from "pages/AddEvents/AddEvents";
import MyCreatedEvent from "pages/MyCreatedEvent/MyCreatedEvent";
import Normal from "pages/Profile/Normal/Normal";
import Organizer from "pages/Profile/Organizer/Organizer";
import Verified from "pages/Profile/Verified/Verified";
import Followers from "pages/Profile/Followers/Followers";
import Favorite from "pages/Profile/Favorite/Favorite";
import LogoLodaer from "components/styled/LogoLodaer";
import { useState } from "react";
import CalendarPlan from "components/calendar/CalendarPlan";
import ResetPassword from "pages/Auth/ResetPassword";
import VerificationCodeForm from "pages/Auth/VerificationCodeForm";
import PasswordEditForm from "pages/Auth/PasswordEditForm";
import OtpCode from "pages/Auth/Register/OtpCode";

function App() {
  // const navigate = useNavigate();
  // const { enqueueSnackbar } = useSnackbar();
  const [showLogo, setShowLogo] = useState(true);
  const { darkToggle } = useContext(DarkModeContext);

  useEffect(() => {
    if (darkToggle) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [darkToggle]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    HttpRequestInterceptor();
    // HttpResponseInterceptor(navigate, enqueueSnackbar);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLogo(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <div className={`w-full h-full ${darkToggle ? "dark bg-black" : ""}`}>
      {showLogo ? (
        <LogoLodaer />
      ) : (
        <Routes>
          <Route
            element={
              <Layout>
                <Outlet />
              </Layout>
            }
          >
            <Route path="/" element={<Home />} />
            <Route path="/singin" element={<Singin />} />
            <Route path="/register" element={<Register />} />
            <Route path="/register/otp/:email" element={<OtpCode />} />
            <Route path="/event-show/:id" element={<EventShow />} />

            <Route
              path="/add-event"
              element={
                <ShouldBeLogged>
                  <AddEvents />
                </ShouldBeLogged>
              }
            />
            <Route path="/my-created-events" element={<MyCreatedEvent />} />
            <Route path="/calendar" element={<CalendarPlan />} />
            <Route path="/organizer-profile/:id" element={<Organizer />} />
            <Route path="/verified-profile/:id" element={<Verified />} />
            <Route
              path="/normal-profile/:id"
              element={
                <ShouldBeLogged>
                  <Normal />
                </ShouldBeLogged>
              }
            />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route
              path="/reset-password/check-code/:email"
              element={<VerificationCodeForm />}
            />
            <Route
              path="/reset-password/edit-password/:email/:code"
              element={<PasswordEditForm />}
            />
            <Route path="/" element={<Navigate to="/reset-password" />} />
            <Route
              path="/followers"
              element={
                <ShouldBeLogged>
                  <Followers />
                </ShouldBeLogged>
              }
            />
            <Route
              path="/favorite"
              element={
                <ShouldBeLogged>
                  <Favorite />
                </ShouldBeLogged>
              }
            />

            <Route
              element={
                <AboutLayout>
                  <Outlet />
                </AboutLayout>
              }
            >
              <Route path="/siin-info/*" element={<AboutRouting />} />
            </Route>
            <Route path="/how-it-works" element={<HowItWorks />} />
          </Route>
          <Route
            path="*"
            element={
              <p className="text-black dark:text-white">not found 404</p>
            }
          />
        </Routes>
      )}
    </div>
  );
}

export default App;
