import React, { useContext } from "react";

import logo from "assets/images/sinLogo.png";
import lightIcon from "assets/icons/light_mode.svg";
import darkIcon from "assets/icons/dark_mode.svg";
import logoSinDark from "assets/images/logoSinDark.png";
import { useTranslation } from "react-i18next";
import { DarkModeContext } from "theme/provider/DarkModeProvider";
import instgramIcon from "assets/icons/footer/instagramIcon.svg";
import facebookIcon from "assets/icons/footer/facebookIcon.svg";
import twitterIcon from "assets/icons/footer/twitterIcon.svg";
import youtubeIcon from "assets/icons/footer/youtubeIcon.svg";
import tiktokIcon from "assets/icons/footer/tiktokIcon.svg";
import { settingsStore } from "store/settingsStore";
const Footer = () => {
  const { t, i18n } = useTranslation("index");
  const { darkToggle, setDarkToggle } = useContext(DarkModeContext);

  const [direction, setDirection] = settingsStore((state) => [
    state.direction,
    state.setDirection,
  ]);

  const toggleLang = () => {
    setDirection(direction === "ltr" ? "rtl" : "ltr");
    i18n.changeLanguage(direction === "ltr" ? "ar" : "en");
  };
  const images = [
    { image: instgramIcon },
    { image: facebookIcon },
    { image: twitterIcon },
    { image: youtubeIcon },
    { image: tiktokIcon },
  ];
  const footerItem = [
    {
      head: t("LEARN MORE"),
      body1: <a href="/privacy">{t("Privacy")}</a>,
      body2: <a href="/support">{t("Support")}</a>,
      body3: <a href="/terms">{t("Terms")}</a>,
    },
  ];
  const footerResponsive = [
    {
      head: t("LEARN MORE"),
      body1: <a href="/privacy">{t("Privacy")}</a>,
      body2: <a href="/support">{t("Support")}</a>,
      body3: <a href="/terms">{t("Terms")}</a>,
    },
  ];
  return (
    <div className="border border-b-0 border-l-0 border-r-0 border-primary-color">
      <div className="hidden md:block ">
        <div className="flex justify-around w-full py-20 text-black justify-items-center dark:text-white ">
          <div className="flex flex-col">
            <div>
              {darkToggle ? (
                <img src={logo} alt="" className="w-80 md:w-40" />
              ) : (
                <img src={logoSinDark} alt="" className="w-80 md:w-40" />
              )}
            </div>
            <div className="flex flex-col gap-4 mt-5">
              <div className="flex gap-2">
                <p>Home</p> | <p>How it work</p> | <p>About</p> |{" "}
                <p>Advertise</p>
              </div>
              <div>
                <p> For inquiries: info@lebtivity.com</p>
              </div>
              <div>
                <p>For events boosting: boost@lebtivity.com</p>
              </div>
              <div>
                <p>| Contact us</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-col ">
              <div className="flex flex-col gap-4 leading-10 ">
                <p className="text-xl font-bold ">Join Sin Newsletter!</p>
                <form className="flex flex-col gap-5">
                  <input
                    type="text"
                    placeholder="Full Name"
                    name="full_name"
                    className="h-10 px-4 text-white rounded-[10px] w-80 bg-[#3b3b3b]/50 placeholder:text-white"
                    required
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    required
                    className="h-10 px-4 text-white rounded-[10px] w-80 bg-[#3b3b3b]/50 placeholder:text-white "
                  />
                  <button
                    type="submit"
                    className="w-40 h-10 rounded-md bg-secondry-color"
                  >
                    Subscribe
                  </button>
                </form>
                <p className="mt-2">Follow us! We are sociable!</p>
                <div className="flex justify-between mt-6">
                  {images?.map((item, index) => (
                    <div className="flex " key={index}>
                      <img src={item.image} alt="" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div>
            {footerItem.map((item, index) => (
              <div className="flex flex-col leading-10" key={index}>
                <div className="text-xl font-bold text-secondry-color">
                  {item.head}
                </div>
                {Object.values(item)
                  .slice(1)
                  .map((bodyItem, bodyIndex) => (
                    <div key={bodyIndex}>{bodyItem}</div>
                  ))}
              </div>
            ))}
          </div>

          <div className="flex items-start">
            <button
              onClick={toggleLang}
              className="mx-2 text-black dark:text-white"
            >
              {t("AED")}
            </button>
            <button onClick={() => setDarkToggle(!darkToggle)}>
              {darkToggle ? (
                <img src={lightIcon} alt="" />
              ) : (
                <img src={darkIcon} alt="" />
              )}
            </button>
          </div>
        </div>
      </div>
      {/* res */}
      <div className="block md:hidden">
        <div className="flex flex-col items-center justify-center h-screen">
          <div className="flex flex-col items-center justify-center mt-10">
            <div>
              {darkToggle ? (
                <img src={logo} alt="" className="w-40 md:w-40" />
              ) : (
                <img src={logoSinDark} alt="" className="w-40 md:w-40" />
              )}
            </div>
            <div className="flex items-center w-1/2 gap-5 leading-10 text-center text-black dark:text-white">
              <p>Home | How it works | About | Advertis boosting</p>
            </div>
            <div className="flex flex-col">
              <div className="flex flex-col ">
                <div className="flex flex-col gap-4 leading-10 ">
                  <p className="text-xl font-bold ">Join Sin Newsletter!</p>
                  <form className="flex flex-col gap-5">
                    <input
                      type="text"
                      placeholder="Full Name"
                      name="full_name"
                      className="h-10 px-4 text-white rounded-[10px] w-80 bg-[#3b3b3b]/50 placeholder:text-white"
                      required
                    />
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      required
                      className="h-10 px-4 text-white rounded-[10px] w-80 bg-[#3b3b3b]/50 placeholder:text-white "
                    />
                    <button
                      type="submit"
                      className="w-40 h-10 rounded-md bg-secondry-color"
                    >
                      Subscribe
                    </button>
                  </form>
                  {footerResponsive.map((item, index) => (
                    <div
                      className="flex justify-between px-5 mt-5 text-secondry-color"
                      key={index}
                    >
                      {Object.values(item)
                        .slice(1)
                        .map((bodyItem, bodyIndex) => (
                          <div key={bodyIndex}>{bodyItem}</div>
                        ))}
                    </div>
                  ))}

                  <p className="mt-2 text-center">
                    Follow us! We are sociable!
                  </p>
                  <div className="flex justify-between ">
                    {images?.map((item, index) => (
                      <div className="flex " key={index}>
                        <img src={item.image} alt="" className="w-8 h-8" />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <p className="px-5 py-5 mt-10 text-xs text-center text-black dark:text-white">
              ©siinexperience 2023. All rights reserved. Privacy Policy
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
