import { useEffect } from "react";

import { _axios } from "interceptor/http-config";
import { useMutation, useQuery } from "react-query";
import { typeStore } from "store/typeStore";
import { hanldeClickSub } from "../../../layout/hooks/useNavBarLogic";
import { categoryStore } from "store/categoryStore";
import { useQueryClient } from "react-query";
import { cityStore } from "store/cityStore";
import { startDateStore } from "store/startDateStore";
import { settingsStore } from "store/settingsStore";
import { kindStore } from "store/kindStore";
import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { _AuthApi } from "api/auth";

export const useLogic = () => {
  const navigate = useNavigate();
  const access_token = _AuthApi.getToken();
  const { t } = useTranslation("index");
  const queryClient = useQueryClient();
  const [openModals, setOpenModals] = useState({});
  const [openModalsRes, setOpenModalsRes] = useState({});
  const [loadingFavorite, setLoadingFavorite] = useState(false);
  const [type] = typeStore((state) => [state.type]);
  const [direction] = settingsStore((state) => [state.direction]);
  const [category] = categoryStore((state) => [
    state.category,
    state.setCategory,
  ]);
  const [city] = cityStore((state) => [state.city]);
  const [date] = startDateStore((state) => [state.date]);
  const [kind] = kindStore((state) => [state.kind]);
  const settings = {
    dots: "true",
    infinite: "true",
    speed: 500,
    slidestoshow: 1,
    slidestoscroll: 1,
  };

  // const { data: subCategory, isLoading } = useQuery(
  //   ["sub-category", category],
  //   async () => {
  //     return await _axios
  //       .get(`/user/sub-category${category ? `?category=${category}` : ""}`, {
  //         headers: {
  //           ...HttpRequestInterceptor(),
  //         },
  //       })
  //       .then((res) => res.data?.sub_categories);
  //   },
  //   {}
  // );
  const { data: heroImages, isLoading: heroLoading } = useQuery(
    ["hero-loading"],
    async () => {
      return await _axios
        .get(`/user/home/hero-image`, {
          headers: {
            ...HttpRequestInterceptor(),
          },
        })
        .then((res) => res.data?.images);
    },
    {}
  );
  const { data: specialEvent, isLoading: specialEventLoading } = useQuery(
    ["special-event", category],
    async () => {
      return await _axios
        .get(
          `/user/home/special-event${category ? `?category=${category}` : ""}`,
          {
            headers: {
              ...HttpRequestInterceptor(),
            },
          }
        )
        .then((res) => res.data?.events);
    },
    {}
  );
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [event, setEvent] = useState({ events: [], pag: "" });

  const { isLoading: eventLoading } = useQuery(
    ["event", category, city, date, page, count],
    async () => {
      const response = await _axios.get(
        `/user/home/event?${page ? `page=${page}` : ""}${
          count ? `&count=${count}` : ""
        }${category ? `&category=${category}` : ""}${
          city ? `&city=${city}` : ""
        }${date ? `&start_date=${date}` : ""}`
      );

      const eventsWithLoading = response?.data?.events?.map((event) => ({
        ...event,
        loading: false,
      }));
      const pag = response.data.pagination.total;
      console.log("pag", pag);
      setEvent({ events: eventsWithLoading, pag: pag });
      return response.data;
    },
    {}
  );

  const formatDate = (dateTimeString) => {
    const formattedTime = new Date(dateTimeString).toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return formattedTime;
  };
  const formatDateMonth = (dateTimeString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Date(dateTimeString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };
  const handleShare = (itemId) => {
    setOpenModals((prevOpenModals) => ({
      ...prevOpenModals,
      [itemId]: !prevOpenModals[itemId],
    }));
  };
  const handleShareRes = (itemId) => {
    setOpenModalsRes((prevOpenModals) => ({
      ...prevOpenModals,
      [itemId]: !prevOpenModals[itemId],
    }));
  };

  const handleEventShowView = (id) => {
    navigate(`/event-show/${id}`);
  };
  const closeModal = () => {
    setOpenModals(false);
  };
  const closeModalRes = () => {
    setOpenModalsRes(false);
  };
  const favoriteToggle = useMutation(
    (id) => {
      const formData = new FormData();
      formData.append("kind", kind);
      formData.append("id", id);
      setLoadingFavorite(true);
      return _axios.post("/user/favorite-toggle", formData);
    },
    {
      onSettled: () => {
        setLoadingFavorite(false);
      },
      onSuccess: () => {
        setLoadingFavorite(false);
        queryClient.invalidateQueries();
      },
    }
  );

  const hanldeFavorite = async (id) => {
    const eventIndex = event.events.findIndex((ev) => ev.id === id);
    if (eventIndex !== -1) {
      const updatedEvents = [...event.events];
      updatedEvents[eventIndex] = {
        ...updatedEvents[eventIndex],
        loadingFavorite: true,
      };
      setEvent({ events: updatedEvents });
      try {
        await favoriteToggle.mutateAsync(id);
        updatedEvents[eventIndex] = {
          ...updatedEvents[eventIndex],
          is_favorite: !updatedEvents[eventIndex].is_favorite,
        };
        setEvent({ events: updatedEvents });
      } finally {
        updatedEvents[eventIndex] = {
          ...updatedEvents[eventIndex],
          loadingFavorite: false,
        };

        setEvent({ events: updatedEvents });
      }
    }
  };

  useEffect(() => {
    hanldeClickSub();
    // window.scrollTo(0, 0);
  }, []);

  return {
    navigate,
    type,
    direction,
    category,
    city,
    date,
    kind,
    openModals,
    setOpenModals,
    openModalsRes,
    setOpenModalsRes,
    closeModal,
    closeModalRes,
    hanldeFavorite,
    settings,
    // subCategory,
    // isLoading,
    heroImages,
    heroLoading,
    specialEvent,
    specialEventLoading,
    event,
    eventLoading,
    formatDate,
    formatDateMonth,
    handleShare,
    handleShareRes,
    handleEventShowView,
    loadingFavorite,
    favoriteToggle,
    t,
    access_token,
    page,
    setPage,
    count,
    setCount,
  };
};
